import React, { useContext, useEffect, useState } from 'react';
import './Notification.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  readNotiAction,
  setNotifications,
} from '../../redux/actions/Notification';
import { Stack, Typography } from '@mui/material';
import { AuthContext } from '../../App';
import { getClients } from '../../redux/actions/Clients';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import SelectInput from '../../components/shared/SelectInput';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(null);
  const [clientId, setClientId] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const allNotifications = useSelector((state) => state.notification);
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  const { data: clientsData, loading: loadingClients } = useSelector(
    (s) => s.client,
  );

  useEffect(() => {
    if (allNotifications?.notifications?.length > 0) {
      setFilteredNotifications([...allNotifications?.notifications]);
      setLastPage(allNotifications.lastPage);
    }
  }, [allNotifications?.loading]);

  useEffect(() => {
    dispatch(setNotifications());
    dispatch(getClients(auth._id));
    dispatch(readNotiAction());
  }, []);

  useEffect(() => {
    if (page > 0 && page <= lastPage) {
      handleNextPage();
    }
  }, [page]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      setPage((p) => p + 1);
    }
  };

  const handleNextPage = async () => {
    dispatch(setNotifications(page, clientId));
  };

  const handleSelectClient = (value) => {
    if (value === 'DEFAULT') {
      dispatch(setNotifications(0));
    } else {
      dispatch(setNotifications(0, value));
    }
    setClientId(value);
    setPage(0);
  };

  const handleSelectNotification = (notification) => {
    if (notification.type === 'NEW_USER') {
      navigate('/client');
    } else if (notification.type === 'WORKOUT_LOG') {
      navigate('/workout/' + notification.data.workoutId);
    } else if (notification.type === 'TEAM_LOG') {
      navigate('/team');
    } else if (notification.type === 'CHAT_MESSAGE') {
      navigate('/client?chat=' + notification.from._id);
    } else if (notification.type === 'TEAM_MESSAGE') {
      navigate('/team');
    }
  };

  const getOptions = () => {
    const result = clientsData.map((client) => {
      return {
        value: client._id,
        label: client.Name,
      };
    });
    return [{ value: 'DEFAULT', label: t('texts.allClients') }, ...result];
  };

  return (
    <React.Fragment>
      <div className="home">
        <h1>{t('texts.notifications')}</h1>
        {allNotifications.loading && <Loader />}
        <br />
        <div className="select-box">
          <SelectInput
            label={t('texts.clients')}
            value={clientId || 'DEFAULT'}
            onChange={(e) => handleSelectClient(e.target.value)}
            selectOptions={getOptions()}
            hideError={true}
            containerSx={{ mb: 0, flex: 1, mt: -2 }}
          />
        </div>
        <div
          className="homepage-boxes extra-home notifications"
          onScroll={handleScroll}
          style={{ overflow: 'auto', height: window.innerHeight - 350 }}
        >
          {filteredNotifications?.length === 0 && (
            <Stack textAlign={'center'}>
              <Typography>
                {t('phrases.notifications.emptyNotifications')}
              </Typography>
            </Stack>
          )}
          {filteredNotifications?.map((noti, notiIdx) => {
            return (
              <React.Fragment key={notiIdx}>
                <div
                  className="boxes"
                  style={{
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    background: noti.read
                      ? 'rgb(37, 39, 53)'
                      : 'rgb(50, 62, 66)',
                  }}
                  onClick={() => handleSelectNotification(noti)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="box-avtar">
                      <img src="assets/demoUser.png" alt="" />
                    </div>
                    <div className="box-dataupper">
                      <Typography
                        style={{
                          color: '#32B5FF',
                          fontWeight: 'bold',
                          fontSize: 21,
                          marginBottom: 5,
                        }}
                      >
                        {noti?.heading}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        {noti?.msg}
                      </Typography>
                    </div>
                  </div>

                  <div>
                    <img src="assets/color-arrow.png" alt="" />
                  </div>
                  <div
                    className="box-time"
                    style={{
                      position: 'absolute',
                      bottom: 15,
                      right: 15,
                      color: 'rgba(255,255,255,0.4)',
                    }}
                  >
                    {moment(noti.createdOn).fromNow()}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
