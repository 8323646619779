import { Stack, Typography } from '@mui/material';
import ClientListItem from '../client/ClientListItem';
import useTeamMembers from './useTeamMembers';

const TeamMembers = () => {
  const { team, members } = useTeamMembers();

  return (
    <Stack spacing={1.5}>
      <Typography sx={{ fontSize: 25, textAlign: 'start' }}>
        Members of {team?.TeamName} team
      </Typography>
      {members.map((member) => (
        <Stack>
          <ClientListItem user={member} />
        </Stack>
      ))}
    </Stack>
  );
};

export default TeamMembers;
