import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  assignClientSessionApi,
  assignTeamSession,
  deleteAssignedSessionById,
  deleteTeamSession,
  sessionCreate,
} from '../../Service/Api';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset_assign_session_data,
  set_client_session_byDate,
  update_assigned_session,
} from '../../redux/actions/ClientAssignedSession';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  reset_data_team_session,
  set_team_session_by_date,
  set_update_assigned_team_date,
} from '../../redux/actions/TeamAssignedSession';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';

export default function useClientSessionAssign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useContext(AuthContext);

  const [title, setTitle] = useState('');
  const [Instructions, setInstructions] = useState('');
  const [selectedSession, setSelectedSession] = useState();
  const [selectedSessionId, setSelectedSessionId] = useState();
  const [currentExercises, setCurrentExercises] = useState([]);
  const [currentSessionDate, setCurrentSessionDate] = useState(null);
  const [exercisesModalVisibility, setExercisesModalVisibility] =
    useState(false);
  const [importModalVisibility, setImportModalVisibility] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [expandExercise, setExpandExercise] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { clientId, teamId, date } = useParams();
  const { t } = useTranslation();

  const currentClientSessionData = useSelector(
    (state) => state.clientAssignedSession,
  );
  const teamSessions = useSelector((state) => state.teamSessions);

  const sessions = useMemo(
    () => (teamId ? teamSessions : currentClientSessionData),
    [teamId, teamSessions?.data, currentClientSessionData?.data],
  );
  const [newSessionsDates, setNewSessionsDates] = useState([]);
  const [removedSessionsDates, setRemovedSessionsDates] = useState([]);

  const resetState = (resetSession = false) => {
    setCurrentExercises([]);
    setInstructions('');
    setTitle('');
    if (resetSession) {
      setSelectedSession(undefined);
      setSelectedSessionId(undefined);
    }
  };

  const fetchData = () => {
    if (!currentSessionDate) return;
    if (teamId) dispatch(set_team_session_by_date(teamId, currentSessionDate));
    else if (clientId)
      dispatch(set_client_session_byDate(clientId, currentSessionDate));
  };
  console.log('selecetedSEssion', selectedSession);

  const setSessionInfo = (session, initializeSelectedSession) => {
    if (session) {
      const exercisesFormatted = session.assignedExercises.map((inner) => {
        return inner.map((e) => {
          return { ...e, ...e.exerciseId };
        });
      });
      setSelectedSessionId(session._id);

      if (initializeSelectedSession) {
        setSelectedSession({
          ...session,
          title: session.title,
          Instructions: session.Instructions,
          assignedExercises: exercisesFormatted,
        });
      }

      setTitle(session.title);
      setInstructions(session.Instructions);
      setCurrentExercises(exercisesFormatted);
    } else {
      resetState();
    }
  };

  const convertToWeekday = (date) => {
    return moment.utc(date).format('dddd');
  };
  const convertToLocalDate = (date) => {
    return moment.utc(date).format('YYYY-MM-DD');
  };
  const weekDay = React.useMemo(() => {
    return convertToWeekday(currentSessionDate);
  }, [currentSessionDate]);

  const fullDate = React.useMemo(() => {
    return convertToLocalDate(currentSessionDate);
  }, [currentSessionDate]);

  const handleAddExercisesToSession = (newExercises) => {
    const aux = [...currentExercises];
    for (let exercises of newExercises) {
      for (let innerExercise of exercises) {
        aux.push([innerExercise]);
      }
    }
    setCurrentExercises(aux);
    setExercisesModalVisibility(false);
  };

  const handleImportSessionExercises = (newExercises) => {
    setCurrentExercises([...currentExercises, ...newExercises]);
    setImportModalVisibility(false);
  };

  const setDateParam = (date) => {
    const newDate = moment(date).format('YYYY-MM-DD');

    const pathParts = location.pathname.split('/');
    const newPath = pathParts
      .map((value, idx) => (idx === pathParts.length - 1 ? newDate : value))
      .join('/');
    navigate(newPath, { replace: true });
  };

  const onChangeDate = (date) => {
    resetState();
    setCurrentSessionDate(date);
    setDateParam(date);
  };

  const nextDate = () => {
    const newDate = moment.utc(currentSessionDate).add(1, 'days');
    onChangeDate(newDate);
  };

  const preDate = () => {
    const newDate = moment.utc(currentSessionDate).subtract(1, 'days');
    onChangeDate(newDate);
  };

  const sessionExists = () => {
    return !!sessions?.data?.length && selectedSession;
  };

  const addSuperset = (i, j) => {
    const aux = [...currentExercises];
    if (j > 0) {
      const exercisesToAdd = aux[i + 1];
      for (let exercises of exercisesToAdd) {
        aux[i].push(exercises);
      }
      aux.splice(i + 1, 1);
    } else {
      if (aux[i + 1].length > 1) {
        const exerciseToAdd = { ...currentExercises[i][0] };
        aux[i + 1].push(exerciseToAdd);
        aux.splice(i, 1);
      } else {
        const exerciseToAdd = { ...currentExercises[i + 1][0] };
        aux[i].push(exerciseToAdd);
        aux.splice(i + 1, 1);
      }
    }
    setCurrentExercises(aux);
  };

  const removeSuperset = (i, j) => {
    const exerciseToRemove = { ...currentExercises[i][j + 1] };
    let aux = [...currentExercises];
    aux[i].splice(j + 1, 1);
    aux.push([exerciseToRemove]);
    setCurrentExercises([...aux]);
  };

  const isExercisesInvalid = () => {
    if (currentExercises.length === 0 || currentExercises[0].length === 0) {
      toast.error(t('validations.exercisesRequired'));
      return true;
    } else if (!title || title.trim().length === 0) {
      toast.error(t('validations.titleRequired'));
      return true;
    } else {
      const isMetricFieldsInvalid = currentExercises
        .reduce(
          (allExercises, exercises) => [...allExercises, ...exercises],
          [],
        )
        .reduce(
          (metadatas, exercise) => [...metadatas, ...exercise.metadata],
          [],
        )
        .find(
          (data) =>
            !data.firstMetric.value ||
            !data.secondMetric.value ||
            !data.tempo ||
            !data.rest,
        );

      if (isMetricFieldsInvalid) {
        toast.error(t('validations.allTableValuesRequired'));
        return true;
      }
    }
  };

  const update = async () => {
    if (isExercisesInvalid()) return;

    let body = {
      _id: selectedSessionId,
      title,
      Instructions,
      exercises: currentExercises,
    };

    if (teamId)
      dispatch(set_update_assigned_team_date(body, selectedSessionId));
    else if (clientId)
      dispatch(update_assigned_session(selectedSessionId, body));
  };

  const saveSessionToLibrary = async () => {
    const { _id, token } = auth;
    const body = {
      SessionName: title,
      Instructions: Instructions,
      trainerId: _id,
      exercises: currentExercises,
    };

    try {
      const response = await sessionCreate(body, token);

      if (response.statusCode === 200) {
        toast.success(t('success.sessionCreated'));
        navigate('/librarypage/session/' + response.data._id);
      } else {
        toast.error(response.error || t('errors.somethingWentWrong'));
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const create = async () => {
    if (isExercisesInvalid()) return;

    const date = moment.utc(currentSessionDate).format('YYYY-MM-DD');
    const body = {
      title,
      Instructions,
      trainerId: auth._id,
      exercises: currentExercises,
      userId: clientId,
      teamId,
      date,
    };

    try {
      const response = await (teamId
        ? assignTeamSession(body)
        : assignClientSessionApi(body));

      if (response.statusCode === 200) {
        toast.success(t('success.sessionCreated'));
        setNewSessionsDates([
          ...newSessionsDates,
          moment(currentSessionDate).format('DD-MM-YYYY'),
        ]);
        fetchData();

        const session = response.data;
        setSelectedSession({ ...session, assignedExercises: currentExercises });
        setSelectedSessionId(session._id);
      } else {
        throw new Error(t('errors.creatingSession'));
      }
    } catch (err) {
      throw new Error(err.message || t('errors.somethingWentWrong'));
    }
  };

  const sessionSubmit = async () => {
    setLoading(true);
    try {
      if (!sessionExists()) {
        await create();
      } else {
        await update();
      }
    } catch (err) {
      console.log('err', err);
      toast.error(err.message || t('errors.somethingWentWrong'));
    }
    setLoading(false);
  };

  const handleDeleteClientSession = async () => {
    try {
      setLoading(true);
      const sessionId = selectedSession?._id;
      const response = await (teamId
        ? deleteTeamSession(sessionId, auth.token)
        : deleteAssignedSessionById(sessionId, auth.token));

      if (response.statusCode === 200) {
        if (sessions?.data?.length === 1) {
          setRemovedSessionsDates([
            ...removedSessionsDates,
            moment(date).format('DD-MM-YYYY'),
          ]);
        }

        fetchData();
        resetState();
        toast.success(response.message || t('success.clientSessionRemoved'));
      }
      setDeleteModalVisibility(false);
    } catch (err) {
      toast.error(err.message || t('errors.somethingWentWrong'));
      setDeleteModalVisibility(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onChangeDate(date);
    return () => {
      if (teamId) dispatch(reset_data_team_session());
      else if (clientId) dispatch(reset_assign_session_data());
    };
  }, []);

  useEffect(() => {
    if (sessions.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [sessions.loading]);

  useEffect(() => {
    fetchData();
  }, [currentSessionDate, clientId, teamId]);

  useEffect(() => {
    setSessionInfo(selectedSession);
  }, [selectedSession]);

  useEffect(() => {
    const initializeSession = !title && !Instructions;
    const sessionDateChanged = !(sessions.data || [])
      .map(({ _id }) => _id)
      .includes(selectedSessionId);
    if (sessions.data && (initializeSession || sessionDateChanged)) {
      const session = sessions.data[0];
      setSessionInfo(session, true);
    }
  }, [sessions]);

  useEffect(() => {
    const newDate = moment.utc(date);
    const current = moment.utc(currentSessionDate);
    if (!moment(newDate).isSame(current, 'day')) {
      onChangeDate(newDate);
    }
  }, [location]);

  return {
    teamId,
    clientId,
    sessions: sessions?.data,
    weekDay,
    fullDate,
    currentExercises,
    expandExercise,
    loading,
    currentSessionDate,
    title,
    Instructions,
    selectedSession,
    deleteModalVisibility,
    importModalVisibility,
    exercisesModalVisibility,
    selectedSessionId,
    newSessionsDates,
    removedSessionsDates,
    saveSessionToLibrary,
    setSelectedSessionId,
    preDate,
    nextDate,
    addSuperset,
    removeSuperset,
    setExpandExercise,
    onChangeDate,
    setCurrentExercises,
    sessionSubmit,
    sessionExists,
    setDeleteModalVisibility,
    setImportModalVisibility,
    setExercisesModalVisibility,
    setSelectedSession,
    handleAddExercisesToSession,
    handleImportSessionExercises,
    handleDeleteClientSession,
    setTitle,
    setInstructions,
    resetState,
  };
}
