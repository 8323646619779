import moment from 'moment';

import {
  HIT_ASSIGNTEAMSESSIONS_REQUEST,
  SET_ASSIGNED_TEAM_SESSION,
  FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
  SET_ALL_ASSIGNED_SESSION,
  RESET_TEAM_SESSION_ACTION,
  SET_TEAM_SIMPLE_SESSION,
  UPDATE_TEAM_SESSION_DATE,
} from '../constants';

import {
  team_assigned_session,
  team_assigned_session_byId,
  team_assigned_session_byDate,
  getSessionById,
  update_team_session_date,
} from '../../Service/Api';
import { toast } from 'react-toastify';
import i18n from '../../i18n.js';

export const set_team_session_by_date = (team, date) => async (dispatch) => {
  dispatch({
    type: HIT_ASSIGNTEAMSESSIONS_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('auth'));
    const response = await team_assigned_session_byDate(
      token,
      date,
      team,
      user._id,
    );

    if (response && response.statusCode === 200) {
      dispatch({
        type: SET_ASSIGNED_TEAM_SESSION,
        payload: { data: response.data },
      });
    } else {
      dispatch({ type: RESET_TEAM_SESSION_ACTION });
    }
  } catch (err) {
    dispatch({
      type: FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
      payload: {
        error: err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const set_team_session_by_id = (id) => async (dispatch) => {
  dispatch({
    type: HIT_ASSIGNTEAMSESSIONS_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');

    const response = await team_assigned_session_byId(token, id);

    if (response && response.statusCode === 200) {
      dispatch({
        type: SET_ASSIGNED_TEAM_SESSION,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
      payload: {
        error: err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const set_allTeam_session = (teamId) => async (dispatch) => {
  dispatch({
    type: HIT_ASSIGNTEAMSESSIONS_REQUEST,
  });

  try {
    const token = localStorage.getItem('token');

    const response = await team_assigned_session(teamId, token);

    if (response && response.statusCode === 200) {
      const mappedData = response.data.reduce(
        (result, session) => ({
          ...result,
          [moment.utc(session.date).format('MM DD YYYY').toString()]: session,
        }),
        {},
      );
      dispatch({
        type: SET_ALL_ASSIGNED_SESSION,
        payload: {
          data: mappedData,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
      payload: {
        error: err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const reset_data_team_session = () => (dispatch) => {
  dispatch({ type: RESET_TEAM_SESSION_ACTION });
};

export const set_simple_team_session = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await getSessionById(token, id);

    if (response && response.statusCode === 200) {
      dispatch({
        type: SET_TEAM_SIMPLE_SESSION,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
      payload: {
        error: err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const set_update_assigned_team_date = (body, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await update_team_session_date(token, id, body);

    if (response && response.statusCode === 200) {
      dispatch({
        type: UPDATE_TEAM_SESSION_DATE,
        payload: {
          data: response.data,
        },
      });
      toast.success(i18n.t('success.sessionUpdated'));
    } else {
      throw response;
    }
  } catch (err) {
    toast.error(i18n.t('errors.somethingWentWrong'));
    dispatch({
      type: FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
      payload: {
        error: err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};
