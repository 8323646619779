import {
  HIT_SESSION_FEED_REQUEST,
  SET_SESSION_FEED_DATA,
  FAILED_SESSION_FEED_REQUEST,
  RESET_SESSION_FEED,
  FILTER_SESSION_FEED_REQUEST,
  FAILED_SEARCH_SESSION_FEED,
} from "../constants";

const init = {
  data: [],
  error: null,
  loading: false,
  filterItems: null,
};

export const sessionFeedReducer = (state = init, { type, payload }) => {
  switch (type) {
    case HIT_SESSION_FEED_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_SESSION_FEED_DATA: {
      return {
        ...state,
        data: payload["data"],
        loading: false,
        error: null,
        filterItems: null,
      };
    }
    case FAILED_SESSION_FEED_REQUEST: {
      return {
        ...state,
        error: payload["error"],
        loading: false,
      };
    }
    case RESET_SESSION_FEED: {
      return {
        ...state,
        data: [],
        error: null,
        loading: false,
      };
    }
    case FILTER_SESSION_FEED_REQUEST: {
      return {
        ...state,
        loading: false,
        error: null,
        filterItems: payload.data,
      };
    }
    case FAILED_SEARCH_SESSION_FEED: {
      return {
        ...state,
        loading: false,
        error: null,
        filterItems: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};
