import {
  BlockOutlined,
  CalendarMonth,
  ChatOutlined,
  HowToReg,
  InsightsOutlined,
  PersonOutlineSharp,
} from '@mui/icons-material';
import { Badge, Icon } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/shared/Card';
import { useTranslation } from 'react-i18next';

const ClientListItem = ({
  user,
  status,
  setClientIdForChat,
  setClientIdForStatus,
  hasNewChatMessages,
  getStatusChangeText,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CustomIcon = ({ Icon, sx = {} }) => (
    <Icon
      sx={{
        width: 35,
        height: 35,
        color: 'silver',
        '&:hover': { color: 'white' },
        ...sx,
      }}
    />
  );

  const getStatusChangeIcon = (status) => {
    switch (status) {
      case 'Active':
        return HowToReg;
      default:
        return BlockOutlined;
    }
  };

  return (
    <Card title={user.Name} avatar={user.avatar}>
      <div className="images-chart">
        <div
          className="tooltip-icons-cstm"
          onClick={() => navigate(`/client/profile/${user._id}`)}
        >
          <CustomIcon
            Icon={PersonOutlineSharp}
            sx={{ mb: -0.5, mr: -0.5, height: 38, width: 38 }}
          />
          <span className="tooltiptext">{t('tooltips.profile')}</span>
        </div>
        {setClientIdForChat && (
          <Badge color="warning" variant={hasNewChatMessages ? 'dot' : 'none'}>
            <div
              onClick={() => setClientIdForChat(user._id)}
              className="tooltip-icons-cstm"
            >
              <CustomIcon Icon={ChatOutlined} sx={{ mb: -0.5 }} />
              <span className="tooltiptext">{t('tooltips.chat')}</span>
            </div>
          </Badge>
        )}
        <div
          className="tooltip-icons-cstm"
          onClick={() => navigate(`/client/calendars/${user._id}`)}
        >
          <CustomIcon Icon={CalendarMonth} />
          <span className="tooltiptext">{t('tooltips.calendar')}</span>
        </div>
        <div
          className="tooltip-icons-cstm"
          onClick={() => navigate(`/statistics?userId=${user._id}`)}
        >
          <CustomIcon Icon={InsightsOutlined} />
          <span className="tooltiptext">{t('tooltips.chart')}</span>
        </div>
        {setClientIdForStatus && (
          <div
            className="tooltip-icons-cstm"
            onClick={() => setClientIdForStatus(user._id)}
          >
            <CustomIcon Icon={getStatusChangeIcon()} sx={{ ml: -1 }} />
            <span
              className="tooltiptext"
              style={status !== 'Deactive' ? { background: '#ff3333' } : {}}
            >
              {getStatusChangeText(status)}
            </span>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ClientListItem;
