import {
  HIT_CLIENT_REQUEST,
  GET_CLIENTS_DATA,
  GET_CLIENT_DATA,
  GET_CLIENTS_BY_IDS,
  FAILED_CLIENT_DATA,
  UPDATE_CLIENT_STATUS,
  UPDATE_CLIENT,
  GET_ALL_CLIENTS_REQUEST,
  ASSIGN_COACH,
  DELETE_CLIENT,
  SEND_CLIENT_JOIN_REQUEST,
} from '../constants';

const initialValue = {
  selectedClient: null,
  data: [],
  error: '',
  loading: false,
};

export const clientReducer = (state = initialValue, { type, payload }) => {
  switch (type) {
    case HIT_CLIENT_REQUEST: {
      return { ...state, loading: true };
    }

    case GET_CLIENTS_DATA: {
      return { ...state, data: payload.data, loading: false };
    }

    case GET_CLIENT_DATA: {
      return { ...state, selectedClient: payload.data, loading: false };
    }
    case GET_CLIENTS_BY_IDS: {
      return { ...state, data: payload.data, loading: false };
    }
    case DELETE_CLIENT: {
      return {
        ...state,
        data: state.data.filter((client) => client._id !== payload.data),
        selectedClient: null,
        loading: false,
      };
    }

    case FAILED_CLIENT_DATA: {
      return {
        ...state,
        loading: false,
        error: payload?.error,
      };
    }
    case ASSIGN_COACH: {
      return {
        ...state,
        data: [...state.data, payload.data],
        loading: false,
      };
    }
    case UPDATE_CLIENT: {
      return {
        ...state,
        data: state.data.map((client) =>
          client._id === payload.data._id ? payload.data : client,
        ),
        loading: false,
      };
    }
    case UPDATE_CLIENT_STATUS: {
      const filtred = state.data.filter((obj) => {
        return !payload.filtredIds.includes(obj._id);
      });
      return {
        ...state,
        loading: false,
        data: [...filtred],
      };
    }
    case GET_ALL_CLIENTS_REQUEST: {
      return { ...state, data: payload.data.items, loading: false };
    }
    case SEND_CLIENT_JOIN_REQUEST: {
      return { ...state, loading: false };
    }

    default: {
      return { ...state };
    }
  }
};
