import './Chats.css';
import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiModal from '../../components/shared/MuiModal';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import useChat from './useChat';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const BootstrapDialogTitle = (props) => (
  <DialogTitle sx={{ m: 0, p: 2 }}>
    <IconButton
      aria-label="close"
      onClick={props.onClose}
      sx={{
        position: 'absolute',
        right: 10,
        top: 10,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
);

const Chat = ({ open, closeTab, clientId, team, noti, focusMessage }) => {
  const {
    messages,
    loading,
    allMessagesLoaded,
    user,
    chatBoxRef,
    input,
    getFirstAndLastName,
    onModalClose,
    onScroll,
    inputHandler,
    sendMessage,
  } = useChat({ open, closeTab, clientId, team, noti, focusMessage });

  const navigate = useNavigate()

  const handleMessageRedirect = (workoutId) => {
    navigate(`/workout/${workoutId}`)
  }

  return (
    <MuiModal
      open={open}
      onClose={onModalClose}
      sx={{ width: 0.8, maxWidth: 1250 }}
      onSubmit={sendMessage}
      submitBtnText="Send"
      hideCloseBtn
    >
      <BootstrapDialogTitle onClose={onModalClose} />

      <div className="empty-space-10"></div>

      <Stack
        className="chat-form-info"
        p={0}
        height={1}
        spacing={1}
        justifyContent="space-between"
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          className="messages-info"
          onScroll={onScroll}
          ref={chatBoxRef}
          sx={{ width: '100% !important', height: 1, textAlign: 'center' }}
        >
          {loading && <CircularProgress size="2rem" />}

          {allMessagesLoaded && (
            <Typography sx={{ color: 'secondary.main', textAlign: 'center' }}>
              Beginning of chat
            </Typography>
          )}

          {messages.map(({ _id, data, msg, userFrom, msgFrom, createdOn }, idx) => (
            <Box
              key={idx + createdOn}
              className={msgFrom === user._id ? 'myMessage' : 'fromThem'}
              sx={{
                mt:
                  clientId || msgFrom === user._id
                    ? '0px !important'
                    : '25px !important',
              }}
            >
              {msgFrom !== user._id && team && (
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    top: -20,
                    fontSize: '13px',
                    left: 1,
                    color: 'white',
                  }}
                >
                  {getFirstAndLastName(userFrom)}
                </Typography>
              )}
              <Typography
                sx={{
                  ...{
                    fontSize: 11,
                    position: 'absolute',
                    bottom: -20,
                    whiteSpace: 'nowrap',
                    color: 'secondary.main',
                  },
                  ...(msgFrom === user._id ? { left: 5 } : { right: 5 }),
                }}
              >
                {moment(new Date(createdOn)).format('hh:mm A MM/DD')}
              </Typography>

              <Typography
                id={_id}
                sx={{ color: msgFrom === user._id ? 'white' : 'silver' }}
                style={{textDecoration: data?.type === 'WORKOUT' ? 'underline' : 'none', cursor: data?.type === 'WORKOUT' ? 'pointer' : 'auto'}}
                onClick={() => data?.type === 'WORKOUT' && handleMessageRedirect(data.workoutId)}
              >
                {msg}
              </Typography>
            </Box>
          ))}
        </Stack>

        <Box className="messages-type-box">
          <textarea
            value={input}
            disabled={messages.length === 0 && !allMessagesLoaded}
            onChange={inputHandler}
            name="message-text"
            rows="3"
            style={{ borderRadius: 10 }}
            placeholder="Enter a message..."
          />
        </Box>
      </Stack>
    </MuiModal>
  );
};

export default Chat;
