import { Box, Modal, Stack, Typography } from '@mui/material';
import MuiButton from './MuiButton';
import { useTranslation } from 'react-i18next';

const MuiModal = ({
  open,
  hideCloseBtn,
  onClose,
  onSubmit,
  title,
  description,
  children,
  closeBtnText,
  submitBtnText,
  isLoading,
  sx,
  sxButtons,
  isSubmitDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={2}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 400,
          bgcolor: 'background.main',
          border: '2px solid #32B5FF',
          boxShadow: 24,
          borderRadius: 2.5,
          p: 4,
          ...sx,
        }}
      >
        {title && (
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            fontWeight="bold"
          >
            {title}
          </Typography>
        )}
        <form
          onSubmit={(e, data) => {
            e.preventDefault();
            onSubmit?.(e, data);
          }}
        >
          {description && <Typography pb={2}>{description}</Typography>}

          <Box sx={{ overflow: 'auto', maxHeight: '80vh' }}>{children}</Box>

          <Stack
            direction="row"
            spacing={1.5}
            justifyContent="end"
            sx={{ mt: 2, ...sxButtons }}
          >
            {!hideCloseBtn && (
              <MuiButton sx={{ maxWidth: 100 }} onClick={onClose} transparent>
                {closeBtnText || t('buttons.close')}
              </MuiButton>
            )}

            {onSubmit && (
              <MuiButton
                type="submit"
                isLoading={isLoading}
                isSubmitDisabled={isSubmitDisabled}
              >
                {submitBtnText || t('buttons.submit')}
              </MuiButton>
            )}
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default MuiModal;
