import {
  HIT_PROGRAM_REQUEST,
  SET_PROGRAM_DATA,
  FAILED_PROGRAM_REQUEST,
  UPDATE_PROGRAM_REQUEST,
} from "../constants";

const initial = {
  data: {},
  error: null,
  loading: false,
};

export const programReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case HIT_PROGRAM_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_PROGRAM_DATA: {
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    }

    case FAILED_PROGRAM_REQUEST: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case UPDATE_PROGRAM_REQUEST: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};
