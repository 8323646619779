import { Info, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';

const SelectInput = ({
  register,
  label,
  labelInfo,
  type,
  placeholder,
  name,
  variant,
  endAdornment,
  options,
  selectOptions,
  sx,
  inputSx,
  containerSx,
  formControlSx,
  lightTheme,
  shadow = true,
  noLabel,
  error,
  control,
  disabled,
  hideError,
  ...props
}) => {
  const inputStyle = {
    color: !lightTheme ? 'white' : 'black',
    bgcolor: !lightTheme ? 'background.light' : 'white',
    boxShadow: shadow ? '0px 4px 4px rgb(0 0 0 / 25%)' : 'none',
    borderRadius: 2.5,
    width: 1,
    pr: 1,
  };
  const showLabel = !noLabel && label;
  return (
    <Box sx={{ mb: 1, ...containerSx }}>
      <FormControl
        sx={{
          width: 1,
          '& .MuiFormHelperText-root': { ml: '1px' },
        }}
      >
        {showLabel && (
          <InputLabel
            shrink
            sx={{ color: disabled ? 'disabled.main' : 'white', ml: -1.5 }}
          >
            {label}
            {labelInfo && (
              <Tooltip title={labelInfo}>
                <InfoOutlined sx={{ ml: 1, mb: 0.3, width: 19, height: 19 }} />
              </Tooltip>
            )}
          </InputLabel>
        )}

        <Select
          inputProps={{
            sx: { py: '10px', ...inputStyle, ...inputSx },
            MenuProps: {
              PaperProps: { sx: { maxHeight: 250 } },
              MenuListProps: { sx: { ...inputStyle, borderRadius: 0 } },
            },
            ...props.inputProps,
          }}
          sx={{
            mt: showLabel ? 2 : 0,
            borderRadius: '10px',
            '& .Mui-disabled': { WebkitTextFillColor: '#71797E !important' },
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            ...sx,
          }}
          renderValue={
            props.value
              ? undefined
              : () => (
                  <Typography sx={{ color: 'secondary.main' }}>
                    {placeholder}
                  </Typography>
                )
          }
          disabled={disabled}
          error={!!error}
          {...props}
        >
          {selectOptions.map((option, idx) => (
            <MenuItem key={idx} value={option.value || option}>
              {option.label || option}
            </MenuItem>
          ))}
        </Select>
        {!hideError && error && (
          <FormHelperText sx={{ color: 'error.main', height: 15 }}>
            {error?.message.toString()}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default SelectInput;
