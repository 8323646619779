import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createNewTeam, updateTeam } from '../../../redux/actions/Teams';

const useSaveTeamModal = (onClose, trainerId, team) => {
  const dispatch = useDispatch();

  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { members: [] } });

  useEffect(() => {
    if (!team) return;
    setValue('members', team.member);
    setValue('TeamName', team.TeamName);
    setValue('Sport', team.Sport);
    setValue('accesscode', team.accesscode);
  }, [team]);

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const onAddNewTeam = handleSubmit((data, event) => {
    event.preventDefault();
    if (team)
      dispatch(updateTeam(team._id, { ...data, trainerId }, onCloseModal));
    else dispatch(createNewTeam({ ...data, trainerId }, onCloseModal));
  });

  return {
    onAddNewTeam,
    onCloseModal,
    control,
    register,
    errors,
    onSubmit: onAddNewTeam,
  };
};

export default useSaveTeamModal;
