import { useTranslation } from 'react-i18next';
import ExerciseItem from './ExerciseItem';

export default function SessionExercisesList({
  exercises,
  updateExercisesList,
  addSuperset,
  removeSuperset,
  isExpanded,
  isLibrarySection,
}) {
  const { t } = useTranslation();

  const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  const moveUp = (i, j) => {
    if (j > 0) {
      const exercisesCopy = [...exercises];
      let aux = exercisesCopy[i][j];
      exercisesCopy[i][j] = exercisesCopy[i][j - 1];
      exercisesCopy[i][j - 1] = aux;
      updateExercisesList(exercisesCopy);
    } else if (i > 0) {
      let exercisesCopy = [...exercises];
      let indexAux = i - 1;
      let exercisesAbove = exercisesCopy[indexAux];
      let insertFlag = false;
      while (indexAux > 0 && exercisesAbove.length > 1) {
        indexAux = indexAux - 1;
        exercisesAbove = exercisesCopy[indexAux];
        insertFlag = true;
      }
      if (insertFlag) {
        let inserItem = exercisesCopy[i];
        exercisesCopy.splice(i, 1);
        exercisesCopy = insert(exercisesCopy, indexAux + 1, inserItem);
      } else {
        exercisesCopy[indexAux] = exercisesCopy[i];
        exercisesCopy[i] = exercisesAbove;
      }
      updateExercisesList(exercisesCopy);
    }
  };

  const moveDown = (i, j) => {
    if (isSupersetAndNotLastIndex(i, j)) {
      const exercisesCopy = [...exercises];
      let aux = exercisesCopy[i][j];
      exercisesCopy[i][j] = exercisesCopy[i][j + 1];
      exercisesCopy[i][j + 1] = aux;
      updateExercisesList(exercisesCopy);
    } else if (i < exercises.length - 1) {
      const exercisesCopy = [...exercises];
      let aux = exercisesCopy[i + 1];
      exercisesCopy[i + 1] = exercisesCopy[i];
      exercisesCopy[i] = aux;
      updateExercisesList(exercisesCopy);
    }
  };

  const isSupersetAndNotLastIndex = (i, j) => {
    return j >= 0 && j < exercises[i].length - 1;
  };

  const removeExercise = (i, j) => {
    const exercisesCopy = [...exercises];
    if (j >= 0) {
      exercisesCopy[i].splice(j, 1);
    } else {
      exercisesCopy.splice(i, 1);
    }
    updateExercisesList(exercisesCopy.filter((e) => e.length));
  };

  const handleSetsUpdate = (exerciseData, i, j) => {
    const exercisesCopy = [...exercises];
    exercisesCopy[i][j].metadata = exerciseData;
    updateExercisesList(exercisesCopy);
  };

  const onChangeNotes = (newValue, i, j) => {
    const exercisesCopy = [...exercises];
    exercisesCopy[i][j].Notes = newValue;
    updateExercisesList(exercisesCopy);
  };

  const renderExercises = () => {
    if (exercises.length === 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10%' }}>
          <span>{t('texts.addExercisesOrImportSessions')}</span>
        </div>
      );
    }

    return (
      <div style={isLibrarySection ? { marginLeft: 15 } : {}}>
        {exercises?.map((innerExercises, i) => {
          const isSuperset = innerExercises.length > 1;
          return innerExercises.map((exercise, j) => {
            const isLast = j === innerExercises.length - 1;
            const isEndIndex =
              i === exercises.length - 1 &&
              j === exercises[exercises.length - 1].length - 1;
            return (
              <div key={i + j + 'item'}>
                <ExerciseItem
                  isLibrarySection={isLibrarySection}
                  exercise={exercise}
                  indexI={i}
                  indexJ={j}
                  moveUp={moveUp}
                  moveDown={moveDown}
                  handleSetsUpdate={handleSetsUpdate}
                  removeExercise={removeExercise}
                  onChangeNotes={onChangeNotes}
                  isExpanded={isExpanded}
                  isSuperset={isSuperset}
                  isEndIndex={isEndIndex}
                />
                <div>
                  {isSuperset && (
                    <div className="btn-superset-table btn-superset-table-cardios">
                      {!isLast ? (
                        <button onClick={() => removeSuperset(i, j)}>
                          - superset
                        </button>
                      ) : (
                        !isEndIndex && (
                          <button onClick={() => addSuperset(i, j)}>
                            + superset
                          </button>
                        )
                      )}
                    </div>
                  )}
                  {!isSuperset && (
                    <div className="btn-superset-table btn-superset-table-cardios">
                      {!isEndIndex && (
                        <button onClick={() => addSuperset(i)}>
                          + superset
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          });
        })}
      </div>
    );
  };

  return (
    <div className="data-line">
      <div>{renderExercises()}</div>
    </div>
  );
}
