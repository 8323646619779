import MuiButton from '../../../components/shared/MuiButton';
import { Stack } from '@mui/material';
import useForgotPassword from './useForgotPassword';
import Logo from '../../../components/shared/Logo';
import EmailInput from '../../../components/shared/EmailInput';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate() 
  const { register, onSubmit, isLoading, errors } = useForgotPassword();

  return (
    <div className="login-page inner-page-header">
      <div className="containers">
        <div className="forms">
          <div className="form login">
            <Logo />
            <form onSubmit={onSubmit}>
              <Stack spacing={1}>
                <EmailInput register={register} error={errors?.email} />

                <Stack spacing={2} alignItems="center">
                  <MuiButton
                    fullWidth
                    sx={{ width: 1 }}
                    type="submit"
                    isLoading={isLoading}
                  >
                    Forgot Password
                  </MuiButton>
                  <MuiButton
                    onClick={() => navigate('/login')}
                    transparent
                    sx={{ width: 1 }}
                    fullWidth
                  >
                    Back
                  </MuiButton>
                </Stack>
              </Stack>
            </form>
          </div>
        </div>

        <div className="space-40px"></div>
        <div className="space-80px"></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
