import {
  HIT_CLIENT_SESSION_REQUEST,
  SET_CLIENT_SESSION,
  FAILED_CLIENT_SESSION,
  EMPTY_DATA,
  STOP_LOADING,
} from "../constants";

const initialVal = {
  data: null,
  loading: false,
  error: null,
};
export const clientSessionReducer = (state = initialVal, { type, payload }) => {
  switch (type) {
    case HIT_CLIENT_SESSION_REQUEST: {
      return { ...state, loading: true };
    }

    case SET_CLIENT_SESSION: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }

    case FAILED_CLIENT_SESSION: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }

    case STOP_LOADING: {
      return { ...state, loading: false };
    }
    case EMPTY_DATA: {
      return {
        ...state,
        data: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};
