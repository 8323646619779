import { AddOutlined } from '@material-ui/icons';
import { Stack, Typography } from '@mui/material';
import MuiButton from '../../../components/shared/MuiButton';
import MuiModal from '../../../components/shared/MuiModal';
import MuiTable from '../../../components/shared/MuiTable';
import Title from '../../../components/shared/Title';
import { TrainerProfileNavMenu } from '../../trainer-profile/TrainerProfile';
import PaymentCard from './PaymentCard';
import PaymentCardModal from './PaymentCardModal';
import usePaymentInfo from './usePaymentInfo';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './../../../stripe/utils';
import { useTranslation } from 'react-i18next';

const PaymentInfo = () => {
  const {
    getCardBrandImage,
    setCardToBeRemoved,
    onRemoveCard,
    setShowAddCardModal,
    setDefaultCard,
    onCreateCard,
    showAddCardModal,
    bankCards,
    paymentsTableData,
    isLoading,
    cardToBeRemoved,
    subscriptionStatus,
  } = usePaymentInfo();
  const { t } = useTranslation();

  return (
    <Stack>
      <TrainerProfileNavMenu
        page="paymentinfo"
        subscriptionStatus={subscriptionStatus}
      />

      <Stack
        className="boxes"
        alignItems="center"
        spacing={4}
        sx={{ m: 0, mt: 1.5 }}
      >
        <Stack maxWidth={850} width={1} spacing={6}>
          <Stack spacing={3} width={1}>
            <Stack direction="row" justifyContent="space-between">
              <Title>{t('texts.registeredCards')}</Title>
              <MuiButton
                sx={{ maxWidth: 160 }}
                icon={<AddOutlined />}
                onClick={() => setShowAddCardModal(true)}
              >
                {t('buttons.newCard')}
              </MuiButton>
            </Stack>
            {bankCards.items.length ? (
              bankCards.items.map((card) => (
                <PaymentCard
                  removeDisabled={card.default}
                  key={card._id}
                  card={card}
                  getCardBrandImage={getCardBrandImage}
                  setCardToBeRemoved={setCardToBeRemoved}
                  setDefault={setDefaultCard}
                />
              ))
            ) : (
              <Typography sx={{ color: 'error.main' }}>
                {t('texts.noRegisteredCards')}
              </Typography>
            )}
          </Stack>

          <Stack spacing={3} width={1}>
            <Title>{t('texts.previousPayments')}</Title>

            <MuiTable
              headers={[t('texts.amount'), t('texts.receipt')]}
              rows={paymentsTableData}
              noDataMessage={t('texts.noPaymentsHistory')}
              page={0}
              pageSize={10}
            />
          </Stack>
        </Stack>
      </Stack>

      <Elements stripe={stripePromise}>
        <PaymentCardModal
          onSubmit={onCreateCard}
          open={!!showAddCardModal}
          onClose={() => setShowAddCardModal(false)}
          isLoading={isLoading}
        />
      </Elements>
      <MuiModal
        title={t('modals.titles.removeCard')}
        description={t('modals.phrases.areYouSureDeleteCard', {
          cardBrand: cardToBeRemoved?.brand,
          cardNumber: cardToBeRemoved?.cardno,
        })}
        onSubmit={onRemoveCard}
        open={!!cardToBeRemoved}
        onClose={() => setCardToBeRemoved(false)}
        isLoading={isLoading}
        submitBtnText={t('buttons.remove')}
        closeBtnText={t('buttons.cancel')}
      />
    </Stack>
  );
};

export default PaymentInfo;
