import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { customFetch } from '../../../Service/Api';

const useChangePassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  var token = localStorage.getItem('token');

  const onSubmit = handleSubmit(async (data, event) => {
    event.preventDefault();
    setIsLoading(true);

    customFetch(`${process.env.REACT_APP_API_URL}/api/users/newPassword`, {
      method: 'POST',
      body: JSON.stringify({ newPassword: data.password }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    })
      .then((res) => res.json())
      .then((resdata) => {
        if (resdata.statusCode === 200) {
          toast.success(resdata.data);
          navigate('/login');
        } else {
          toast.error(
            resdata.error || 'Something went wrong. Please try again later.',
          );
        }
      })
      .finally(() => setIsLoading(false));
  });

  return { register, onSubmit, getValues, isLoading, errors };
};

export default useChangePassword;
