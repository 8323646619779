import {
  HIT_PAYMENT_INFO_REQUEST,
  SET_PAYMENT_INFO_REQUEST,
  FAILED_PAYMENT_INFO_REQUEST,
} from '../constants';
import { getPaymentInfo } from '../../Service/Api';
import i18n from '../../i18n';

export const setPaymentInfo = () => async (dispatch) => {
  // * intial dispatch
  dispatch({
    type: HIT_PAYMENT_INFO_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');
    const { _id } = JSON.parse(localStorage.getItem('auth'));
    const response = await getPaymentInfo(token, _id);

    if (response.statusCode === 200) {
      dispatch({
        type: SET_PAYMENT_INFO_REQUEST,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_PAYMENT_INFO_REQUEST,
      payload: {
        error: err?.message || err?.error || i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};
