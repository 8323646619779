import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteClient,
  getClientById,
  updateClient,
} from '../../redux/actions/Clients';

const useClientProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const weightUnits = [
    { label: 'Kilograms', value: 'Kgs' },
    { label: 'Pounds', value: 'Lbs' },
  ];

  const heightUnits = ['Centimeters', 'Feet & Inches'];

  const genderOptions = ['Male', 'Female', 'Other'];
  const clientsData = useSelector((state) => state.client);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);
  const [chatModalOpened, setChatModalOpened] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { userId } = useParams();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const setFormFields = () => {
    const {
      Name,
      email,
      DOB,
      gender,
      weight,
      height,
      heightUnit,
      weightUnit,
      goals,
    } = clientsData.selectedClient;
    const formattedDOB = DOB
      ? moment(new Date(DOB)).format('yyyy-MM-DD')
      : undefined;

    setValue('Name', Name);
    setValue('email', email);
    setValue('DOB', formattedDOB);
    setValue('gender', gender);
    setValue('weightUnit', weightUnit);
    setValue('weight', weight);
    setValue('heightUnit', heightUnit);
    setValue('height', height);
    setValue('goals', goals);
  };

  useEffect(() => {
    if (clientsData.selectedClient?._id !== userId)
      dispatch(getClientById(userId));
  }, []);

  useEffect(() => {
    if (clientsData.selectedClient) setFormFields();
  }, [clientsData.selectedClient]);

  const removeClient = () => {
    setLoading(true);
    dispatch(
      deleteClient(userId, () => {
        setConfirmationModalOpened(false);
        setLoading(false);
        navigate('/client');
      }),
      () => {
        setConfirmationModalOpened(false);
        setLoading(false);
      },
    );
  };

  const updateUserProfile = handleSubmit(async (data, event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(
      updateClient(userId, data, () => {
        setLoading(false);
      }),
    );
  });

  return {
    client: clientsData.selectedClient,
    chatModalOpened,
    setChatModalOpened,
    confirmationModalOpened,
    setConfirmationModalOpened,
    updateUserProfile,
    removeClient,
    genderOptions,
    weightUnits,
    heightUnits,
    isLoading,
    register,
    control,
    errors,
    userId,
  };
};
export default useClientProfile;
