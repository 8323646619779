import {
  HIT_TOOL_REQUEST,
  SET_TOOL_DATA_REQUEST,
  FAILED_TOOL_DATA_REQUEST,
} from '../constants';

const init = {
  data: [],
  error: null,
  loading: false,
};

export const toolReducer = (state = init, { type, payload }) => {
  switch (type) {
    case HIT_TOOL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_TOOL_DATA_REQUEST: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
      };
    }
    case FAILED_TOOL_DATA_REQUEST: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default: {
      return { ...state };
    }
  }
};
