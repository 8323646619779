import React from 'react';
import { Link } from 'react-router-dom';
import './Payment.css';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const PaymentCards = ({ data, activeClients, isTrialPeriod, currentPrice }) => {
  const priceAllowed = (clientCount, priceId) => {
    return (
      !currentPrice ||
      (activeClients <= clientCount && currentPrice?.id !== priceId)
    );
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="pricing-table-box">
        <div className="row">
          {data.map((item, idx) => {
            return (
              <div
                className={idx < 4 ? 'col-lg-3' : 'col-lg-4'}
                key={item?.id}
                style={{
                  marginBottom: 30,
                  opacity: priceAllowed(item.clientCount, item.id) ? 1 : 0.5,
                }}
              >
                <div className="pricing-table-content">
                  {item?.popular && (
                    <div
                      className="most-popular-txt-pricing-table"
                      style={{ position: 'absolute', right: 5, top: 5 }}
                    >
                      <p>{t('texts.mostPopular')}</p>
                    </div>
                  )}
                  <div className="pricing-box-title">
                    <h2>{item.name}</h2>
                  </div>
                  <div className="table-price-amount">
                    <h3 className="table-price">
                      ${item.amount}
                      <span className="txt-month"> / {t('texts.month')}</span>
                    </h3>
                    <p className="txt-month">
                      {t('texts.upTo')} {item.clientCount} {t('texts.users')}
                    </p>
                  </div>
                  <div
                    className="table-pricing-btn"
                    style={{ display: isTrialPeriod ? 'none' : 'block' }}
                  >
                    <Link
                      style={{
                        pointerEvents: priceAllowed(item.clientCount, item.id)
                          ? 'all'
                          : 'none',
                      }}
                      to={`/payment/${item.id}`}
                      className="btn-pricing-start"
                    >
                      {t('texts.choosePlan')}
                    </Link>
                  </div>
                  <Divider sx={{ color: 'grey', mt: 2, mb: 1.5 }} />
                  <div className="table-price-amount">
                    <p className="txt-month" style={{ marginBottom: 5 }}>
                      <b>${(item.amount / item.clientCount).toFixed(2)}</b>{' '}
                      {t('texts.perUser')}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PaymentCards;
