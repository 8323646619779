import { Stack, Typography } from '@mui/material';
import ExpandedInputs from './ExpandedInputs';
import useExerciseSetExpanded from './useExerciseSetExpanded';
import { useTranslation } from 'react-i18next';

export default function ExerciseSetExpanded({
  indexI,
  indexJ,
  exercise,
  handleSetsUpdate,
}) {
  const {
    initialMetricOptions,
    metricsCombinations,
    metrics,
    metricsUnits,
    firstMetricType,
    secondMetricType,
    firstMetricUnit,
    secondMetricUnit,
    handleRemoveSet,
    handleAddNewSet,
    handleInputsUpdate,
    handleFirstMetricChange,
    handleFirstMetricUnitChange,
    handleSecondMetricChange,
    handleSecondMetricUnitChange,
  } = useExerciseSetExpanded({
    indexI,
    indexJ,
    exercise,
    handleSetsUpdate,
  });

  const { t } = useTranslation();

  return (
    <div className="exercise-ins-table" style={{ marginTop: 20 }}>
      <Stack direction="row" alignItems="end" spacing={2} mb={1}>
        <Typography sx={{ mr: '3px' }}>{t('texts.firstMetric')}:</Typography>
        <span className="drowdown-btn-div">
          <select
            value={firstMetricType}
            onChange={handleFirstMetricChange}
            className="select-tag-icon"
            style={{ width: 140 }}
          >
            {initialMetricOptions?.map((metric) => (
              <option
                key={metric}
                value={Object.keys(metrics).find(
                  (key) => metrics[key] === metric,
                )}
              >
                {metric}
              </option>
            ))}
          </select>
        </span>

        {metricsUnits[firstMetricType]?.length && (
          <span className="drowdown-btn-div">
            <select
              value={firstMetricUnit}
              onChange={(e) => handleFirstMetricUnitChange(e)}
              className="select-tag-icon"
              style={{ width: 140 }}
            >
              {metricsUnits[firstMetricType]?.map(({ value, label }) => (
                <option
                  key={value}
                  value={Object.keys(metrics).find(
                    (key) => metrics[key] === value,
                  )}
                >
                  {label}
                </option>
              ))}
            </select>
          </span>
        )}
      </Stack>

      <Stack direction="row" alignItems="end" spacing={2} mb={3}>
        <Typography>{t('texts.secondMetric')}:</Typography>
        <span className="drowdown-btn-div">
          <select
            value={secondMetricType}
            onChange={handleSecondMetricChange}
            className="select-tag-icon"
            style={{ width: 140 }}
          >
            {metricsCombinations[firstMetricType]?.map((metric) => (
              <option
                key={metric}
                value={Object.keys(metrics).find(
                  (key) => metrics[key] === metric,
                )}
              >
                {metric}
              </option>
            ))}
          </select>
        </span>
        {metricsUnits[secondMetricType]?.length && (
          <span className="drowdown-btn-div">
            <select
              value={secondMetricUnit}
              onChange={(e) => handleSecondMetricUnitChange(e)}
              className="select-tag-icon"
              style={{ width: 140 }}
            >
              {metricsUnits[secondMetricType]?.map(({ label, value }) => (
                <option
                  key={value}
                  value={Object.keys(metrics).find(
                    (key) => metrics[key] === value,
                  )}
                >
                  {label}
                </option>
              ))}
            </select>
          </span>
        )}
      </Stack>

      <table className="exercise-ins-table-info" width="100%">
        <thead>
          <tr>
            <th width="10%">Set</th>
            <th width="20%">{metrics[firstMetricType]}</th>
            <th width="20%">{metrics[secondMetricType]}</th>
            <th width="20%">Tempo</th>
            <th width="20%">Rest period</th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          {exercise?.metadata?.map((data, index) => {
            return (
              <ExpandedInputs
                key={index + 'expand'}
                index={index}
                exerciseMetadata={exercise?.metadata}
                data={data}
                handleUpdate={handleInputsUpdate}
                handleRemoveSet={handleRemoveSet}
              />
            );
          })}
        </tbody>
      </table>
      <Typography
        sx={{ ml: 2, mt: 1, cursor: 'pointer' }}
        onClick={handleAddNewSet}
      >
        + {t('buttons.newSet')}
      </Typography>
    </div>
  );
}
