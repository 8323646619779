import './ClientProfile.css';
import { Stack, Typography } from '@mui/material';
import Chat from '../chat/Chat';
import FormInput from '../../components/shared/FormInput';
import useClientProfile from './useClientProfile';
import EmailInput from '../../components/shared/EmailInput';
import FormSelectInput from '../../components/shared/FormSelectInput';
import MuiButton from '../../components/shared/MuiButton';
import { Save } from '@material-ui/icons';
import MuiModal from '../../components/shared/MuiModal';
import { validateName } from '../../utils';
import { useTranslation } from 'react-i18next';

const FormSection = ({ children }) => (
  <Stack direction="row" alignItems="start" sx={{ width: '100%' }} spacing={4}>
    {children}
  </Stack>
);

const ClientProfile = () => {
  const {
    chatModalOpened,
    setChatModalOpened,
    confirmationModalOpened,
    setConfirmationModalOpened,
    updateUserProfile,
    removeClient,
    genderOptions,
    unitsOptions,
    isLoading,
    register,
    control,
    errors,
    weightUnits,
    heightUnits,
    userId,
  } = useClientProfile();
  const { t } = useTranslation();

  return (
    <div className="boxes client-page">
      <Stack
        justifyContent="center"
        spacing={3}
        sx={{
          width: '100%',
          maxWidth: 1000,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <FormSection>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.name')}
            </Typography>

            <FormInput
              register={register}
              name="Name"
              placeholder={t('placeholders.enterClientName')}
              error={errors?.name}
              options={{
                required: t('validations.nameIsRequired'),
                validate: validateName,
              }}
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.email')}
            </Typography>

            <EmailInput
              name="email"
              register={register}
              error={errors.email}
              noLabel
              disabled
            />
          </Stack>
        </FormSection>

        <FormSection>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.dateOfBirth')}
            </Typography>

            <FormInput
              name="DOB"
              register={register}
              type="date"
              error={errors.DOB}
              options={{ required: t('validations.dateOfBirthRequired') }}
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.gender')}
            </Typography>

            <FormSelectInput
              name="gender"
              control={control}
              selectOptions={genderOptions}
            />
          </Stack>
        </FormSection>

        <FormSection>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.weightUnit')}
            </Typography>

            <FormSelectInput
              name="weightUnit"
              control={control}
              selectOptions={weightUnits}
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.weight')}
            </Typography>

            <FormInput
              register={register}
              name="weight"
              type="number"
              placeholder="Enter client's weight"
              error={errors?.weight}
              options={{ required: t('validations.weightRequired') }}
            />
          </Stack>
        </FormSection>

        <FormSection>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.heightUnit')}
            </Typography>

            <FormSelectInput
              name="heightUnit"
              control={control}
              selectOptions={heightUnits}
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.height')}
            </Typography>

            <FormInput
              register={register}
              name="height"
              type="number"
              placeholder="Enter client's height"
              error={errors?.height}
              options={{ required: t('validations.heightRequired') }}
            />
          </Stack>
        </FormSection>

        <FormSection>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.goals')}
            </Typography>

            <textarea
              {...register('goals')}
              style={{ color: 'white !important' }}
              placeholder="Enter the client's goals"
              className="form-control"
              rows="4"
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="end"
            justifyContent="end"
            spacing={2}
            sx={{ flex: 1, alignSelf: 'end' }}
          >
            <MuiButton
              icon={<Save />}
              danger
              transparent
              onClick={() => setConfirmationModalOpened(true)}
              isLoading={isLoading}
              sx={{ width: 180 }}
            >
              {t('buttons.deleteClient')}
            </MuiButton>
            <MuiButton
              icon={<Save />}
              onClick={updateUserProfile}
              isLoading={isLoading}
              sx={{ width: 150 }}
            >
              {t('buttons.save')}
            </MuiButton>
          </Stack>
        </FormSection>
      </Stack>

      <Chat
        open={!!chatModalOpened}
        closeTab={() => setChatModalOpened(false)}
        clientId={userId}
      />
      <MuiModal
        title={t('modals.titles.deleteClient')}
        description={t('modals.phrases.areYouSureDeleteUser')}
        open={confirmationModalOpened}
        onClose={() => setConfirmationModalOpened(false)}
        onSubmit={removeClient}
        submitBtnText={t('buttons.confirm')}
        closeBtnText={t('buttons.cancel')}
      />
    </div>
  );
};
export default ClientProfile;
