import { Stack } from '@mui/material';

const Logo = ({ style }) => {
  return (
    <Stack className="login-logo" style={style} px={2}>
      <img
        alt="logo"
        src="https://performex-images.fra1.digitaloceanspaces.com/full-logo.png"
      />
    </Stack>
  );
};

export default Logo;
