import {
  getSessionById,
  getSessionByDate,
  orderUpExcercise,
  orderDownExcercise,
} from '../../Service/Api';
import {
  HIT_SESSION_REQUEST,
  SET_SESSION_DATA,
  FAILED_SESSION_DATA,
  SET_EMPTY_STATE,
  SET_ORDER_OF_EXERCISES,
  RESET_DATA,
} from '../constants';
const CREATE_SESSION = 'CREATE_SESSION';
const GET_SESSION = 'GET_SESSION';
export const createSession = (body) => {
  return {
    type: CREATE_SESSION,
    payload: { body },
  };
};

export const setSeesionData = (data) => {
  return {
    type: GET_SESSION,
    payload: {
      data: data,
    },
  };
};

export const setClientSessionData = (data) => {
  return {
    type: 'SET_CLIENT_SESSION',
    payload: {
      data: data,
    },
  };
};

export const getSessionAction = (id) => async (dispatch) => {
  dispatch({
    type: HIT_SESSION_REQUEST,
  });

  try {
    const token = localStorage.getItem('token');
    const response = await getSessionById(token, id);

    if (response && response.statusCode === 200) {
      const exercisesMetadata = response.data.exercisesMetadata;
      const exercisesWithMetadata = response.data.exercises.map((superset) =>
        superset.map((exercise) => ({
          ...exercise,
          metadata: exercisesMetadata.find(
            ({ exerciseId }) => exerciseId === exercise._id,
          )?.metadata,
        })),
      );

      dispatch({
        type: SET_SESSION_DATA,
        payload: {
          data: { ...response.data, exercises: exercisesWithMetadata },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: FAILED_SESSION_DATA,
      payload: {
        error: err.message,
      },
    });
  }
};

export const sessionByDateAction = (date) => async (dispatch) => {
  dispatch({ type: HIT_SESSION_REQUEST });

  try {
    const token = localStorage.getItem('token');
    const { _id } = JSON.parse(localStorage.getItem('auth'));

    const response = await getSessionByDate(_id, date, token);
    if (response && response.statusCode === 200) {
      dispatch({
        type: SET_SESSION_DATA,
        payload: {
          data: response.data,
        },
      });
    } else {
      dispatch({
        type: SET_SESSION_DATA,
        payload: {
          data: null,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: FAILED_SESSION_DATA,
      payload: {
        error: err.message,
      },
    });
  }
};

export const setSessionDataAction = (data) => (dispatch) => {
  dispatch({ type: HIT_SESSION_REQUEST });
  dispatch({
    type: SET_SESSION_DATA,
    payload: {
      data: data,
    },
  });
};

export const setEmptyStateAction = () => (dispatch) => {
  dispatch({ type: SET_EMPTY_STATE });
};

export const changeOrderOfExercises =
  (sessionID, exerciseId, direction) => async (dispatch) => {
    dispatch({ type: HIT_SESSION_REQUEST });
    try {
      const token = localStorage.getItem('token');
      let response = null;
      if (direction === 'UP') {
        response = await orderUpExcercise(token, sessionID, exerciseId);
      } else {
        response = await orderDownExcercise(token, sessionID, exerciseId);
      }
      if (response && response.statusCode === 200) {
        dispatch({
          type: SET_ORDER_OF_EXERCISES,
          payload: {
            data: response.data,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: FAILED_SESSION_DATA,
        payload: { error: err },
      });
    }
  };

export const reset_data_action = () => (dispatch) => {
  dispatch({
    type: RESET_DATA,
  });
};
