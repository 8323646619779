import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { customFetch } from '../../../Service/Api';
import { useTranslation } from 'react-i18next';

const useOTPVerification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  var token = localStorage.getItem('token');

  const onSubmit = handleSubmit(async (data, event) => {
    event.preventDefault();
    setIsLoading(true);

    customFetch(`${process.env.REACT_APP_API_URL}/api/users/verifyOtp`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    })
      .then((res) => res.json())
      .then((resdata) => {
        if (resdata.statusCode === 200) {
          toast.success(t('success.codeVerified'));
          navigate('/changepassword');
        } else {
          toast.error(t('errors.incorrectCode'));
        }
      })
      .catch(() => {
        toast.error(t('errors.somethingWentWrong'));
      })
      .finally(() => setIsLoading(false));
  });

  return { register, onSubmit, isLoading, errors };
};

export default useOTPVerification;
