import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import moment from 'moment';
import { AuthContext } from '../App';

const HasSubscription = () => {
  const { auth } = React.useContext(AuthContext);
  const location = useLocation();

  console.log('auth', auth);

  const isTrialPeriod = () => {
    const today = moment().utc();
    const trialEnd = moment(auth.trialPeriod).utc();
    const diff = trialEnd.diff(today, 'days');
    if (diff >= 0) {
      return true;
    }
    return false;
  };

  const handleRedirect = () => {
    if (
      auth.subscriptionStatus === 'CONFIRMING' &&
      location.pathname !== '/payment/confirmation'
    ) {
      return <Navigate to="/payment/confirmation" state={{ from: location }} />;
    } else if (
      auth.subscriptionStatus === 'CONFIRMING' &&
      location.pathname === '/payment/confirmation'
    ) {
      return <Outlet />;
    } else if (
      auth.subscriptionStatus !== 'CONFIRMING' &&
      location.pathname === '/payment/confirmation'
    ) {
      return <Navigate to="/client" state={{ from: location }} />;
    } else if (
      auth.subscriptionStatus === 'ACTIVE' ||
      isTrialPeriod() ||
      auth.subscriptionStatus === 'CANCEL_REQUESTED'
    ) {
      return <Outlet />;
    } else if (
      auth.subscriptionStatus === 'INCOMPLETE' &&
      location.pathname !== '/subscription'
    ) {
      return <Navigate to="/subscription" state={{ from: location }} />;
    } else if (auth.subscriptionStatus === 'INCOMPLETE') {
      return <Outlet />;
    } else if (location.pathname !== '/subscription') {
      return <Navigate to="/subscription" state={{ from: location }} />;
    } else if (
      location.pathname !== '/plans' &&
      auth.subscriptionStatus === 'TRIAL_PERIOD'
    ) {
      return <Navigate to="/plans" state={{ from: location }} />;
    } else {
      return <Outlet />;
    }
  };

  return handleRedirect();
};
export default HasSubscription;
