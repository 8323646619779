import { Box } from '@mui/material';
import FormFileInput from '../../../components/shared/FormFileInput';
import MuiModal from '../../../components/shared/MuiModal';
import useTeamPhotoModal from './useTeamPhotoModal';
import { useTranslation } from 'react-i18next';

const TeamPhotoModal = ({ onClose, open, teamId }) => {
  const {t} = useTranslation();
  const { register, errors, onSubmit } = useTeamPhotoModal(teamId, onClose);

  return (
    <MuiModal
      title={t('modals.titles.changeTeamPhoto')}
      submitBtnText={t('buttons.change')}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Box sx={{ py: 2 }}>
        <FormFileInput
          name="avatar"
          register={register}
          error={errors.avatar}
          options={{ required: t('validations.photoRequired') }}
        />
      </Box>
    </MuiModal>
  );
};

export default TeamPhotoModal;
