import React from 'react';
import { NavLink } from 'react-router-dom';

const Program = () => {
  return (
    <div className="client-page">
      <div className="client-nav">
        <div>
          <ul className="nav">
            <li className="nav-item">
              <NavLink to="/library" activeclassname="is-active">
                <span>Exercises</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/workout" activeclassname="is-active">
                <span>Workouts</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/program" activeclassname="is-active">
                <span>Programs</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div>
          <button>Create Program</button>
        </div>
      </div>
      <div className="search">
        <div className="search-bar">
          <div className="form-group has-search">
            <span className="form-control-feedback">
              <i className="bi bi-search"></i>
            </span>
            <input type="text" className="form-control" placeholder="Search" />
          </div>
        </div>
      </div>

      <div className="homepage-boxes extra-clr">
        <div className="boxes">
          <div className="box-avtar">
            <img src="assets/logo.png" alt="" />
          </div>
          <div className="box-dataupper">
            <h3>
              <span>4-week Hypertophy</span>
            </h3>
          </div>
          <div className="images-chart">
            <button className="ml-3">Assign</button>
            <img src="assets/settings.png" alt="" />
          </div>
        </div>

        <div className="boxes">
          <div className="box-avtar">
            <img src="assets/logo.png" alt="" />
          </div>
          <div className="box-dataupper">
            <h3>
              <span>2-month Body-weigtht</span>
            </h3>
          </div>
          <div className="images-chart">
            <button className="ml-3">Assign</button>
            <img src="assets/settings.png" alt="" />
          </div>
        </div>

        <div className="boxes">
          <div className="box-avtar">
            <img src="assets/logo.png" alt="" />
          </div>
          <div className="box-dataupper">
            <h3>
              <span>Running Endurance Program</span>
            </h3>
          </div>
          <div className="images-chart">
            <button className="ml-3">Assign</button>
            <img src="assets/settings.png" alt="" />
          </div>
        </div>

        <div className="boxes">
          <div className="box-avtar">
            <img src="assets/logo.png" alt="" />
          </div>
          <div className="box-dataupper">
            <h3>
              <span>4-week Transformation</span>
            </h3>
          </div>
          <div className="images-chart">
            <button className="ml-3">Assign</button>
            <img src="assets/settings.png" alt="" />
          </div>
        </div>

        <div className="boxes">
          <div className="box-avtar">
            <img src="assets/logo.png" alt="" />
          </div>
          <div className="box-dataupper">
            <h3>
              <span>Marathon Program</span>
            </h3>
          </div>
          <div className="images-chart">
            <button className="ml-3">Assign</button>
            <img src="assets/settings.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
