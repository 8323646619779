import {
  HIT_PAYMENT_INFO_REQUEST,
  SET_PAYMENT_INFO_REQUEST,
  FAILED_PAYMENT_INFO_REQUEST,
} from '../constants';

const init = {
  data: [],
  error: null,
  loading: false,
};

export const paymentReducer = (state = init, { type, payload }) => {
  switch (type) {
    case HIT_PAYMENT_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_PAYMENT_INFO_REQUEST: {
      return {
        ...state,
        data: payload.data,
        error: null,
        loading: false,
      };
    }
    case FAILED_PAYMENT_INFO_REQUEST: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
