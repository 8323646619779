import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ProgramSetting.css';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setProgram } from '../../redux/actions/Program';
import { Link } from 'react-router-dom';
import { updateProgramAction } from '../../redux/actions/Program';
import { customFetch } from '../../Service/Api';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

var ProgramGetData;
const ProgramSetting = () => {
  let navigate = useNavigate();
  const [ProgramName, setProgramName] = useState();
  const [NumberofWeeks, setNumberofWeeks] = useState();
  const [description, setDescription] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const { programId } = useParams();
  const [programData, setValue] = useState({
    ProgramName: '',
    NumberofWeeks: '',
    description: '',
  });

  // * redux function
  const dispatch = useDispatch();
  const program = useSelector((state) => state.program);

  const handleDeleteOpen = (data) => {
    setOpenDelete(true);
  };

  const handleDelete = () => {
    setOpenDelete(false);
  };

  //Current Program get by Id
  const GetProgramId = () => {
    let token = localStorage.getItem('token');
    customFetch(
      `${process.env.REACT_APP_API_URL}/api/programs/current/${programId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      },
    )
      .then((res) => res.json())
      .then((resdata) => {
        ProgramGetData = resdata.data;
        setValue(ProgramGetData);
        setProgramName(ProgramGetData.ProgramName);
        setNumberofWeeks(ProgramGetData.NumberofWeeks);
        setDescription(ProgramGetData.description);
      });
  };

  useEffect(() => {
    dispatch(setProgram(programId));
  }, []);

  useEffect(() => {
    setValue({
      ...programData,
      ProgramName: program.data?.ProgramName,
      NumberofWeeks: program.data?.NumberofWeeks,
      description: program.data?.description,
    });
  }, [program.data]);
  // end

  // Program update API
  const ProgramUpdate = async (e) => {
    const body = {
      ...programData,
    };
    dispatch(updateProgramAction(programId, body));
  };
  // end

  // Program Delete Api
  const DeleteProgram = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem('token');
    try {
      const response = await customFetch(
        `${process.env.REACT_APP_API_URL}/api/programs/delete/${programId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        },
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success(data.data);
        setOpenDelete(false);
        navigate('/librarypage/programmm');
      }
    } catch (err) {
      toast.error(err.data);
    }
  };
  // end

  return (
    <div className="client-page programsetting-page-info">
      <div className="title-heading">
        <h2>Running Program</h2>
      </div>
      <div className="client-nav">
        <div>
          <ul className="nav">
            <li className="nav-item">
              {
                //** */
              }
              <Link to={`/librarypage/program/calendar/${program.data?._id}`}>
                <span>Calendar</span>
              </Link>
            </li>
            <li className="nav-item active">
              <a>
                <span>Settings</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="boxes client-section-info">
        <table align="center" cellPadding="10" width="100%">
          <tbody>
            <tr>
              <td className="font-size-22">Program Name</td>
              <td>
                <input
                  type="text"
                  name="running-program"
                  placeholder="Running Program"
                  className="form-control"
                  value={programData?.ProgramName}
                  onChange={(e) => {
                    setValue((obj) => {
                      return {
                        ...obj,
                        ProgramName: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="font-size-22">Program Length(weeks)</td>
              <td>
                <input
                  type="text"
                  name="program-length"
                  placeholder="5"
                  className="form-control"
                  value={programData?.NumberofWeeks}
                  onChange={(e) => {
                    setValue((obj) => {
                      return {
                        ...obj,
                        NumberofWeeks: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="font-size-22">Description</td>
              <td>
                <textarea
                  name="Address"
                  placeholder="Four strength sessions a week plus one conditioning session."
                  className="form-control"
                  rows="4"
                  cols="60"
                  value={programData?.description}
                  onChange={(e) => {
                    setValue((obj) => {
                      return {
                        ...obj,
                        description: e.target.value,
                      };
                    });
                  }}
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="empty-space-30"></div>
        <div className="mx-auto d-block">
          <div className="btn">
            <button onClick={ProgramUpdate}>Save</button>
            <a href="#" onClick={handleDeleteOpen}>
              Delete Program
            </a>
          </div>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleDelete}
        aria-labelledby="customized-dialog-title"
        open={openDelete}
      >
        <div className="popup-add-client-info popup-new-exercise-info Pop-del">
          <div className="popup-add-client-content-info ">
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleDelete}
            ></BootstrapDialogTitle>
            <div className="Delpop">
              <p>Do you want to Delete the Selected Exercise..!!</p>
              <div className="del-btn">
                <button type="submit" onClick={DeleteProgram}>
                  Yes
                </button>
                <button type="submit" onClick={handleDelete}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default ProgramSetting;
