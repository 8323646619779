import {
  MONTHLY_PLAN_REQUEST,
  GET_MONTHLY_PLAN,
  GET_MONTHLY_PLAN_FAILED,
  YEARL_PLAN_REQUEST,
  GET_YEARLY_PLAN,
  FAILED_YEARLY_PLAN,
} from '../constants';

const initialVal = {
  data: [],
  error: null,
  loading: false,
};

export const monthlyPlansReducer = (state = initialVal, { type, payload }) => {
  switch (type) {
    case MONTHLY_PLAN_REQUEST: {
      return {
        ...state,
        data: [],
        loading: true,
      };
    }
    case GET_MONTHLY_PLAN: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }
    case GET_MONTHLY_PLAN_FAILED: {
      return {
        ...state,
        data: [],
        loading: false,
        error: payload.error,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const yearlyPlanReducers = (state = initialVal, { type, payload }) => {
  switch (type) {
    case YEARL_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_YEARLY_PLAN: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }
    case FAILED_YEARLY_PLAN: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default: {
      return { ...state };
    }
  }
};
