export const languages = {
  en: { nativeName: 'English' },
  se: { nativeName: 'Swedish' },
};

export const stringify = (obj) => JSON.stringify(obj);

export const setStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageParsed = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const queryString = (queryObj) => {
  if (queryObj instanceof Object) {
    let query = '?';
    const raw = [...Object.entries(queryObj)];
    raw.forEach(([key, val], idx) => {
      query += `${key}=${val}${raw.length - 1 === idx ? '' : '&'}`;
    });
    return query;
  } else {
    return new Error('This param is not  valid Object');
  }
};
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const capitalizeFirstLetter = (text) =>
  text?.length ? text[0].toUpperCase() + text.slice(1) : '';

export const validateName = (value) => {
  if (value?.length < 5) return 'Full name must have at least 5 characters.';
  else if (value?.length > 50)
    return 'Full name must not have more than 50 characters';
  else return true;
};

export const validateEmail = (value) =>
  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
