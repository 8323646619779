import FormInput from '../../../components/shared/FormInput';
import { Stack } from '@mui/material';
import MuiButton from '../../../components/shared/MuiButton';
import Logo from '../../../components/shared/Logo';
import useOTPVerification from './useOTPVerification';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

const OTPVerification = () => {
  const { register, onSubmit, errors, isLoading } = useOTPVerification();
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <div className="login-page inner-page-header">
      <div className="containers">
        <Stack className="forms">
          <Logo />
          <form onSubmit={onSubmit}>
            <FormInput
              name="otp"
              label={t('texts.verificationCode')}
              placeholder={t('placeholders.enterVerificationCode')}
              register={register}
              options={{ required: t('validations.codeRequired') }}
              error={errors?.otp}
            />

            <Stack spacing={2} alignItems="center" sx={{ mt: 1 }}>
              <MuiButton
                sx={{ width: 1 }}
                type="submit"
                isLoading={isLoading}
                fullWidth
              >
                {t('buttons.verify')}
              </MuiButton>
              <MuiButton onClick={() => navigate('/forgot')}  sx={{ width: 1 }} transparent fullWidth>
                {t('buttons.back')}
              </MuiButton>
            </Stack>
          </form>
        </Stack>

        <div className="space-40px"></div>
        <div className="space-80px"></div>
      </div>
    </div>
  );
};

export default OTPVerification;
