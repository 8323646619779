import { Stack, Typography } from '@mui/material';
import MuiModal from '../../components/shared/MuiModal';
import MuiButton from '../../components/shared/MuiButton';
import { Download } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { importExercises } from '../../Service/Api';
import { useContext, useState } from 'react';
import { AuthContext } from '../../App';

const ExercisesImport = ({ isOpen, onClose }) => {
  const { auth } = useContext(AuthContext);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const fileReader = new FileReader();

  const handleOnFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeaderText = string.slice(0, string.indexOf('\n'));
    const separator = csvHeaderText.includes(',') ? ',' : ';';

    const csvHeader = csvHeaderText
      .split(separator)
      .map((header) => header.replace('\r', '').replace(' ', ''));

    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i) => {
      const values = i.split(separator);
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index].replace(`\"`, '');
        return object;
      }, {});
      return { ...obj, trainerId: auth._id };
    });

    return array;
  };

  const onImportExercises = () => {
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const exercises = csvFileToArray(csvOutput);
        createExercises(exercises);
      };

      fileReader.readAsText(file);
    } else toast.info('Choose a valid CSV file');
  };

  const handleClose = () => {
    onClose();
    setFile(undefined);
  };

  const createExercises = async (exercises) => {
    try {
      setLoading(true);
      await importExercises(auth.token, exercises);
      toast.success('Exercises successfully imported');
      handleClose();
    } catch (err) {
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const download = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'example.csv');
    a.click();
  };

  const buildExampleCsv = () => {
    const csvRows = [];
    const headers = ['ExerciseName', 'Instructions', 'YoutubeUrl'];
    csvRows.push(headers.join(','));
    const values = ['', '', ''];
    csvRows.push(values);
    return csvRows.join('\n');
  };

  const handleDownload = async () => {
    const csvdata = buildExampleCsv();
    download(csvdata);
  };

  return (
    <MuiModal
      onClose={handleClose}
      open={isOpen}
      isLoading={loading}
      title="How to import multiple exercises"
      onSubmit={onImportExercises}
      submitBtnText="Import"
      closeBtnText="Cancel"
    >
      <Stack spacing={2} mb={1} mt={1}>
        <Typography>1. Download the example CSV</Typography>
        <MuiButton sx={{ mb: 1, ml: 2.5 }} onClick={() => handleDownload()}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Download sx={{ mr: 1 }} /> <Typography>Example CSV</Typography>
          </Stack>
        </MuiButton>
        <Typography>
          2. Populate the downloaded file with the desired exercises
        </Typography>

        <Typography>3. Upload the edited file and press "Import"</Typography>
        <input
          style={{
            marginLeft: 20,
            borderRadius: 5,
            border: '1px solid snow',
          }}
          type="file"
          accept=".csv"
          onChange={handleOnFileChange}
        />
      </Stack>
    </MuiModal>
  );
};

export default ExercisesImport;
