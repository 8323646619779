import {
  AddCircleOutline,
  Create,
  LibraryAdd,
  Save,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import MuiButton from '../../components/shared/MuiButton';
import SelectInput from '../../components/shared/SelectInput';
import { AddOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export function TopMenu({
  isExistentSession,
  sessionSubmit,
  saveSessionToLibrary,
  setExercisesModalVisibility,
  setImportModalVisibility,
  selectedSessionId,
  setSelectedSessionId,
  sessions,
  resetState,
}) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2.5} alignItems="flex-end" width={1}>
      {isExistentSession && selectedSessionId && (
        <Stack direction="row" alignItems="end" spacing={2} width={1}>
          <SelectInput
            sx={{ width: 1 }}
            label={t('texts.session')}
            value={selectedSessionId}
            onChange={(e) => setSelectedSessionId(e.target.value)}
            selectOptions={sessions.map((session) => ({
              value: session._id,
              label: session.title,
            }))}
            hideError={true}
            containerSx={{ mb: 0, flex: 1 }}
          />
          <MuiButton onClick={() => resetState(true)} icon={<AddOutlined />}>
            {t('buttons.createSession')}
          </MuiButton>
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        width={1}
      >
        <MuiButton onClick={saveSessionToLibrary} icon={<LibraryAdd />}>
          {t('buttons.saveSessionToLibrary')}
        </MuiButton>
        <Stack direction="row" spacing={2}>
          <MuiButton
            onClick={() => setImportModalVisibility(true)}
            icon={<AddOutlined />}
          >
            {t('buttons.addSession')}
          </MuiButton>
          <MuiButton
            onClick={() => setExercisesModalVisibility(true)}
            icon={<AddOutlined />}
          >
            {t('buttons.addExercise')}
          </MuiButton>

          {isExistentSession ? (
            <MuiButton onClick={() => sessionSubmit()} icon={<Save />}>
              {t('buttons.save')}
            </MuiButton>
          ) : (
            <MuiButton onClick={() => sessionSubmit()} icon={<Save />}>
              {t('buttons.create')}
            </MuiButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
