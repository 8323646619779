const DEFAULT_METADATA_ITEM = {
  firstMetric: {
    type: 'reps',
    value: 10,
  },
  secondMetric: {
    type: 'weight',
    value: 5,
    unit: 'kg',
  },
  tempo: '3010',
  rest: '01:30',
};

export const DEFAULT_METADATA = [
  DEFAULT_METADATA_ITEM,
  DEFAULT_METADATA_ITEM,
  DEFAULT_METADATA_ITEM,
];
