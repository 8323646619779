import { Box } from '@mui/material';
import FormFileInput from '../../../components/shared/FormFileInput';
import FormInput from '../../../components/shared/FormInput';
import FormSelectInput from '../../../components/shared/FormSelectInput';
import MuiModal from '../../../components/shared/MuiModal';
import useSaveTeamModal from './useSaveTeamModal';
import { useTranslation } from 'react-i18next';

const SaveTeamModal = ({ open, onClose, membersOptions, trainerId, team }) => {
  const { control, onCloseModal, onSubmit, register, errors } =
    useSaveTeamModal(onClose, trainerId, team);
  const isEditModal = !!team;
  const { t } = useTranslation();

  return (
    <MuiModal
      title={
        isEditModal
          ? t('modals.titles.editTeam')
          : t('modals.titles.createNewTeam')
      }
      open={open}
      onClose={onCloseModal}
      onSubmit={onSubmit}
      submitBtnText={isEditModal ? t('buttons.save') : t('buttons.create')}
    >
      <Box sx={{ mt: 3 }}>
        {!isEditModal && (
          <Box sx={{ py: 2 }}>
            <FormFileInput
              name="avatar"
              label={t('texts.teamPhoto')}
              register={register}
              error={errors.avatar}
            />
          </Box>
        )}
        <FormInput
          name="TeamName"
          label={t('texts.teamName')}
          placeholder={t('placeholders.enterTeamName')}
          register={register}
          error={errors.TeamName}
          options={{ required: t('validations.teamNameRequired') }}
        />
        <FormInput
          name="Sport"
          label={t('texts.sport')}
          placeholder={t('placeholders.enterSportName')}
          register={register}
          error={errors.Sport}
          options={{ required: t('validations.sportNameRequired') }}
        />
        <Box sx={{ width: 1 }}>
          <FormSelectInput
            name="members"
            control={control}
            selectOptions={membersOptions}
            label={t('texts.members')}
            placeholder={t('placeholders.selectSomeClients')}
            options={{ required: t('validations.teamMembersRequired') }}
            multiple
          />
        </Box>

        <FormInput
          name="accesscode"
          label={t('texts.accessCode')}
          placeholder={t('placeholders.enterTeamAccessCode')}
          register={register}
          error={errors.accesscode}
          options={{ required: t('validations.teamAccessCodeRequired') }}
        />
        <Box sx={{ my: 2 }}>
          <ul className="ul-tag-info" style={{ fontSize: 15 }}>
            <li>{t('phrases.teamModal.phrase1')}</li>
            <li>{t('phrases.teamModal.phrase2')}</li>
            <li>{t('phrases.teamModal.phrase3')}</li>
            <li>{t('phrases.teamModal.phrase4')}</li>
            <li>{t('phrases.teamModal.phrase5')}</li>
          </ul>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default SaveTeamModal;
