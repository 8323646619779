import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  cancelSubscription,
  getAllPrices,
  getUserById,
  getActiveAndPendingClients,
} from '../../../Service/Api';
import LoaderContext from '../../../context/Loader';
import { AuthContext } from '../../../App';
import moment from 'moment';

const useSubscription = () => {
  const navigate = useNavigate();

  const [prices, setPrices] = useState([]);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [
    cancelSubscriptionConfirmationModal,
    setCancelSubscriptionConfirmationModal,
  ] = useState(false);
  const [clients, setClients] = useState([]);

  const loader = useContext(LoaderContext);
  const abortControler = new AbortController();
  const signal = abortControler.signal;
  const { auth } = useContext(AuthContext);
  const [trialPeriodData, setTrialPeriodData] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);

  const getCurrentPrice = () => {
    const findIndex = prices.findIndex((price) => price.id === auth?.priceId);
    if (findIndex !== -1) {
      setCurrentPrice(prices[findIndex]);
    }
  };

  const getTrialPeriodData = () => {
    setTrialPeriodData({
      isTrialPeriod:
        auth.trialPeriod && moment(new Date()).isBefore(auth?.trialPeriod),
      trialPeriod: auth.trialPeriod,
    });
  };

  const getNumberOfActiveClients = () => {
    return clients.length;
  };

  const fetchPrices = async () => {
    try {
      loader.setLoading(true);

      const token = localStorage.getItem('token');
      const response = await getAllPrices(token, signal);

      if (response && response.statusCode === 200) {
        const data = response.data;
        setPrices(data);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        err?.error ?? err?.message ?? "Something wen't wrong. Try again later.",
      );
    } finally {
      loader.setLoading(false);
    }
  };

  const fetchActiveClients = async () => {
    try {
      loader.setLoading(true);
      const response = await getActiveAndPendingClients(auth._id, auth.token);

      if (response && response.statusCode === 200) {
        setClients(response.data);
      } else {
        throw response;
      }
    } catch (err) {
      // toast.error(
      //   err?.error ?? err?.message ?? "Something wen't wrong. Try again later.",
      // );
    } finally {
      loader.setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
    fetchActiveClients();
    getTrialPeriodData();
    return () => {
      abortControler.abort();
    };
  }, []);

  useEffect(() => {
    getCurrentPrice();
  }, [prices]);

  const cancelCurrentSubscription = async () => {
    try {
      setIsCancellingSubscription(true);

      const token = localStorage.getItem('token');
      const { _id } = JSON.parse(localStorage.getItem('auth'));
      const response = await cancelSubscription(_id, token);

      if (response && response.statusCode === 200) {
        const user = await getUserById(_id, token);

        localStorage.clear();
        localStorage.setItem('token', user.data.token);
        localStorage.setItem('auth', JSON.stringify(user.data));

        toast.success('Your subscription was successfully canceled.');
        navigate('/plans');
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        err?.error ?? err?.message ?? "Something wen't wrong. Try again later.",
      );
    } finally {
      setIsCancellingSubscription(false);
      setCancelSubscriptionConfirmationModal(false);
    }
  };

  return {
    prices,
    currentPrice,
    cancelCurrentSubscription,
    isCancellingSubscription,
    cancelSubscriptionModal,
    setCancelSubscriptionModal,
    cancelSubscriptionConfirmationModal,
    setCancelSubscriptionConfirmationModal,
    getNumberOfActiveClients,
    trialPeriodData,
    subscriptionStatus: auth.subscriptionStatus,
  };
};

export default useSubscription;
