import {
  HIT_CARD__REQUEST,
  SET_CARD_REQUEST,
  FAILED_CARD_REQUEST,
  UPDATE_EXPIRY_REQUEST,
  SET_CARD_DEFAULT
} from '../constants';

const initialVal = {
  items: [],
  loading: false,
  error: null,
};
export const bankCardReducer = (state = initialVal, { payload, type }) => {
  switch (type) {
    case HIT_CARD__REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CARD_REQUEST: {
      return {
        ...state,
        loading: false,
        items: payload.items,
        error: null,
      };
    }
    case SET_CARD_DEFAULT: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case FAILED_CARD_REQUEST: {
      return {
        ...state,
        loading: false,
        //    items: payload.items,
        error: payload.error,
      };
    }
    case UPDATE_EXPIRY_REQUEST: {
      return {
        ...state,
        items: state.items.map((item) =>
          item._id === payload.data._id
            ? {
                ...item,
                exp_month: payload.data.exp_month,
                exp_year: payload.data.exp_year,
              }
            : item,
        ),
        loading: false,
        error: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};
