import {
  HIT_CARD__REQUEST,
  SET_CARD_REQUEST,
  FAILED_CARD_REQUEST,
  UPDATE_EXPIRY_REQUEST,
} from '../constants';
import {
  bankCardByUser,
  setDefaultCard,
  updateBankCard,
} from '../../Service/Api';
import { toast } from 'react-toastify';
import i18n from '../../i18n.js';

export const set_bank_card = () => async (dispatch) => {
  dispatch({
    type: HIT_CARD__REQUEST,
  });
  try {
    const response = await bankCardByUser();

    if (response && response.statusCode === 200) {
      dispatch({
        type: SET_CARD_REQUEST,
        payload: {
          items: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    const error = err?.error ?? err?.message ?? 'Unknown error occurred.';

    dispatch({
      type: FAILED_CARD_REQUEST,
      payload: {
        error: error,
      },
    });
  }
};

export const set_default_card = (body) => async (dispatch) => {
  dispatch({
    type: HIT_CARD__REQUEST,
  });
  try {
    const response = await setDefaultCard(body);

    if (response && response.statusCode === 200) {
      toast.success(response?.message || i18n.t('success.cardSetDefault'));
      dispatch(set_bank_card());
    } else {
      throw response;
    }
  } catch (err) {
    const error = err?.error ?? err?.message ?? i18n.t('errors.somethingWentWrong');

    dispatch({
      type: FAILED_CARD_REQUEST,
      payload: {
        error: error,
      },
    });
  }
};

export const updateExpiryOfCard =
  (id, body, onCallback) => async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      const response = await updateBankCard(id, body, token);

      if (response && response.statusCode === 200) {
        toast.success(response?.message ?? i18n.t('success.cardUpdated'));
        dispatch({
          type: UPDATE_EXPIRY_REQUEST,
          payload: {
            data: response.data,
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      const error =
        err?.error ||
        err?.message ||
        i18n.t('errors.somethingWentWrong')
      toast.error(error);
      dispatch({
        type: FAILED_CARD_REQUEST,
        payload: {
          error: error,
        },
      });
    } finally {
      onCallback();
    }
  };
