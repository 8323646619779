import {
  AutoStories,
  BarChart,
  Groups,
  Insights,
  LibraryBooks,
  People,
  Person,
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { Trans, useTranslation } from 'react-i18next';
import Logo from '../shared/Logo';

export const SIDEBAR_WIDTH = '280px';

const SideBar = () => {
  const { t } = useTranslation();

  return (
    <div className="sidebar-componnet">
      <Logo
        style={{
          marginBottom: 7,
          paddingTop: 20,
          marginTop: 0,
          paddingRight: 30,
          paddingLeft: 30,
          paddingBottom: 20,
          height: '100%',
          boxShadow: '0px 2px rgb(0 0 0 / 25%)',
        }}
      />
      <div className="links" style={{ paddingTop: 0 }}>
        <ul className="nav flex-column">
          <li className="nav-item" style={{ marginTop: 0 }}>
            <NavLink
              to="/client"
              activeclassname="is-active"
              style={{ marginTop: 0 }}
            >
              {' '}
              <People /> {t('sidebar.clients')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/team" activeclassname="is-active">
              {' '}
              <Groups />
              {t('sidebar.teams')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/librarypage" activeclassname="is-active">
              {' '}
              <AutoStories />
              {t('sidebar.library')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/statistics" activeclassname="is-active">
              {' '}
              <Insights />
              {t('sidebar.statistics')}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default SideBar;
