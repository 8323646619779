import { Box, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import EmailInput from '../../components/shared/EmailInput';
import FormInput from '../../components/shared/FormInput';
import './TrainerProfile.css';
import useTrainerProfile from './useTrainerProfile';
import clsx from 'clsx';
import MuiButton from '../../components/shared/MuiButton';
import { languages, validateName } from '../../utils';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../components/shared/SelectInput';

const hasActiveSubscription = (subscriptionStatus) => {
  return subscriptionStatus === 'ACTIVE' || subscriptionStatus === 'INCOMPLETE';
};

export const TrainerProfileNavMenu = ({ subscriptionStatus, page }) => {
  const { t } = useTranslation();
  return (
    <div className="client-nav">
      <div>
        <ul className="nav">
          <li className={clsx('nav-item', page === 'settings' && 'active')}>
            <NavLink to="/settings" activeclassname="is-active">
              <span>{t('texts.profileInformation')}</span>
            </NavLink>
          </li>
          <li className={clsx('nav-item', page === 'subscription' && 'active')}>
            <NavLink
              to={
                hasActiveSubscription(subscriptionStatus)
                  ? '/subscription'
                  : '/plans'
              }
              activeclassname="is-active"
            >
              <span>{t('texts.subscription')}</span>
            </NavLink>
          </li>
          {subscriptionStatus !== 'TRIAL_PERIOD' && (
            <li
              className={clsx('nav-item', page === 'paymentinfo' && 'active')}
            >
              <NavLink to="/paymentinfo" activeclassname="is-active">
                <span>{t('texts.paymentInfo')}</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const TrainerProfile = () => {
  const { i18n, t } = useTranslation();
  const { register, onSubmit, logOut, errors, subscriptionStatus } =
    useTrainerProfile();

  return (
    <div className="client-page">
      <TrainerProfileNavMenu
        page="settings"
        subscriptionStatus={subscriptionStatus}
      />

      <Stack className="boxes" margin={0} mt={1.5}>
        <Stack width={1} maxWidth={700} spacing={2}>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.fullName')}
            </Typography>

            <FormInput
              register={register}
              name="Name"
              options={{
                required: t('validations.fullNameRequired'),
                validate: validateName,
              }}
              error={errors?.Name}
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.emailAddress')}
            </Typography>
            <EmailInput
              register={register}
              error={errors?.email}
              noLabel
              disabled
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ color: 'primary.main' }}>
              {t('texts.organizationName')}
            </Typography>
            <FormInput
              register={register}
              name="organizationName"
              error={errors?.organizationName}
              placeholder={t('placeholders.enterOrganizationName')}
            />
          </Stack>

          <Stack direction="row" spacing={1} alignSelf="end" pt={3}>
            <MuiButton
              onClick={logOut}
              style={{ width: 150 }}
              transparent
              danger
            >
              {t('buttons.logout')}
            </MuiButton>
            <MuiButton onClick={onSubmit} type="submit" style={{ width: 150 }}>
              {t('buttons.save')}
            </MuiButton>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default TrainerProfile;
