import {
  HIT_ASSIGNCLIENTSESSIONS_REQUEST,
  GET_ASSIGNCLIENTSESSIONS_DATA,
  FAILED_ASSIGNCLIENTSESSIONS_DATA,
  UPDATE_ASSIGNCLIENTSESSIONS_STATUS,
  SET_SIMPLE_SESSION_DATA,
  RESET_SESSION_DATA,
  INSERT_EXERCISES_IN_CLIENT_SESSION,
  SET_ALL_CLIENT_SESSION,
  FAILED_ALL_CLIENT_SESSION_REQUEST,
  UPDATE_ASSIGNED_SESSION,
} from '../constants';

const init = {
  data: null,
  error: null,
  loading: false,
  isSimpleSession: null,
  allSession: [],
  allSessionError: null,
};
export const ClientAssignedSession = (state = init, { type, payload }) => {
  switch (type) {
    case HIT_ASSIGNCLIENTSESSIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ASSIGNCLIENTSESSIONS_DATA: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
        isSimpleSession: null,
      };
    }
    case FAILED_ASSIGNCLIENTSESSIONS_DATA: {
      return {
        ...state,
        error: payload.error,
        loading: false,
        isSimpleSession: null,
      };
    }
    case SET_SIMPLE_SESSION_DATA: {
      return {
        ...state,
        loading: false,
        isSimpleSession: true,
        data: {
          sessionId: payload.data || {},
        },
      };
    }
    case RESET_SESSION_DATA: {
      return init;
    }
    case SET_ALL_CLIENT_SESSION: {
      return {
        ...state,
        allSession: payload.data,
        allSessionError: null,
        loading: false,
      };
    }
    case UPDATE_ASSIGNED_SESSION: {
      return {
        ...state,
        data: state.data.map((session) =>
          session._id === payload._id ? payload : session,
        ),
        loading: false,
      };
    }
    case FAILED_ALL_CLIENT_SESSION_REQUEST: {
      return {
        ...state,
        allSessionError: payload.error,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
