import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './LibraryPage.css';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import RenderIf from '../../customComponents/RenderIf';
import Loader from '../loader/Loader';
import { useDebounce } from '../../hooks/useDebounce';
import { Box, CircularProgress, Stack } from '@mui/material';
import MuiModal from '../../components/shared/MuiModal';
import SaveExercise from '../../components/SaveExercise/SaveExercise';
import { customFetch } from '../../Service/Api';
import { AuthContext } from '../../App';
import MuiButton from '../../components/shared/MuiButton';
import { AddOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ExercisesImport from './ExercisesImport';
import { Search, Upload } from '@mui/icons-material';
import FormInput from '../../components/shared/FormInput';
import { useForm } from 'react-hook-form';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const LibraryPage = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [exerciseModalOpened, setExerciseModalOpened] = useState(false);
  const [exercisesImportModalOpened, setExercisesImportModalOpened] =
    useState(false);
  const [userExercises, setUserExercises] = useState([]);
  const [filteredUserExercises, setFilteredUserExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [page, setPage] = useState(0);
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  const { register, watch } = useForm({ defaultValues: { search: '' } });
  const search = watch('search');

  const debouncedSearchTerm = useDebounce(search, 500);

  const updateExercises = (exercise, isNew) => {
    if (isNew) setFilteredUserExercises([...filteredUserExercises, exercise]);
    else
      setFilteredUserExercises(
        filteredUserExercises.map((ex) =>
          ex._id === exercise._id ? exercise : ex,
        ),
      );
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (page > 0 && page <= lastPage) {
      handleNextPage();
    }
  }, [page]);

  useEffect(() => {
    handleSearchUpdate();
  }, [debouncedSearchTerm]);

  const loadInitialData = async () => {
    setLoading(true);
    const response = await getExercises(page, search);

    setUserExercises(response);
    setFilteredUserExercises(response);
    setLoading(false);
  };

  const handleSearchUpdate = async () => {
    setLoading(true);
    if (search.trim()) {
      const response = await getExercises(0, search);
      setFilteredUserExercises(response);
      scrollTo(response[0]?._id);
    } else {
      setFilteredUserExercises([...userExercises]);
      scrollTo([...userExercises][0]?._id);
    }
    setPage(0);
    setLoading(false);
  };

  const scrollTo = (id) => {
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView(true);
    }
  };

  const getExercises = (page, search) => {
    const trainerId = auth._id;
    const query = {
      name: search || '',
      page,
    };
    let url = `${process.env.REACT_APP_API_URL}/api/exercises/getbyuser/${trainerId}?page=${query.page}`;
    if (query.name) {
      url += `&name=${query.name}`;
    }
    return customFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.statusCode === 200) {
          setLastPage(response.data.lastPage);
          return response.data.exercises;
        }
      });
  };

  const handleNextPage = async () => {
    setLoadingNextPage(true);
    const response = await getExercises(page, search);
    if (!search.trim()) {
      setUserExercises([...userExercises, ...response]);
      setFilteredUserExercises([...userExercises, ...response]);
    } else {
      setFilteredUserExercises([...filteredUserExercises, ...response]);
    }
    setLoadingNextPage(false);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      setPage((p) => p + 1);
    }
  };

  const handleDeleteOpen = (data) => {
    setOpenDelete(true);
    setSelectedExercise(data);
  };

  const onCloseDeleteModal = () => {
    setOpenDelete(false);
    setSelectedExercise(undefined);
  };

  const deleteExercise = () => {
    setLoading(true);
    customFetch(
      `${process.env.REACT_APP_API_URL}/api/exercises/delete/${selectedExercise?._id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': auth.token,
        },
      },
    )
      .then((res) => res.json())
      .then(async (resdata) => {
        if (resdata.statusCode === 200) {
          toast.success(resdata.data);
          const response = await getExercises();
          setFilteredUserExercises(response);
          setOpenDelete(false);
        } else {
          toast.error(resdata.error);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <RenderIf isTrue={loading}>
        <Loader />
      </RenderIf>
      <div className="client-page">
        <div className="client-nav">
          <div>
            <ul className="nav">
              <li className="nav-item active">
                <NavLink to="/librarypage" className="exercise">
                  <span>{t('texts.exercises')}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/librarypage/sessions">
                  <span>{t('texts.sessions')}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <Stack
          className="search"
          direction="row"
          justifyContent="space-between"
        >
          <Box sx={{ width: 500, my: 1.5, mr: 2 }}>
            <FormInput
              name="search"
              register={register}
              placeholder={t('placeholders.searchByExerciseName')}
              sx={{ width: 1, mb: -3 }}
              startAdornment={
                <Search sx={{ color: 'secondary.main', ml: 2 }} />
              }
            />
          </Box>
          <Stack direction="row" spacing={2} mt={1.5}>
            <MuiButton
              onClick={() => setExerciseModalOpened(true)}
              icon={<AddOutlined />}
            >
              {t('buttons.addExercise')}
            </MuiButton>
            <MuiButton
              onClick={() => setExercisesImportModalOpened(true)}
              icon={<Upload />}
            >
              {t('buttons.importExercises')} (CSV)
            </MuiButton>
          </Stack>
        </Stack>

        <div
          onScroll={handleScroll}
          style={{ overflow: 'auto', height: window.innerHeight - 300 }}
        >
          {filteredUserExercises.map((exercise) => {
            return (
              <div
                id={exercise._id}
                key={exercise._id}
                className="boxes"
                style={{
                  marginLeft: 0,
                  padding: 25,
                  alignItems: 'flex-start',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (
                    exercise.createdBy !== 'admin' &&
                    exercise.trainerId === auth._id
                  ) {
                    setExerciseModalOpened(true);
                    setSelectedExercise(exercise);
                  }
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'flex-start',
                    paddingLeft: 30,
                    cursor:
                      exercise.createdBy === 'admin'
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                >
                  <Stack
                    sx={{
                      bgcolor: 'white',
                      minWidth: 106,
                      borderRadius: 10,
                    }}
                  >
                    <img
                      style={{ height: 80, borderRadius: 10 }}
                      className="img-fluid"
                      src={
                        exercise?.YoutubeUrl && exercise?.avatar
                          ? exercise?.avatar
                          : 'https://performex-images.s3.us-west-2.amazonaws.com/exercise.jpeg'
                      }
                      alt=""
                    />
                  </Stack>
                  <div className="box-dataupper">
                    <h3>
                      <span>{exercise.ExerciseName}</span>
                    </h3>
                    <p style={{ color: '#aaa', paddingRight: 20 }}>
                      {exercise.Instructions?.length
                        ? exercise.Instructions
                        : t('texts.withoutDescription')}
                    </p>
                  </div>
                </div>

                {exercise.createdBy && (
                  <div className="images-chart">
                    <img
                      src="assets/close.png"
                      alt=""
                      style={{ height: 20, width: 20, marginRight: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteOpen(exercise);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <RenderIf isTrue={loadingNextPage}>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress
              size={23}
              color="primary"
              sx={{ position: 'static' }}
            />
          </div>
        </RenderIf>

        <SaveExercise
          open={exerciseModalOpened}
          onClose={() => {
            setSelectedExercise(undefined);
            setExerciseModalOpened(false);
          }}
          exercise={selectedExercise}
          updateExercises={updateExercises}
        />

        <MuiModal
          onClose={onCloseDeleteModal}
          style={{ width: 600 }}
          open={openDelete}
          title={t('modals.titles.deleteExercise')}
          description={t('modals.phrases.areYouSureDeleteExercise')}
          onSubmit={deleteExercise}
          submitBtnText={t('buttons.yes')}
          closeBtnText={t('buttons.cancel')}
        />
        <ExercisesImport
          isOpen={exercisesImportModalOpened}
          onClose={() => setExercisesImportModalOpened(false)}
        />
      </div>
    </React.Fragment>
  );
};
export default LibraryPage;
