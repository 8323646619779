import {
  HIT_CLIENT_REQUEST,
  GET_CLIENTS_DATA,
  GET_CLIENT_DATA,
  GET_CLIENTS_BY_IDS,
  FAILED_CLIENT_DATA,
  UPDATE_CLIENT_STATUS,
  UPDATE_CLIENT,
  GET_ALL_CLIENTS_REQUEST,
  ASSIGN_COACH,
  DELETE_CLIENT,
  SEND_CLIENT_JOIN_REQUEST,
} from '../constants';
import {
  deactiveToUsers,
  activeToUsers,
  getClientOfTrainer,
  getAllUsers,
  updateUser,
  assignCoach,
  getUserById,
  deleteUser,
  getUsersByIds,
  sendClientSignupEmail,
  cancelInvite,
} from '../../Service/Api';
import { toast } from 'react-toastify';
import i18n from '../../i18n.js';

const EVENT_CLICK = 'EVENT_CLICK';

export const clickEvent = (data) => {
  return {
    type: EVENT_CLICK,
    payload: data,
  };
};

export const updateClient = (id, body, onSuccess) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });

  try {
    const token = localStorage.getItem('token');
    let response = await updateUser(token, id, body);

    if (response && response.statusCode === 200) {
      toast.success(i18n.t('success.clientUpdated'));
      onSuccess();
      dispatch({
        type: UPDATE_CLIENT,
        payload: {
          data: response.data ?? [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
  }
};

export const deleteClient = (id, onSuccess, onFail) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });

  try {
    const token = localStorage.getItem('token');
    let response = await deleteUser(token, id);

    if (response && response.statusCode === 200) {
      toast.success(i18n.t('success.clientDeleted'));
      dispatch({ type: DELETE_CLIENT, payload: { data: id } });
      onSuccess();
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
    onFail();
  }
};

export const sendClientJoinRequest =
  (coachId, clientsEmails, onSuccess, onFail) => async (dispatch) => {
    dispatch({ type: HIT_CLIENT_REQUEST });

    try {
      const token = localStorage.getItem('token');
      let response = await sendClientSignupEmail(token, coachId, clientsEmails);

      if (response && response.statusCode === 200) {
        dispatch({ type: SEND_CLIENT_JOIN_REQUEST });
        onSuccess(i18n.t('success.clientJoinRequestSent'));
      } else {
        throw response;
      }
    } catch (err) {
      dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
      onFail(err.message || err.error || i18n.t('errors.somethingWentWrong'));
    }
  };

export const assignClientToCoach =
  (clientEmail, coachId, callback) => async (dispatch) => {
    dispatch({ type: HIT_CLIENT_REQUEST });

    try {
      const token = localStorage.getItem('token');
      let response = await assignCoach(token, clientEmail, coachId);

      if (response && response.statusCode === 200) {
        callback();
        dispatch({
          type: ASSIGN_COACH,
          payload: {
            data: response.data ?? [],
          },
        });
      } else {
        toast.error(response.error);
        throw response;
      }
    } catch (err) {
      dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
    }
  };

export const getClients =
  (coachId, filterBy = {}, sortBy) =>
  async (dispatch) => {
    dispatch({ type: HIT_CLIENT_REQUEST });

    try {
      const token = localStorage.getItem('token');
      const filters = { coach: coachId, ...filterBy };
      let response = await getAllUsers(token, filters, sortBy);

      if (response && response.statusCode === 200) {
        dispatch({
          type: GET_CLIENTS_DATA,
          payload: {
            data: response.data ?? [],
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
    }
  };

export const getClientById = (id) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });

  try {
    const token = localStorage.getItem('token');
    let response = await getUserById(id, token);

    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_CLIENT_DATA,
        payload: {
          data: response.data ?? [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
  }
};

export const getClientsByIds = (ids) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });

  try {
    const token = localStorage.getItem('token');
    let response = await getUsersByIds(ids, token);

    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_CLIENTS_BY_IDS,
        payload: {
          data: response.data ?? [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
  }
};

export const getclients = (userType) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });

  try {
    const token = localStorage.getItem('token');
    let response = await getAllUsers(token, {
      status: userType === 'active' ? 'Active' : 'Deactive',
    });

    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_CLIENTS_DATA,
        payload: {
          data: response.data ?? [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({ type: FAILED_CLIENT_DATA, error: err.message });
  }
};

export const updateClientStatus = (ids, updateType) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });
  try {
    const token = localStorage.getItem('token');
    let response = null;
    const body = {
      clients: ids,
    };
    if (updateType === 'Active') {
      response = await deactiveToUsers(body, token);
    } else if (updateType === 'Pending') {
      const clientId = body.clients[0];
      response = await cancelInvite(clientId, token);
    } else {
      response = await activeToUsers(body, token);
    }
    if (response && response.statusCode === 200) {
      dispatch({
        type: UPDATE_CLIENT_STATUS,
        payload: {
          filtredIds: ids || [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_CLIENT_DATA,
      payload: { error: err?.message ?? i18n.t('errors.somethingWentWrong') },
    });
  }
};

export const set_allClients = (id) => async (dispatch) => {
  dispatch({ type: HIT_CLIENT_REQUEST });

  try {
    const token = localStorage.getItem('token');
    const response = await getClientOfTrainer(id, token);

    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_ALL_CLIENTS_REQUEST,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_CLIENT_DATA,
      error: err?.error ?? err?.message ?? i18n.t('errors.somethingWentWrong'),
    });
  }
};
