import {
  HIT_TEAMS_REQUEST,
  GET_TEAMS_DATA,
  FAILED_TEAM_REQUEST,
  CREATE_TEAM_REQUEST,
  UPDATE_TEAM_REQUEST,
  GET_TEAM_REQUEST,
  DELETE_TEAM_REQUEST,
} from '../constants';

const initialValue = {
  teamId: '',
  eventDate: new Date(),
};
export const addTeamData = (state = initialValue, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_TEAM_DATA': {
      return {
        ...payload.data,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const init = {
  data: [],
  selectedTeam: null,
  loading: false,
  error: null,
};

export const teamReducer = (state = init, { payload, type }) => {
  switch (type) {
    case HIT_TEAMS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TEAMS_DATA: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }
    case GET_TEAM_REQUEST: {
      return {
        ...state,
        selectedTeam: payload.data,
        loading: false,
      };
    }
    case UPDATE_TEAM_REQUEST: {
      return {
        ...state,
        data: state.data.map((team) =>
          team._id === payload.data._id ? payload.data : team,
        ),
        loading: false,
      };
    }
    case DELETE_TEAM_REQUEST: {
      return {
        ...state,
        data: state.data.filter((team) => team._id !== payload.teamId),
        loading: false,
      };
    }
    case CREATE_TEAM_REQUEST: {
      return {
        ...state,
        data: [...state.data, payload.data],
        loading: false,
      };
    }
    case FAILED_TEAM_REQUEST: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
