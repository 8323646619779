import React, { useContext, useEffect } from 'react';
import PaymentCards from './PaymentCards';
import moment from 'moment';
import useSubscription from '../../pages/payment/subscription/useSubscription';
import './Payment.css';
import { useTranslation } from 'react-i18next';

const PaymentSection = () => {
  const { trialPeriodData, currentPrice, prices, getNumberOfActiveClients } =
    useSubscription();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="top-content-section center">
              <h2 className="clr-white">{t('texts.subscriptionPageTitle')}</h2>
              <p className="p-small">
                {t('texts.subscriptionPageDescription')}
              </p>
              <div className="space-40px"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="green-tick-content center">
              <ul className="ul-tag-tick-horizotal list-style-none">
                <li>
                  <img src="assets/green-thick.svg" />
                  {t('texts.twentyFourSevenReport')}
                </li>
                <li>
                  <img src="assets/green-thick.svg" />
                  {t('texts.cancelAnytime')}
                </li>
              </ul>
              <div className="space-40px"></div>
            </div>
          </div>
        </div>
        {trialPeriodData?.isTrialPeriod && (
          <div>
            <br />
            <h3 style={{ textAlign: 'center' }}>
              {t('texts.yourTrialPeriodEndsAt')}{' '}
              {moment(trialPeriodData.trialPeriod).format('MM/DD/YYYY')}
            </h3>
            <br />
            <h5 style={{ textAlign: 'center' }}>{t('texts.afterTrialEnds')}</h5>
          </div>
        )}

        <div className="pricing-table-info">
          <div className="tabs-container">
            <div className="space-40px"></div>
            <div className="tab-content" id="cedoTabsContent">
              <PaymentCards
                data={prices}
                activeClients={getNumberOfActiveClients()}
                isTrialPeriod={trialPeriodData?.isTrialPeriod}
                currentPrice={currentPrice}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentSection;
