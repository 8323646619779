import { Delete } from '@material-ui/icons';
import { Stack, Typography } from '@mui/material';
import MuiButton from '../../../components/shared/MuiButton';
import { useTranslation } from 'react-i18next';

const PaymentCard = ({
  getCardBrandImage,
  setCardToBeRemoved,
  card,
  removeDisabled,
  setDefault,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{
        bgcolor: 'background.light',
        p: 2.5,
        borderRadius: 2.5,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack
          justifyContent="center"
          sx={{ width: '85px', height: '61px', '& > *': { borderRadius: 1.5 } }}
        >
          {getCardBrandImage(card.brand)}
        </Stack>

        <Typography>Ending in {card.cardno} </Typography>
        <Typography color={'primary.main'}>
          {card.default && `(${t('texts.default')})`}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        {!card.default && (
          <MuiButton
            onClick={() => setDefault(card)}
            transparent
            disabled={removeDisabled}
          >
            {t('buttons.setDefault')}
          </MuiButton>
        )}

        <MuiButton
          onClick={() => setCardToBeRemoved(card)}
          icon={<Delete />}
          transparent
          danger
          disabled={removeDisabled}
        >
          {t('buttons.remove')}
        </MuiButton>
      </Stack>
    </Stack>
  );
};

export default PaymentCard;
