import { InputLabel, Stack } from '@mui/material';
import { useEffect } from 'react';
import FormInput from '../shared/FormInput';
import MuiModal from '../shared/MuiModal';
import useSaveExercise from './useSaveExercise';
import { useTranslation } from 'react-i18next';

const SaveExercise = ({ exercise, open, onClose, updateExercises }) => {
  const { t } = useTranslation();
  const { register, submit, errors, loading } = useSaveExercise({
    onClose,
    exercise,
    updateExercises,
    open,
  });

  return (
    <MuiModal
      title={
        exercise
          ? t('modals.titles.updateExercise')
          : t('modals.titles.createExercise')
      }
      open={open}
      onClose={onClose}
      onSubmit={submit}
      submitBtnText={exercise ? t('buttons.save') : t('buttons.create')}
      isLoading={loading}
    >
      <Stack spacing={1} py={2}>
        <FormInput
          register={register}
          label={t('texts.exerciseName')}
          name="ExerciseName"
          placeholder={t('placeholders.enterExerciseName')}
          error={errors.ExerciseName}
          options={{ required: t('validations.exerciseNameRequired') }}
        />
        <FormInput
          register={register}
          label="Youtube URL"
          name="YoutubeUrl"
          placeholder="Ex: https://www.youtube.com/watch?v=8"
        />
        <div>
          <InputLabel shrink sx={{ color: 'white', mb: 0, ml: 0.5 }}>
            {t('texts.instructions')}
          </InputLabel>
          <textarea
            {...register('Instructions')}
            placeholder={t('placeholders.exerciseInstructions')}
            className="form-control"
            style={{
              backgroundColor: '#2E313F',
              color: 'white !important',
              borderColor: 'transparent',
              borderRadius: 10,
            }}
            rows="4"
            cols="60"
          />
        </div>
      </Stack>
    </MuiModal>
  );
};

export default SaveExercise;
