import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSubscriptionStatus } from '../../Service/Api';

export default function PaymentConfirmation() {
  const { t } = useTranslation();

  useEffect(() => {
    checkSubscription();
  }, []);

  const checkSubscription = async () => {
    const response = await getSubscriptionStatus();
    const status = response?.data;
    if (status === 'ACTIVE' || status === 'NOT_ACTIVE') {
      const user = JSON.parse(localStorage.getItem('auth'));
      user.subscriptionStatus = status;
      localStorage.setItem('auth', JSON.stringify(user));
    }
  };

  return (
    <div>
      <h1>{t('texts.confirmingSubscription')}</h1>
      <span>{t('texts.processingYourPayment')}</span>
    </div>
  );
}
