import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AuthContext } from '../../App';
import LoaderContext from '../../context/Loader';
import { useDebounce } from '../../hooks/useDebounce';
import {
  getClients,
  sendClientJoinRequest,
  updateClientStatus,
} from '../../redux/actions/Clients';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../utils';
import { updateUser } from '../../Service/Api';

const useClientsList = () => {
  const dispatch = useDispatch();
  const loader = useContext(LoaderContext);
  const { auth, setAuth } = useContext(AuthContext);
  const { t } = useTranslation();

  const clientsData = useSelector((state) => state.client);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [clientIdForChat, setClientIdForChat] = useState(false);
  const [clientIdForStatus, setClientIdForStatus] = useState('');
  const [focusChatMessage, setFocusChatMessage] = useState('');
  const [isTutorialModalOpened, setTutorialModalOpened] = useState(
    auth.showTutorial,
  );

  const orderByDirectionDict = { Name: 1, updatedOn: -1, frequent: -1 };
  const orderByOptions = [
    { label: t('texts.name'), value: 'Name' },
    { label: t('texts.recentlyUsed'), value: 'updatedOn' },
    { label: t('texts.frequentlyUsed'), value: 'frequent' },
  ];
  const clientStatusOptions = [
    { value: 'Active', label: t('texts.active') },
    { value: 'Pending', label: t('texts.pending') },
    { value: 'Deactive', label: t('texts.deactivated') },
  ];

  const {
    control: controlOptions,
    register: registerOptions,
    watch: watchOptions,
    setValue: setOptionsValue,
  } = useForm({
    defaultValues: {
      filterByName: '',
      filterByStatus: clientStatusOptions[0].value,
      orderBy: orderByOptions[0].value,
    },
  });

  const {
    register: registerClientForm,
    reset: resetClientForm,
    getValues: getClientFormValues,
    handleSubmit: handleNewClientSubmit,
    watch: watchNewClientForm,
    setValue: setClientFormValue,
    formState: { errors },
  } = useForm({ defaultValues: { newClientEmail: '', newClientsEmails: [] } });

  const [newClientEmail, newClientsEmails] = watchNewClientForm([
    'newClientEmail',
    'newClientsEmails',
  ]);

  const [filterByName, filterByStatus, orderBy] = watchOptions([
    'filterByName',
    'filterByStatus',
    'orderBy',
  ]);

  const debouncedSearch = useDebounce(filterByName, 500);

  const fetchClientsWithOptions = () => {
    const filters = {
      ...{ status: filterByStatus },
      ...(filterByName ? { Name: filterByName } : {}),
    };
    dispatch(
      getClients(
        auth._id,
        filters,
        orderBy ? { [orderBy]: orderByDirectionDict[orderBy] } : undefined,
      ),
    );
  };

  useEffect(() => {
    fetchClientsWithOptions();
  }, [debouncedSearch]);

  const handleCloseChat = () => {
    setClientIdForChat('');
    fetchClientsWithOptions();
  };

  const onChangeClientStatus = () => {
    dispatch(updateClientStatus([clientIdForStatus], filterByStatus));
    setClientIdForStatus('');
  };

  const onAddEmail = () => {
    if (!validateEmail(newClientEmail)) toast.info('Enter a valid email');
    else if (newClientsEmails.includes(newClientEmail))
      toast.info('Email already added');
    else {
      setClientFormValue('newClientsEmails', [
        ...newClientsEmails,
        newClientEmail.trim(),
      ]);
      setClientFormValue('newClientEmail', '');
    }
  };

  const onAddNewClients = handleNewClientSubmit((data, event) => {
    event.preventDefault();

    if (!newClientsEmails?.length) {
      toast.info(t('validations.inviteEmailsRequired'));
      return;
    }

    const coachId = auth._id;

    dispatch(
      sendClientJoinRequest(
        coachId,
        newClientsEmails,
        (message) => {
          toast.success(message);
          setShowAddClientModal(false);
          fetchClientsWithOptions();
          resetClientForm();
          setOptionsValue('filterByStatus', 'Pending');
        },
        (message) => {
          toast.error(message);
          setShowAddClientModal(false);
        },
      ),
    );
  });

  const onRemoveEmail = (value) => {
    setClientFormValue(
      'newClientsEmails',
      newClientsEmails.filter((email) => email !== value),
    );
  };

  const updateShowTutorial = async () => {
    const body = { showTutorial: false };
    try {
      await updateUser(auth.token, auth._id, body).then(() => {
        const updatedUser = { ...auth, showTutorial: false };
        localStorage.setItem('auth', JSON.stringify(updatedUser));
        setAuth(updatedUser);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onCloseTutorialModal = () => setTutorialModalOpened(false);

  useEffect(() => {
    if (auth.showTutorial) {
      updateShowTutorial();
    }
  }, []);

  useEffect(() => {
    fetchClientsWithOptions();
  }, [filterByStatus, orderBy]);

  useEffect(() => {
    loader.setLoading(clientsData.loading);
  }, [loader, clientsData.loading]);

  return {
    orderByOptions,
    clientStatusOptions,
    onAddNewClients,
    onChangeClientStatus,
    showAddClientModal,
    setShowAddClientModal,
    clientIdForStatus,
    setClientIdForStatus,
    clientsData,
    setClientIdForChat,
    clientIdForChat,
    controlOptions,
    registerOptions,
    registerClientForm,
    focusChatMessage,
    setFocusChatMessage,
    handleCloseChat,
    errors,
    onAddEmail,
    newClientsEmails,
    onRemoveEmail,
    onCloseTutorialModal,
    isTutorialModalOpened,
  };
};

export default useClientsList;
