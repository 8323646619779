import {
  HIT_NOTIFICATION_REQUEST,
  SET_NOTIFICATION_REQUEST,
  FAILED_NOTFICATION_REQUEST,
  NEW_NOTIFICATION_REQUEST,
  CHECKED_NOTIFICATION_REQUEST,
} from '../constants';
const init = {
  notifications: [],
  loading: false,
  error: null,
  newNotify: false,
};
export const notificationReducer = (state = init, { type, payload }) => {
  switch (type) {
    case HIT_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: false,
        notifications:
          payload.page === 0
            ? [...payload.data.notifications]
            : [...state.notifications, ...payload.data.notifications],
        lastPage: payload.data.lastPage,
      };
    }
    case FAILED_NOTFICATION_REQUEST: {
      return { ...state, loading: false, error: payload.error };
    }
    case NEW_NOTIFICATION_REQUEST: {
      return { ...state, newNotify: true };
    }
    case CHECKED_NOTIFICATION_REQUEST: {
      return { ...state, newNotify: false };
    }
    default: {
      return { ...state };
    }
  }
};
