import { Avatar, Stack, Typography } from '@mui/material';

const Card = ({ avatar, onClick, onAvatarClick, title, children }) => {
  return (
    <Stack
      className="boxes"
      sx={{ mx: 0, py: 3, pl: 4 }}
      direction="row"
      justifyContent="space-between"
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <Avatar
          sx={{
            width: 80,
            height: 80,
            color: 'white',
            cursor: onAvatarClick ? 'pointer' : 'normal',
          }}
          src={avatar || '/broken-image.jpg'}
          onClick={onAvatarClick}
        />
        <Typography sx={{ color: 'secondary.main', fontSize: 25 }}>
          {title}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default Card;
