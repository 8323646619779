import { LanguageOutlined } from '@mui/icons-material';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import i18n from '../../i18n';
import { languages } from '../../utils';
import { useState } from 'react';

const LanguageDropdown = () => {
  const currentLanguage = i18n.resolvedLanguage;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, lng) => {
    e.stopPropagation();
    if (lng) i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <LanguageOutlined color="white" style={{ width: 25, height: 25 }} />

      <Typography sx={{ color: 'white' }}>
        {languages[currentLanguage].nativeName}
      </Typography>

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.keys(languages).map((lng) => (
          <MenuItem key={lng} value={lng} onClick={(e) => handleClose(e, lng)}>
            {languages[lng].nativeName}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default LanguageDropdown;
