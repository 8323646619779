import {
  CHAT_REQUEST_HIT,
  LOAD_CHAT_HISTORY,
  FAILED_CHAT_REQUEST,
  INSERT_CHAT_MESSAGE,
  EMPTY_CHAT,
} from '../constants';
import { getOldChat } from '../../Service/Api';

export const initilChatDataAction = (messages, total) => (dispatch) => {
  dispatch({ type: CHAT_REQUEST_HIT });

  if (messages) {
    dispatch({
      type: LOAD_CHAT_HISTORY,
      payload: { messages, total },
    });
  } else {
    dispatch({
      type: FAILED_CHAT_REQUEST,
      payload: {
        error: 'Chat not found',
      },
    });
  }
};

export const addMessageAction = (message) => (dispatch) => {
  dispatch({
    type: INSERT_CHAT_MESSAGE,
    payload: { message },
  });
};

export const emptyToChat = () => (dispatch) => {
  dispatch({ type: EMPTY_CHAT });
};

export const onChatRequestFailed = (err) => (dispatch) => {
  dispatch({
    type: FAILED_CHAT_REQUEST,
    payload: {
      error: err?.error || err?.message || 'Chat not found',
    },
  });
};

export const loadChatHistory =
  (roomId, page, pageSize, callback) => async (dispatch) => {
    dispatch({ type: CHAT_REQUEST_HIT });

    try {
      const response = await getOldChat(roomId, page, pageSize);

      if (response.statusCode === 200) {
        dispatch({
          type: LOAD_CHAT_HISTORY,
          payload: {
            messages: response.items,
            total: response.total,
          },
        });
      } else throw response;
    } catch (err) {
      dispatch(onChatRequestFailed(err));
    } finally {
      callback();
    }
  };
