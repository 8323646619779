import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          navbar: {
            features: 'Features',
            pricing: 'Pricing',
            about: 'About',
            contact: 'Contact',
            tryForFree: 'Try For Free',
          },
          sidebar: {
            clients: 'Clients',
            teams: 'Teams',
            library: 'Library',
            statistics: 'Statistics',
          },
          buttons: {
            apply: 'Apply',
            inviteClient: 'Invite Client',
            addTeam: 'Add Team',
            addExercise: 'Add Exercise',
            addSession: 'Add Session',
            saveSessionToLibrary: 'Save session to library',
            create: 'Create',
            save: 'Save',
            close: 'Close',
            goToToday: 'Go to Today',
            send: 'Send',
            scheduleSessions: 'Schedule Sessions',
            newSession: 'New Session',
            import: 'Import',
            newExercise: 'New Exercise',
            addExercises: 'Add Exercise(s)',
            newSet: 'New Set',
            change: 'Change',
            logout: 'Logout',
            back: 'Back',
            createAccount: 'Create Account',
            createSession: 'Create Session',
            confirm: 'Confirm',
            sendInvite: 'Send Invite',
            yes: 'Yes',
            cancel: 'Cancel',
            next: 'Next',
            submit: 'Submit',
            submiting: 'Submiting...',
            deleteClient: 'Delete Client',
            newCard: 'New Card',
            remove: 'Remove',
            setDefault: 'Set Default',
            update: 'Update',
            verify: 'Verify',
            importExercises: 'Import Exercises',
          },
          altTexts: {
            profile: 'Profile',
            chat: 'Chat',
            calendar: 'Calendar',
            chart: 'Chart',
            deactivate: 'Deactivate',
            edit: 'Edit',
            members: 'Members',
            delete: 'Delete',
            compressedView: 'Compressed View',
            expandedView: 'Expanded View',
          },
          texts: {
            total: 'Total',
            orderSummary: 'Order Summary',
            creditCardInformation: 'Credit Card Information',
            passwordRequirements:
              'Password must have at least 8 characters containing uppercase, lowercase, number and special character.',
            coupon: 'Coupon',
            fullName: 'Full Name',
            organizationName: 'Organization Name',
            client: 'Client',
            clients: 'Clients',
            teams: 'Teams',
            filterByStatus: 'Filter by status',
            active: 'Active',
            activate: 'Activate',
            deactivate: 'Deactivate',
            deactivated: 'Deactivated',
            pending: 'Pending',
            cancelInvite: 'Cancel invite',
            orderBy: 'Order by',
            name: 'Name',
            recentlyUsed: 'Recently Used',
            frequentlyUsed: 'Frequently Used',
            emailAddress: 'Email Address',
            dateOfBirth: 'Date of Birth',
            gender: 'Gender',
            weightUnit: 'Weight Unit',
            heightUnit: 'Height Unit',
            weight: 'Weight',
            height: 'Height',
            goals: 'Goals',
            today: 'Today',
            sessions: 'Sessions',
            session: 'Session',
            exercises: 'Exercises',
            exercise: 'Exercise',
            clientSessionTitle: 'Client Session Title',
            clientSessionInstructions: 'Client Session Instructions',
            addExercisesOrImportSessions: 'Add exercises or import sessions.',
            noExercises: 'No exercises found.',
            firstMetric: '1st metric',
            secondMetric: '2nd metric',
            loading: 'Loading...',
            distance: 'Distance',
            time: 'Time',
            reps: 'Reps',
            calories: 'Calories',
            meters: 'Meters',
            kilometers: 'Kilometers',
            inches: 'Inches',
            miles: 'Miles',
            rpe: 'RPE(1-10)',
            set: 'Set',
            sets: 'Sets',
            tempo: 'Tempo',
            restPeriod: 'Rest Period',
            superset: 'Superset',
            aggregation: 'Aggregation',
            fromDate: 'From Date',
            toDate: 'To Date',
            noDataAvailableForSelectedFilters:
              'No data available for selected filters.',
            changeProfilePhoto: 'Change Profile Photo',
            subscription: 'Subscription',
            subscriptionPageTitle: 'Simple, transparent pricing',
            subscriptionPageDescription: 'No contracts. No surprise fees.',
            twentyFourSevenReport: '24/7 Report',
            cancelAnytime: 'Cancel Anytime',
            yourTrialPeriodEndsAt: 'Your trial period ends at',
            afterTrialEnds: 'After it, you can choose one of our plans:',
            email: 'Email',
            password: 'Password',
            forgotPasswordQuestion: 'Forgot Password?',
            forgotPassword: 'Forgot your password',
            dontHaveAccount: "Don't have an account?",
            signup: 'Sign Up',
            alreadyHaveAccountQuestion: 'Already have an account?',
            login: 'Login',
            sport: 'Sport',
            accessCode: 'Access Code',
            teamName: "Team's Name",
            teamPhoto: "Team's Photo",
            noClientsFound: 'No clients found.',
            noClientsFoundTip:
              'Try changing the filters or invite a new client.',
            inviteNewClient: 'Invite New Client',
            noTeamsFound: 'No teams found.',
            noTeamsFoundTip: 'Try changing the filters or add a new team.',
            members: 'Members',
            withoutDescription: 'Without Description.',
            exerciseName: 'Exercise Name',
            instructions: 'Instructions',
            createdByYou: 'Created by you',
            noSessionsFound: 'No sessions found.',
            sessionImage: 'Session Image',
            exerciseProgression: 'Exercise Progression',
            noDataAvailable: 'No data available.',
            notifications: 'Notifications',
            allClients: 'All Clients',
            waitingPaymentConfirmation: 'Waiting for payment confirmation',
            attachedClients: 'Attached Clients',
            manageClients: 'Manage Clients',
            numberOfClients: 'Number of Clients',
            price: 'Price',
            upTo: 'Up to',
            selectPlan: 'Select Plan',
            cancelPlan: 'Cancel Plan',
            paySubscription: 'Pay for subscription.',
            enjoyJourney: 'Enjoy your journey.',
            month: 'Month',
            users: 'Users',
            cardNumber: 'Card Number',
            expiryDate: 'Expiry Date',
            cvv: 'CVV Code',
            profileInformation: 'Profile Information',
            paymentInfo: 'Payment Info',
            registeredCards: 'Registered Cards',
            noRegisteredCards: 'No registered cards.',
            previousPayments: 'Previous Payments',
            noPaymentsHistory: 'No history of previous payments',
            amount: 'Amount',
            receipt: 'Receipt',
            default: 'Default',
            sessionInstructions: 'Session Instructions',
            duplicateSession: 'Duplicate Session',
            duplicate: 'Duplicate',
            selectSession: 'Select a session',
            verificationCodeSentToEmail:
              'Verification code sent to your email.',
            verificationCode: 'Verification Code',
            language: 'Language',
            choosePlan: 'Choose a plan',
            perUser: 'per User',
            mostPopular: 'MOST POPULAR',
            processingYourPayment:
              'Payment request succesful! It may take some minutes to complete your subscription.',
            subscriptionStatus: 'Subscription Status',
            activeSubscription: 'Active Subscription',
            noActiveSubscription: 'No active subscription',
            trialPeriod: 'Trial Period',
            invalidSubscription: 'Invalid Subscription',
            cancelRequested: 'Cancel Requested',
            sessionName: 'Session Name',
            newEmail: 'New Email',
            emails: 'Emails',
            dueDate: 'Due Date',
            duration: 'Duration',
            volume: 'Volume',
            confirmingSubscription: 'Confirming subscription',
          },
          placeholders: {
            searchByClientName: "Search by client's name",
            searchByTeamName: "Search by team's name",
            searchByExerciseName: "Search by exercise's name",
            searchBySessionName: "Search by session's name",
            enterEmail: 'Enter your email',
            enterPassword: 'Enter your password',
            enterNewPassword: 'Enter your new password',
            enterClientName: "Enter client's name",
            enterClientEmail: "Enter client's email",
            enterClientWeight: "Enter client's weight",
            enterClientHeight: "Enter client's height",
            enterClientGoals: "Enter client's goals",
            enterTeamName: "Enter team's name",
            enterSportName: "Enter sport's name",
            enterTeamAccessCode: "Enter team's access code",
            enterMessage: 'Enter a message...',
            enterSessionTitle: "Enter session's title",
            enterSessionInstructions: "Enter session's instructions",
            enterTheInstructions: 'Enter the instructions',
            enterExerciseName: 'Enter an exercise name',
            enterOrganizationName: 'Enter your organization name',
            confirmPassword: 'Confirm Password',
            selectSomeClients: 'Select some clients',
            exerciseInstructions:
              'Ex: Engage your back Grip the floor with your feet Start the movement at the hip',
            sessionName: 'Ex: Running Session',
            enterVerificationCode: 'Enter the code sent to your email',
          },
          tooltips: {
            profile: 'Profile',
            chat: 'Chat',
            calendar: 'Calendar',
            chart: 'Chart',
            deactivate: 'Deactivate',
            edit: 'Edit',
            members: 'Members',
            delete: 'Delete',
            aggregation:
              'Month segmentation shows the mean of all the values in each of the months selected',
          },
          modals: {
            phrases: {
              areYouSure: 'Are you sure you want to',
              thisClient: 'this client?',
              areYouSureDelete: 'Are you sure you want to delete',
              areYouSureDeleteExercise:
                'Are you sure you want to delete this exercise?',
              areYouSureDeleteSession:
                'Are you sure you want to delete this session?',
              cancelSubscription:
                "If you cancel your subscription you won't be able to use our services.",
              cancelSubscriptionConfirm:
                "Are you sure you want to cancel your current subscription? By pressing the 'Confirm' button, you will lose access to all our services at the end of your current billing period.",
              selectDateToScheduleSession:
                'Select a date bellow to schedule this session to:',
              areYouSureDeleteUser:
                "Are you sure you want to DELETE this user? He'll lose all his access",
              areYouSureDeleteCard:
                'Are you sure you want to remove your {{cardBrand}} card ending in {{cardNumber}}?',
              confirmExit:
                'Do you really want to exit? All unsaved changes will be lost.',
            },
            titles: {
              editTeam: 'Edit Team',
              createNewTeam: 'Create New Team',
              deleteTeam: 'Delete Team',
              changeTeamPhoto: 'Change Team Photo',
              deleteExercise: 'Delete Exercise',
              updateExercise: 'Update Exercise',
              createExercise: 'Create Exercise',
              deleteSession: 'Delete Session',
              cancelSubscription: 'Cancel Subscription',
              confirmSubscriptionCancel: 'Confirm subscription cancelation',
              removeCard: 'Remove Card',
              registerNewCard: 'Register New Card',
              updateSessionDate: 'Update Session Date',
              deleteClient: 'Delete Client',
            },
          },
          validations: {
            teamNameRequired: 'Team name is required.',
            sportNameRequired: 'Sport name is required.',
            teamMembersRequired: 'Team members are required.',
            teamAccessCodeRequired: 'Team access code is required.',
            photoRequired: 'Photo is required.',
            exerciseNameRequired: 'Exercise name is required.',
            youtubeUrlRequired: 'Youtube URL is required.',
            fullNameRequired: 'Full name is required.',
            nameIsRequired: 'Name is required.',
            dateOfBirthRequired: 'Date of birth is required.',
            weightRequired: 'Weight is required.',
            heightRequired: 'Height is required.',
            emailRequired: 'Email is required.',
            codeRequired: 'Code is required.',
            invalidEmail: 'Invalid email.',
            titleRequired: 'Title is required.',
            exercisesRequired: 'Keep at least one exercise.',
            allTableValuesRequired: 'All table values are required.',
            noExercisesSelected: 'No exercises selected.',
            sameDate: "Can't select the same date",
            addAtLeastOneEmail: 'Add at least one email.',
            inviteEmailsRequired: 'Use the + button to add an email',
          },
          errors: {
            couldNotSendVerificationCode: 'Could not send verification code.',
            somethingWentWrong: 'Something went wrong. Please try again later.',
            creatingSession: 'Error creating session.',
            incorrectCode: 'Incorrect verification code.',
            duplicateSession: "Couldn't duplicate session",
          },
          success: {
            login: 'Login successful.',
            exerciseUpdated: 'Exercise updated successfully.',
            userCreated: 'User created successfully.',
            sessionCreated: 'Session created succesfully.',
            sessionUpdated: 'Session updated succesfully.',
            sessionDeleted: 'Session deleted succesfully.',
            sessionDuplicated: 'Session duplicated.',
            sessionDateChanged: 'Session date changed.',
            clientSessionRemoved: 'Client session removed.',
            codeVerified: 'Code verified.',
            teamCreated: 'Team created successfully.',
            teamUpdated: 'Team updated successfully.',
            teamDeleted: 'Team deleted successfully.',
            teamPhotoUpdated: 'Team photo updated successfully.',
            profileUpdated: 'Profile updated successfully.',
            cardSetDefault: 'Card set as default successfully.',
            cardUpdated: 'Card updated successfully.',
            cardCreated: 'Card created successfully.',
            cardRemoved: 'Kortet har tagits bort.',
            clientUpdated: 'Client updated successfully.',
            clientDeleted: 'Client deleted successfully.',
            clientJoinRequestSent:
              'Successfully sent the client an email with the join request.',
          },
          phrases: {
            areYouSure: 'Are you sure',
            thisClient: 'this client?',
            teamModal: {
              phrase1:
                'Athletes can enter an access code in the mobile app to join your organization.',
              phrase2:
                'A code you create can be used by anyone, and there is no limit or expiration date.',
              phrase3:
                'You can inactivate the access code by leaving the field blank.',
              phrase4:
                'This will not impact athletes who used the code in the past.',
              phrase5:
                "Athletes who use access codes may count toward your plan's athlete limit and cause your plan to be upgraded.",
            },
            statistics: {
              chartAvailability:
                'Chart only available for exercises using "reps" and "weight" metrics.',
            },
            notifications: {
              emptyNotifications:
                'No notifications found for the selected client.',
            },
            card: {
              enterDetails: 'Enter your card details below.',
            },
          },
        },
      },
      se: {
        translation: {
          navbar: {
            features: 'Funktioner',
            pricing: 'Prissättning',
            about: 'Handla om',
            contact: 'Kontakt',
            tryForFree: 'Prova gratis',
          },
          sidebar: {
            clients: 'Kunder',
            teams: 'Lag',
            library: 'Bibliotek',
            statistics: 'Statistik',
          },
          buttons: {
            apply: 'Tillämpa',
            inviteClient: 'Bjud in ny kund',
            addTeam: 'Lägg till lag',
            addExercise: 'Lägg till övning',
            addSession: 'Lägg till träningspass',
            saveSessionToLibrary: 'Lägg till träningspass i biblioteket',
            create: 'Skapa',
            save: 'Spara',
            close: 'Stäng',
            goToToday: 'Gå till idag',
            send: 'Skicka',
            scheduleSessions: 'Schemalägg träningspass',
            newSession: 'Nytt trönings pass',
            import: 'Importera',
            newExercise: 'Ny övning',
            addExercises: 'Lägg till övningar',
            newSet: 'Ny uppsättning',
            change: 'Ändra',
            logout: 'Logga ut',
            back: 'Tillbaka',
            createAccount: 'Skapa konto',
            createSession: 'Skapa träningspass',
            confirm: 'Bekräfta',
            sendInvite: 'Skicka inbjudan',
            yes: 'Ja',
            cancel: 'Avbryt',
            submit: 'Skicka',
            submiting: 'Skickar...',
            deleteClient: 'Ta bort kund',
            newCard: 'Nytt kort',
            remove: 'Ta bort',
            setDefault: 'Ange som standard',
            update: 'Uppdatera',
            verify: 'Verifiera',
            importExercises: 'Importera övningar',
          },
          altTexts: {
            profile: 'Profil',
            chat: 'Chatt',
            calendar: 'Kalender',
            chart: 'Diagram',
            deactivate: 'Deaktivera',
            edit: 'Redigera',
            members: 'Medlemmar',
            delete: 'Ta bort',
            compressedView: 'Komprimerad vy',
            expandedView: 'Utökad vy',
          },
          texts: {
            passwordRequirements:
              'Lösenordet måste ha minst 8 tecken innehållande versaler, gemener, siffror och specialtecken.',
            total: 'Summa',
            orderSummary: 'Ordersammanfattning',
            creditCardInformation: 'Kreditkortsinformation',
            coupon: 'Kupong',
            fullName: 'Fullständigt namn',
            organizationName: 'Organisationsnamn',
            client: 'Kund',
            clients: 'Kunder',
            teams: 'Lag',
            filterByStatus: 'Filtrera efter status',
            active: 'Aktiv',
            deactivated: 'Deaktiverad',
            pending: 'I väntan på',
            cancelInvite: 'Avbryta inbjudan',
            orderBy: 'Sortera efter',
            name: 'Namn',
            recentlyUsed: 'Nyligen använda',
            frequentlyUsed: 'Mest använda',
            emailAddress: 'E-postadress',
            dateOfBirth: 'Födelsedatum',
            gender: 'Kön',
            weightUnit: 'Viktenhet',
            heightUnit: 'Längdenhet',
            weight: 'Vikt',
            height: 'Längd',
            goals: 'Mål',
            today: 'Idag',
            sessions: 'Träningspass',
            session: 'Träningspass',
            exercises: 'Övningar',
            exercise: 'Övning',
            clientSessionTitle: 'Träningspass Titel',
            clientSessionInstructions: 'Träningspass Instruktioner',
            addExercisesOrImportSessions:
              'Lägg till övningar eller importera träningspass',
            noExercises: 'Inga övningar hittades.',
            firstMetric: '1:a alternativ',
            secondMetric: '2:a alternativ',
            loading: 'Laddar...',
            distance: 'Distans',
            time: 'Tid',
            reps: 'Reps',
            calories: 'Kalorier',
            meters: 'Meter',
            kilometers: 'Kilometer',
            inches: 'Inches',
            miles: 'Miles',
            rpe: 'RPE(1-10)',
            set: 'Set',
            sets: 'Sets',
            tempo: 'Tempo',
            restPeriod: 'Viloperiod',
            superset: 'Superset',
            aggregation: 'Aggregering',
            fromDate: 'Från datum',
            toDate: 'Till datum',
            noDataAvailableForSelectedFilters:
              'Inga data tillgängliga för valda filter.',
            changeProfilePhoto: 'Ändra profilfoto',
            subscription: 'Prenumeration',
            subscriptionPageTitle: 'Enkel, transparent prissättning',
            subscriptionPageDescription:
              'Inga kontrakt. Inga överraskningsavgifter.',
            twentyFourSevenReport: '24/7 Stöd',
            cancelAnytime: 'Avbryt När Som Helst',
            yourTrialPeriodEndsAt: 'Din testperiod slutar',
            afterTrialEnds: 'Efter det kan du välja en av våra planer:',
            email: 'E-post',
            password: 'Lösenord',
            forgotPasswordQuestion: 'Glömt lösenord?',
            forgotPassword: 'Glömt lösenord',
            dontHaveAccount: 'Har du inget konto?',
            signup: 'Registrera dig',
            alreadyHaveAccountQuestion: 'Har du redan ett konto?',
            login: 'Logga in',
            sport: 'Sport',
            accessCode: 'Åtkomstkod',
            teamName: 'Lagnamn',
            teamPhoto: 'Lagfoto',
            noClientsFound: 'Inga kunder hittades.',
            noClientsFoundTip: 'Bjud in en kund eller skapa ett lag.',
            inviteNewClient: 'Bjud in ny kund',
            noTeamsFound: 'Inga lag hittades.',
            noTeamsFoundTip: 'Skapa ett lag.',
            members: 'Medlemmar',
            withoutDescription: 'Utan beskrivning',
            exerciseName: 'Övningens namn',
            instructions: 'Instruktioner',
            createdByYou: 'Skapad av dig',
            noSessionsFound: 'Inga träningspass hittades.',
            sessionImage: 'Träningspass bild',
            exerciseProgression: 'Övningsprogression',
            noDataAvailable: 'Ingen data tillgänglig.',
            notifications: 'Notifikationer',
            allClients: 'Alla kunder',
            waitingPaymentConfirmation: 'Väntar på betalningsbekräftelse',
            attachedClients: 'Tillhörande kunder',
            manageClients: 'Hantera kunder',
            numberOfClients: 'Antal kunder',
            price: 'Pris',
            upTo: 'Upp till',
            selectPlan: 'Välj plan',
            cancelPlan: 'Avbryt plan',
            paySubscription: 'Betala prenumeration',
            enjoyJourney: 'Njut av din resa',
            month: 'Månad',
            users: 'Användare',
            cardNumber: 'Kortnummer',
            expiryDate: 'Utgångsdatum',
            cvv: 'CVV',
            profileInformation: 'Profilinformation',
            paymentInfo: 'Betalningsinformation',
            registeredCards: 'Registrerade kort',
            noRegisteredCards: 'Inga registrerade kort',
            previousPayments: 'Tidigare betalningar',
            noPaymentsHistory: 'Ingen betalningshistorik',
            amount: 'Belopp',
            receipt: 'Kvitto',
            default: 'Standard',
            activate: 'Aktivera',
            sessionInstructions: 'Träningspass instruktioner',
            duplicateSession: 'Duplicera Träningspass',
            duplicate: 'Duplicera',
            selectSession: 'Välj Träningspass',
            verificationCodeSentToEmail:
              'Verifieringskod har skickats till din e-post.',
            verificationCode: 'Verifieringskod',
            language: 'Språk',
            choosePlan: 'Välj plan',
            perUser: 'per användare',
            mostPopular: 'MEST POPULÃR',
            processingYourPayment:
              'Betalningsförfrågan lyckades! Det kan ta några minuter att slutföra din prenumeration.',
            subscriptionStatus: 'Prenumerationsstatus',
            activeSubscription: 'Aktiv prenumeration',
            noActiveSubscription: 'Ingen aktiv prenumeration',
            trialPeriod: 'Testperiod',
            invalidSubscription: 'Ogiltig prenumeration',
            cancelRequested: 'Avbryt begärd',
            sessionName: 'Träningspass namn',
            newEmail: 'Ny e-post',
            emails: 'E-post',
            dueDate: 'Förfallodatum',
            duration: 'Varaktighet',
            volume: 'Volym',
            confirmingSubscription: 'Bekräftar prenumeration',
          },
          placeholders: {
            searchByClientName: 'Sök efter kundens namn',
            searchByTeamName: 'Sök efter lagets namn',
            searchByExerciseName: 'Sök efter övningens namn',
            searchBySessionName: 'Sök efter träningspassets namn',
            enterEmail: 'Ange din e-postadress',
            enterPassword: 'Ange ditt lösenord',
            enterNewPassword: 'Ange ditt nya lösenord',
            enterClientName: 'Ange kundens namn',
            enterClientEmail: 'Ange kundens e-postadress',
            enterClientWeight: 'Ange kundens vikt',
            enterClientHeight: 'Ange kunds längd',
            enterClientGoals: 'Ange kundens mål',
            enterTeamName: 'Ange lagets namn',
            enterSportName: 'Ange sportens namn',
            enterTeamAccessCode: 'Ange lagets åtkomstkod',
            enterMessage: 'Skriv ett meddelande...',
            enterSessionTitle: 'Ange träningspassets titel',
            enterSessionInstructions: 'Ange tränignspassetes instruktioner',
            enterTheInstructions: 'Ange instruktionerna',
            enterExerciseName: 'Ange övningens namn',
            enterOrganizationName: 'Ange organisationsnamn',
            confirmPassword: 'Bekräfta lösenord',
            selectSomeClients: 'Välj några kunder',
            exerciseInstructions: 'Övningsinstruktioner',
            sessionName: 'Träningspass namn',
            enterVerificationCode: 'Ange koden som skickats till din e-post',
          },
          tooltips: {
            profile: 'Profil',
            chat: 'Chatt',
            calendar: 'Kalender',
            chart: 'Diagram',
            deactivate: 'Deaktivera',
            edit: 'Redigera',
            members: 'Medlemmar',
            delete: 'Ta bort',
            aggregation:
              'Månadssegmentering visar medelvärdet av alla värden i var och en av de valda månaderna',
          },
          modals: {
            phrases: {
              areYouSure: 'Är du säker',
              thisClient: 'den här kunden',
              areYouSureDelete: 'Är du säker på att du vill ta bort',
              areYouSureDeleteExercise: 'Är du säker på att du vill ta bort',
              areYouSureDeleteSession: 'Är du säker på att du vill ta bort',
              cancelSubscription:
                'Om du säger upp din prenumeration kommer du inte att kunna använda våra tjänster.',
              cancelSubscriptionConfirm:
                'Är du säker på att du vill avsluta din nuvarande prenumeration? Genom att klicka "Bekräfta" förlorar du åtkomsten till alla våra tjänster i slutet av din nuvarande faktureringsperiod.',
              selectDateToScheduleSession:
                'Välj datum för att schemalägga träningspasset',
              areYouSureDeleteUser: 'Är du säker på att du vill ta bort',
              areYouSureDeleteCard:
                'Är du säker på att du vill ta bort din {{cardBrand}} kort som slutar på {{cardNumber}}?',
              confirmExit:
                'Vill du verkligen avsluta? Alla osparade ändringar kommer att gå förlorade.',
            },
            titles: {
              editTeam: 'Redigera lag',
              createNewTeam: 'Skapa nytt lag',
              deleteTeam: 'Ta bort lag',
              changeTeamPhoto: 'Ändra lagfoto',
              deleteExercise: 'Ta bort övning',
              updateExercise: 'Uppdatera övning',
              createExercise: 'Skapa övning',
              deleteSession: 'Ta bort träningspasset',
              cancelSubscription: 'Avbryt prenumeration',
              confirmSubscriptionCancel: 'Bekräfta prenumeration',
              removeCard: 'Ta bort kort',
              registerNewCard: 'Registrera nytt kort',
              updateSessionDate: 'Uppdatera träningspassets datum',
              deleteClient: 'Ta bort kund',
            },
          },
          validations: {
            teamNameRequired: 'Lagnamn krävs.',
            sportNameRequired: 'Sportnamn krävs.',
            teamMembersRequired: 'Lagmedlemmar krävs.',
            teamAccessCodeRequired: 'Lagåtkomstkod krävs.',
            photoRequired: 'Foto krävs.',
            exerciseNameRequired: 'Övningens namn krävs.',
            youtubeUrlRequired: 'Youtube URL krävs.',
            fullNameRequired: 'Fullständigt namn krävs.',
            nameIsRequired: 'Namn krävs.',
            dateOfBirthRequired: 'Födelsedatum krävs.',
            weightRequired: 'Vikt krävs.',
            heightRequired: 'Längd krävs.',
            emailRequired: 'E-post krävs.',
            codeRequired: 'Kod krävs.',
            invalidEmail: 'Ogiltig e-post.',
            titleRequired: 'Titel krävs.',
            exercisesRequired: 'Håll minst en övning.',
            allTableValuesRequired: 'Alla tabellvärden krävs.',
            noExercisesSelected: 'Inga övningar valda.',
            sameDate: 'Kan inte välja samma datum',
            addAtLeastOneEmail: 'Lägg till minst en e-postadress.',
            inviteEmailsRequired:
              'Använd + -knappen för att lägga till ett e-postmeddelande',
          },
          errors: {
            couldNotSendVerificationCode: 'Kunde inte skicka verifieringskod.',
            somethingWentWrong: 'Något gick fel. Vänligen försök igen senare.',
            creatingSession: 'Något gick fel när du skapade Träningspass.',
            incorrectCode: 'Felaktig verifieringskod.',
            duplicateSession: 'Det gick inte att duplicera sessionen.',
          },
          success: {
            login: 'Inloggning lyckades.',
            exerciseUpdated: 'Övning uppdaterad.',
            userCreated: 'Användare skapad.',
            sessionCreated: 'Träningspass skapad.',
            sessionUpdated: 'Träningspass uppdaterad.',
            sessionDeleted: 'Träningspass borttagen.',
            sessionDuplicated: 'Träningspass duplicerad.',
            sessionDateChanged: 'Träningspass datum ändrad.',
            clientSessionRemoved: 'Kundens träningspass borttagen.',
            codeVerified: 'Kod verifierad.',
            teamCreated: 'Lag skapad.',
            teamUpdated: 'Lag uppdaterad.',
            teamDeleted: 'Lag borttagen.',
            teamPhotoUpdated: 'Lagfoto uppdaterad.',
            profileUpdated: 'Profilen har uppdaterats framgångsrikt.',
            cardSetDefault: 'Kortet har ställts in som standard.',
            cardUpdated: 'Kortet har uppdaterats.',
            cardCreated: 'Kortet har skapats.',
            cardRemoved: 'Kortet har tagits bort.',
            clientUpdated: 'Kund uppdaterad.',
            clientDeleted: 'Kund borttagen.',
            clientJoinRequestSent:
              'Skickade ett e-postmeddelande till kunden med anslutningsbegäran.',
          },
          phrases: {
            areYouSure: 'Är du säker',
            thisClient: 'den här kunden?',
            teamModal: {
              phrase1:
                'Kunder kan ange en åtkomstkod i mobilappen för att gå med i din organisation.',
              phrase2:
                'En kod du skapar kan användas av vem som helst, och det finns ingen gräns eller utgångsdatum.',
              phrase3:
                'Du kan inaktivera åtkomstkoden genom att lämna fältet tomt..',
              phrase4: 'Detta påverkar inte kunder som använt koden tidigare.',
              phrase5:
                'Kunder som använder åtkomstkoder kan räknas mot din plans kundgräns och orsaka att din plan uppgraderas.',
            },
            statistics: {
              chartAvailability:
                'Tabellen är endast tillgänglig för övningar som använder "reps" och "vikt" mätvärden.',
            },
            notifications: {
              emptyNotifications:
                'Inga aviseringar hittades för den valdakunden.',
            },
            card: {
              enterDetails: 'Ange dina kortuppgifter nedan.',
            },
          },
        },
      },
    },
  });

export default i18n;
