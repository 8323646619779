import { HIT_EXERCISES_REQUET, SET_EXERCISES_REQUET } from '../constants';

const intialVal = {
  data: [],
  error: null,
  loading: false,
};
export const exerciseReducer = (state = intialVal, { payload, type }) => {
  switch (type) {
    case HIT_EXERCISES_REQUET: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_EXERCISES_REQUET: {
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    }
    default: {
      return { ...state };
    }
  }
};
