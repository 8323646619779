import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@mui/material';

const FormInput = ({
  register,
  label,
  type,
  placeholder,
  name,
  variant,
  error,
  endAdornment,
  options,
  sx,
  inputSx,
  containerSx,
  lightTheme,
  shadow = true,
  noLabel,
  disabled,
  ...props
}) => (
  <Box sx={containerSx}>
    <FormControl
      sx={{
        width: 1,
        mb: 1,
        '& .MuiFormHelperText-root': { ml: '1px' },
      }}
    >
      {!noLabel && label && (
        <InputLabel
          shrink
          sx={{ color: disabled ? 'disabled.main' : 'white', ml: -1.5 }}
        >
          {label}
        </InputLabel>
      )}

      <Input
        {...(register ? register(name, options) : {})}
        {...props}
        type={type}
        inputProps={{ style: { padding: 10, ...inputSx } }}
        placeholder={placeholder}
        error={!!error}
        endAdornment={endAdornment}
        disableUnderline={true}
        disabled={disabled}
        sx={{
          border: '1px solid #62646e',
          '& ::placeholder': {
            color: lightTheme ? 'black' : 'white !important',
          },
          '& .Mui-disabled': { WebkitTextFillColor: '#71797E !important' },
          color: !lightTheme ? 'white !important' : 'black',
          bgcolor: !lightTheme ? 'background.light' : 'white',
          boxShadow: shadow ? '0px 4px 4px rgb(0 0 0 / 25%)' : 'none',
          borderRadius: 2.5,
          width: 1,
          pr: 1,
          ...sx,
        }}
      />

      {error && (
        <FormHelperText sx={{ color: 'error.main', height: 15 }}>
          {error.message?.toString() || error}
        </FormHelperText>
      )}
    </FormControl>
  </Box>
);

export default FormInput;
