import { combineReducers } from 'redux';
import { session, sessionData, clientSessionData } from './reducers/Sessions';
import { addTeamData } from './reducers/Teams';
import { clientReducer } from './reducers/Clients';
import {
  monthlyPlansReducer,
  yearlyPlanReducers,
} from './reducers/PaymentCards';
import { clientSessionReducer } from './reducers/ClientSession';
import { chatReducer } from './reducers/Chat';
import { notificationReducer } from './reducers/Notification';
import { programReducer } from './reducers/Program';
import { exerciseReducer } from './reducers/Exercises';
import { ClientAssignedSession } from './reducers/ClientAssignedSession';
import { toolReducer } from './reducers/Tool';
import { paymentReducer } from './reducers/PaymentInfo';
import { graphReducer } from './reducers/Graph';
import { sessionExercises } from './reducers/Session_Exercises';
import { AssignedTeamReducer } from './reducers/TeamAssignedSession';
import { teamReducer } from './reducers/Teams';
import { sessionFeedReducer } from './reducers/SessionFeed';
import { bankCardReducer } from './reducers/BankCard';

const ROOT_REDUCERS = combineReducers({
  session: session,
  sessionData: sessionData,
  clientSessionData: clientSessionData,
  addTeamData: addTeamData,
  monthlyPlans: monthlyPlansReducer,
  yearlyPlans: yearlyPlanReducers,
  client: clientReducer,
  clientSession: clientSessionReducer,
  chat: chatReducer,
  notification: notificationReducer,
  program: programReducer,
  exercise: exerciseReducer,
  clientAssignedSession: ClientAssignedSession,
  tool: toolReducer,
  graph: graphReducer,
  session_exercises: sessionExercises,
  teamSessions: AssignedTeamReducer,
  teams: teamReducer,
  sessionFeed: sessionFeedReducer,
  payment: paymentReducer,
  bankCard: bankCardReducer,
});
export default ROOT_REDUCERS;
