import {
  AddOutlined,
  Search,
  BlockOutlined,
  CalendarMonth,
  ChatOutlined,
  HowToReg,
  InsightsOutlined,
  PersonOutlineSharp,
  Edit,
  PeopleAlt,
  CloseSharp,
  Delete,
  PeopleAltOutlined,
  EditOutlined,
} from '@mui/icons-material';
import { Avatar, Badge, Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/shared/FormInput';
import FormSelectInput from '../../components/shared/FormSelectInput';
import MuiButton from '../../components/shared/MuiButton';
import useClientsList from './useTeamList';
import './Team.css';
import SaveTeamModal from './saveTeamModal/SaveTeamModal';
import TeamPhotoModal from './teamPhotoModal/TeamPhotoModal';
import MuiModal from '../../components/shared/MuiModal';
import Chat from '../chat/Chat';
import Card from '../../components/shared/Card';
import { useTranslation } from 'react-i18next';

const TeamList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    orderByOptions,
    onDeleteTeam,
    onCloseConfirmationModal,
    setShowConfirmationModal,
    showConfirmationModal,
    showTeamChat,
    setShowTeamChat,
    trainerId,
    showSaveTeamModal,
    setShowSaveTeamModal,
    showEditPhotoModal,
    setShowEditPhotoModal,
    teamsData,
    setSelectedTeam,
    selectedTeam,
    membersOptions,
    controlOptions,
    registerOptions,
    fetchTeamsWithOptions,
  } = useClientsList();

  const CustomIcon = ({ Icon, sx = {} }) => (
    <Icon
      sx={{
        width: 35,
        height: 35,
        color: 'silver',
        '&:hover': { color: 'white' },
        ...sx,
      }}
    />
  );

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <FormInput
            name="filterByName"
            register={registerOptions}
            placeholder={t('placeholders.searchByTeamName')}
            startAdornment={<Search sx={{ color: 'secondary.main', ml: 2 }} />}
            sx={{ width: 300, mt: 2.5 }}
          />

          <FormSelectInput
            name="orderBy"
            control={controlOptions}
            label={t('texts.orderBy')}
            selectOptions={orderByOptions}
            sx={{ width: 150 }}
          />
        </Stack>
        <Box>
          <MuiButton
            sx={{ mt: 1.8 }}
            onClick={() => setShowSaveTeamModal(true)}
            icon={<AddOutlined />}
          >
            {t('buttons.addTeam')}
          </MuiButton>
        </Box>
      </Stack>
      <Stack>
        {!teamsData?.data?.length ? (
          <>
            <Typography sx={{ color: 'error.main' }}>
              {t('texts.noTeamsFound')}
            </Typography>
            <Typography>{t('texts.noTeamsFoundTip')}</Typography>
          </>
        ) : (
          teamsData?.data?.map((team) => {
            return (
              <Card
                key={team._id}
                title={team.TeamName}
                avatar={team.avatar}
                onAvatarClick={() => {
                  setShowEditPhotoModal(true);
                  setSelectedTeam(team);
                }}
              >
                <div className="images-chart">
                  <div
                    className="tooltip-icons-cstm"
                    onClick={() => {
                      setSelectedTeam(team);
                      setShowSaveTeamModal(true);
                    }}
                  >
                    <CustomIcon Icon={EditOutlined} sx={{ mr: -1 }} />
                    <span className="tooltiptext">{t('tooltips.edit')}</span>
                  </div>

                  <div
                    className="tooltip-icons-cstm"
                    onClick={() => navigate(`/team/calendars/${team?._id}`)}
                  >
                    <CustomIcon Icon={CalendarMonth} />
                    <span className="tooltiptext">
                      {t('tooltips.calendar')}
                    </span>
                  </div>

                  <Badge
                    color="warning"
                    variant={team.newChatMessagesCount > 0 ? 'dot' : 'none'}
                  >
                    <div
                      onClick={() => {
                        setShowTeamChat(true);
                        setSelectedTeam(team);
                      }}
                      className="tooltip-icons-cstm"
                    >
                      <CustomIcon Icon={ChatOutlined} sx={{ mb: -0.5 }} />
                      <span className="tooltiptext">{t('tooltips.chat')}</span>
                    </div>
                  </Badge>

                  <div
                    className="tooltip-icons-cstm"
                    onClick={() => navigate(`/team/${team._id}/members`)}
                  >
                    <CustomIcon Icon={PeopleAltOutlined} />
                    <span className="tooltiptext">{t('tooltips.members')}</span>
                  </div>

                  <div
                    className="tooltip-icons-cstm"
                    onClick={() => {
                      setShowConfirmationModal(true);
                      setSelectedTeam(team);
                    }}
                  >
                    <CustomIcon Icon={CloseSharp} sx={{ ml: -0.7 }} />
                    <span
                      className="tooltiptext"
                      style={{ background: '#ff3333' }}
                    >
                      {t('tooltips.delete')}
                    </span>
                  </div>
                </div>
              </Card>
            );
          })
        )}
      </Stack>

      <Chat
        open={showTeamChat}
        closeTab={() => {
          setSelectedTeam(null);
          setShowTeamChat(false);
          fetchTeamsWithOptions();
        }}
        team={selectedTeam}
      />

      <MuiModal
        title={t('modals.titles.deleteTeam')}
        description={`${t('phrases.areYouSureDelete')} ${
          selectedTeam?.TeamName
        }?`}
        open={showConfirmationModal}
        onClose={onCloseConfirmationModal}
        onSubmit={onDeleteTeam}
        submitBtnText={t('buttons.confirm')}
      />

      <SaveTeamModal
        open={showSaveTeamModal}
        onClose={() => {
          setSelectedTeam(null);
          setShowSaveTeamModal(false);
        }}
        membersOptions={membersOptions}
        trainerId={trainerId}
        team={selectedTeam}
      />
      <TeamPhotoModal
        open={showEditPhotoModal}
        teamId={selectedTeam?._id}
        onClose={() => {
          setShowEditPhotoModal(false);
          setSelectedTeam(null);
        }}
      />
    </Stack>
  );
};

export default TeamList;
