import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getClientsByIds } from '../../redux/actions/Clients';
import { getTeam } from '../../redux/actions/Teams';
import LoaderContext from '../../context/Loader';

const useTeamMembers = () => {
  const { teamId } = useParams();
  const dispatch = useDispatch();

  const loader = useContext(LoaderContext);
  const teamData = useSelector((state) => state.teams);
  const clientsData = useSelector((state) => state.client);
  const isLoading = teamData.loading || clientsData.loading;

  useEffect(() => {
    loader.setLoading(isLoading);
  }, [teamData.loading, clientsData.loading]);

  useEffect(() => {
    dispatch(getTeam(teamId));
  }, []);

  useEffect(() => {
    const membersIds = teamData.selectedTeam?.member;
    if (membersIds) dispatch(getClientsByIds(membersIds));
  }, [teamData.selectedTeam]);

  return {
    team: teamData.selectedTeam,
    members: isLoading ? [] : clientsData?.data || [],
  };
};

export default useTeamMembers;
