import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import './Reactcalendar.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { set_client_session_byDate } from '../../redux/actions/ClientAssignedSession';
import { AuthContext } from '../../App';
import {
  set_allTeam_session,
  set_team_session_by_date,
} from '../../redux/actions/TeamAssignedSession';
import { set_all_client_session } from '../../redux/actions/ClientAssignedSession';
import { useTranslation } from 'react-i18next';

const ReactCalendar = ({
  selectedDate,
  sessionGet,
  clientId,
  teamId,
  setDate,
  onChange,
  newSessionsDates = [],
  removedSessionsDates = [],
  style,
}) => {
  const dispatch = useDispatch();
  const { auth } = useContext(AuthContext);
  const sessionsData = useSelector((s) =>
    teamId ? s.teamSessions : s.clientAssignedSession,
  );

  const [value, setValue] = useState(new Date());
  const [markedDates, setMarkedDate] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const sessions = sessionsData?.allSession;
    const dates = Object.values(sessions)?.map((item) =>
      moment.utc(item.date).format('DD-MM-YYYY'),
    );
    setMarkedDate([...dates]);
  }, [sessionsData]);

  useEffect(() => {
    if (teamId) dispatch(set_allTeam_session(teamId, auth.token));
    else if (clientId) dispatch(set_all_client_session(clientId, auth.token));
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const date = new Date(selectedDate);
      const result = new Date(date.toISOString().slice(0, -1));
      setValue(result);
    }
  }, [selectedDate]);

  const getLanguage = () => {
    const language = i18n.resolvedLanguage;
    if (language === 'en') return 'en';
    else if (language === 'se') return 'sv';
    else return 'en';
  };

  const handler = (e) => {
    if (setDate !== undefined) setDate(e);
    setValue(e);

    if (!!sessionGet) {
      const date = moment.utc(e).format('YYYY-MM-DD');
      if (teamId) dispatch(set_team_session_by_date(teamId, date));
      else if (clientId) dispatch(set_client_session_byDate(clientId, date));
    }
  };

  return (
    <div className="reactcalendar-info" style={style}>
      <Calendar
        onChange={onChange || handler}
        value={value}
        className="react-calendar"
        locale={getLanguage()}
        tileClassName={({ date, view }) => {
          if (
            [...markedDates, ...newSessionsDates]
              .filter((value) => !removedSessionsDates.includes(value))
              .find((x) => x === moment(date).format('DD-MM-YYYY'))
          ) {
            return 'highlights';
          }
        }}
      />
    </div>
  );
};

export default ReactCalendar;
