import { useState, useEffect, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  assignClientSessionApi,
  assignTeamSession,
  deleteAssignedSessionById,
  deleteTeamSession,
  rescheduleSession,
  updateAssignSession,
  update_team_session_date,
} from '../../Service/Api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { set_client_session_byDate } from '../../redux/actions/ClientAssignedSession';
import { set_team_session_by_date } from '../../redux/actions/TeamAssignedSession';
import { AuthContext } from '../../App';
import { useTranslation } from 'react-i18next';

const useActionsModals = ({
  setIsDeleteModalVisible,
  setIsCopyModalVisible,
  setIsMoveModalVisible,
  isCopyModalVisible,
  isMoveModalVisible,
  selectedDateSessions,
  updateSessions,
  sessions,
}) => {
  const { auth } = useContext(AuthContext);
  const { clientId, teamId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [newDate, setNewDate] = useState(
    new Date(selectedDateSessions?.[0]?.date),
  );
  const [selectedSession, setSelectedSession] = useState(
    selectedDateSessions[0],
  );
  const currentClientSessionData = useSelector(
    (state) => state.clientAssignedSession,
  );
  const teamSessions = useSelector((state) => state.teamSessions);

  const { data: sessionsWithExcMetadata } = useMemo(
    () => (teamId ? teamSessions : currentClientSessionData),
    [teamId, clientId, teamSessions, currentClientSessionData],
  );

  const { t } = useTranslation();

  const handleDeleteSession = async () => {
    const sessionId = selectedSession?._id;
    const token = localStorage.getItem('token');

    try {
      setLoading(true);
      const response = await (teamId
        ? deleteTeamSession(sessionId, token)
        : deleteAssignedSessionById(sessionId, token));

      const sessionDate = moment
        .utc(selectedSession.date)
        .format('MM DD YYYY')
        .toString();

      if (response.statusCode === 200) {
        let updatedSessions = { ...sessions };
        const newDateData = sessions[sessionDate].filter(
          ({ _id }) => sessionId !== _id,
        );

        delete updatedSessions[sessionDate];
        if (newDateData.length) {
          updatedSessions[sessionDate] = newDateData;
        } else {
          delete updatedSessions[sessionDate];
        }

        updateSessions(updatedSessions);
        toast.success(response.message || t('success.sessionDeleted'));
      }
    } catch (err) {
      toast.error(err.message || t('errors.somethingWentWrong'));
    } finally {
      setIsDeleteModalVisible(false);
      setLoading(false);
    }
  };

  const isDateInvalid = () => {
    if (moment(new Date(newDate)).isSame(new Date(selectedSession.date))) {
      toast.warn(t('validations.sameDate'));
      return true;
    }
  };

  const handleCopySession = async () => {
    if (isDateInvalid()) return;

    try {
      setLoading(true);
      const date = moment.utc(newDate).format('MM DD YYYY');

      const exercisesFormatted = sessionsWithExcMetadata
        .find(({ _id }) => _id === selectedSession?._id)
        ?.assignedExercises?.map((inner) => {
          return inner.map((e) => {
            return { ...e, ...e.exerciseId };
          });
        });
      const body = {
        ...selectedSession,
        exercises: exercisesFormatted,
        date: new Date(date),
      };
      if (!body.exercises?.length) {
        return;
      }
      delete body._id;
      const response = await (teamId
        ? assignTeamSession(body)
        : assignClientSessionApi(body));

      if (response.statusCode === 200) {
        updateSessions({
          ...sessions,
          [date]: [...(sessions[date] || []), response.data],
        });
        toast.success(response.message || t('success.sessionDuplicated'));
      } else {
        toast.error(response.error || t('errors.duplicateSession'));
      }
    } catch (err) {
      toast.error(err.message || t('errors.duplicateSession'));
    } finally {
      setIsCopyModalVisible(false);
      setLoading(false);
    }
  };

  const handleChangeSessionDate = async () => {
    if (isDateInvalid()) return;

    const sessionId = selectedSession?._id;
    const newDateFormatted = moment.utc(newDate).format('MM DD YYYY');
    const body = { ...selectedSession, date: new Date(newDate) };
    try {
      setLoading(true);
      const response = await (teamId
        ? rescheduleSession(body, sessionId, auth.token)
        : updateAssignSession(body, sessionId, auth.token));

      if (response.statusCode === 200) {
        const updatedSessions = { ...sessions };
        const oldDate = moment.utc(selectedSession.date).format('MM DD YYYY');
        let oldDateSessions = updatedSessions[oldDate];

        if (oldDateSessions.length === 1) {
          delete updatedSessions[oldDate];
          updatedSessions[newDateFormatted] = [
            ...(updatedSessions[newDateFormatted] || []),
            body,
          ];
        } else {
          updatedSessions[oldDate] = oldDateSessions.filter(
            ({ _id }) => _id !== sessionId,
          );
          updatedSessions[newDateFormatted] = [
            ...(updatedSessions[newDateFormatted] || []),
            body,
          ];
        }

        updateSessions(updatedSessions);
        toast.success(response.message || t('success.sessionDateChanged'));
      }
    } catch (err) {
      toast.error(err.message || t('errors.somethingWentWrong'));
    } finally {
      setIsMoveModalVisible(false);
      setLoading(false);
    }
  };

  const fetchData = () => {
    const date = selectedDateSessions[0]?.date;
    if (teamId) dispatch(set_team_session_by_date(teamId, date));
    else if (clientId) dispatch(set_client_session_byDate(clientId, date));
  };

  useEffect(() => {
    fetchData();
  }, [clientId, teamId, isCopyModalVisible]);

  useEffect(() => {
    setNewDate(new Date(selectedDateSessions?.[0]?.date));
  }, [isCopyModalVisible, isMoveModalVisible]);

  useEffect(() => {
    setSelectedSession(selectedDateSessions[0]);
  }, [selectedDateSessions]);

  return {
    handleDeleteSession,
    handleChangeSessionDate,
    handleCopySession,
    setNewDate,
    setSelectedSession,
    selectedSession,
    newDate,
    teamId,
    clientId,
    loading,
  };
};

export default useActionsModals;
