import './LoginPage.css';
import Logo from '../../../components/shared/Logo';
import EmailInput from '../../../components/shared/EmailInput';
import PasswordInput from '../../../components/shared/PasswordInput';
import { Box, Link, Typography } from '@mui/material';
import MuiButton from '../../../components/shared/MuiButton';
import useLoginPage from './useLoginPage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, onSubmit, isLoading, errors } = useLoginPage();

  const navigateToSignUp = () =>
    (window.location = `${process.env.REACT_APP_LANDING_PAGE_URL}/signup`);

  return (
    <div className="login-page inner-page-header main-bg">
      <div className="containers login-bg">
        <div className="forms">
          <div className="form login">
            <Logo />
            <form onSubmit={onSubmit}>
              <EmailInput register={register} error={errors?.email} />

              <Box className="input-field" sx={{ my: '5px !important' }}>
                <label
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 0,
                    zIndex: 10,
                  }}
                >
                  <span onClick={() => navigate('/forgot')}>
                    {t('texts.forgotPasswordQuestion')}
                  </span>
                </label>
              </Box>
              <PasswordInput
                label={t('texts.password').toUpperCase()}
                register={register}
                error={errors.password}
                placeholder="Enter your password"
              />

              <MuiButton
                type="submit"
                isLoading={isLoading}
                sx={{ width: 1, mt: 3 }}
              >
                {t('texts.login')}
              </MuiButton>
            </form>

            <Typography sx={{ textAlign: 'center', mt: 3 }}>
              {t('texts.dontHaveAccount')}

              <Link
                onClick={navigateToSignUp}
                className="text signup-link ml-2"
                sx={{ fontWeight: 400, cursor: 'pointer' }}
              >
                {t('texts.signup')}
              </Link>
            </Typography>
          </div>
        </div>

        <div className="space-40px"></div>
        <div className="space-80px"></div>
      </div>
    </div>
  );
};

export default LoginPage;
