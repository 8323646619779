import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../utils';
import FormInput from './FormInput';

const EmailInput = ({ register, label, error, ...props }) => {
  const { t } = useTranslation();
  return (
    <FormInput
      name="email"
      label={label || t('texts.email').toUpperCase()}
      placeholder={t('placeholders.enterEmail')}
      register={register}
      options={{
        required: t('validations.emailRequired'),
        validate: (value) =>
          validateEmail(value) ? true : t('validations.invalidEmail'),
      }}
      error={error}
      {...props}
    />
  );
};

export default EmailInput;
