import {
  HIT_PROGRAM_REQUEST,
  SET_PROGRAM_DATA,
  FAILED_PROGRAM_REQUEST,
  UPDATE_PROGRAM_REQUEST,
  HIT_UPDATE_REQUEST,
} from '../constants';
import { toast } from 'react-toastify';
import {
  programGetById,
  updateProgram,
  updateProgramWeek,
} from '../../Service/Api';

export const setProgram =
  (id, Token = null) =>
  async (dispatch) => {
    dispatch({
      type: HIT_PROGRAM_REQUEST,
    });
    try {
      const token = localStorage.getItem('token');
      const response = await programGetById(id, Token ?? token);

      if (response.statusCode === 200)
        dispatch({ type: SET_PROGRAM_DATA, payload: { data: response.data } });
      else throw response;
    } catch (err) {
      dispatch({
        type: FAILED_PROGRAM_REQUEST,
        payload: {
          error: err?.error || err?.message || 'Something went wrong',
        },
      });
    }
  };

export const updateProgramAction = (id, body) => async (dispatch) => {
  dispatch({
    type: HIT_UPDATE_REQUEST,
  });

  try {
    const token = localStorage.getItem('token');
    const response = await updateProgram(id, token, body);

    if (response.statusCode === 200) {
      dispatch({
        type: UPDATE_PROGRAM_REQUEST,
        payload: {
          data: response.data,
        },
      });
      toast.success('Update Sccessfully');
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_PROGRAM_REQUEST,
      payload: {
        error: err?.message ?? err?.error ?? 'Something went Wrong .',
      },
    });
  }
};

export const updateProgramWeekAction =
  (id, weekId, body) => async (dispatch) => {
    dispatch({ type: HIT_UPDATE_REQUEST });
    try {
      const token = localStorage.getItem('token');
      const response = await updateProgramWeek(id, weekId, body, token);
      // alert(JSON.stringify(response.data));
      if (response.statusCode === 200) {
        dispatch({
          type: UPDATE_PROGRAM_REQUEST,
          payload: {
            data: response.data,
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      alert(JSON.stringify(err?.message ?? err?.error));
      dispatch({
        type: FAILED_PROGRAM_REQUEST,
        payload: {
          error: err?.message ?? err?.error ?? 'Something went Wrong.',
        },
      });
    }
  };
