import { Stack, Typography } from '@mui/material';
import MuiModal from '../../../components/shared/MuiModal';
import MuiButton from '../../../components/shared/MuiButton';
import MuiTable from '../../../components/shared/MuiTable';
import { TrainerProfileNavMenu } from '../../trainer-profile/TrainerProfile';
import Title from '../../../components/shared/Title';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import useSubscription from './useSubscription';
import { getStorageParsed, getStorage, setStorage } from '../../../utils';
import { getUserById } from '../../../Service/Api';
import { useTranslation } from 'react-i18next';

const Subscription = () => {
  const {
    prices,
    getNumberOfActiveClients,
    cancelCurrentSubscription,
    isCancellingSubscription,
    cancelSubscriptionModal,
    setCancelSubscriptionModal,
    cancelSubscriptionConfirmationModal,
    setCancelSubscriptionConfirmationModal,
    subscriptionStatus,
  } = useSubscription();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const priceId = auth.priceId;

  useEffect(() => {
    refreshUserData();
  }, []);

  const refreshUserData = async () => {
    const user = getStorageParsed('auth');
    if (!user) throw 'User detail missing.';
    const token = getStorage('token');
    const response = await getUserById(user?._id, token);

    if (response.statusCode === 200) {
      setStorage('auth', response.data);
      localStorage.setItem('token', response.data.token);
      setAuth(response.data);
    } else {
      throw response;
    }
  };

  const getSubscriptionStatusLabel = () => {
    switch (auth.subscriptionStatus) {
      case 'INCOMPLETE':
        return t('texts.waitingPaymentConfirmation');
      case 'ACTIVE':
        return t('texts.activeSubscription');
      case 'NOT_ACTIVE':
        return t('texts.noActiveSubscription');
      case 'TRIAL_PERIOD':
        return t('texts.trialPeriod');
      case 'CANCEL_REQUESTED':
        return t('texts.cancelRequested');
      default:
        return t('texts.invalidSubscription');
    }
  };

  return (
    <Stack>
      <TrainerProfileNavMenu
        page="subscription"
        subscriptionStatus={subscriptionStatus}
      />

      <Stack className="boxes" sx={{ m: 0, mt: 1.5 }}>
        <Title>{t('texts.subscriptionStatus')}:</Title>
        <Typography>{getSubscriptionStatusLabel()}</Typography>

        {auth.subscriptionStatus !== 'INCOMPLETE' && (
          <>
            <Stack width={1} maxWidth={850} spacing={5} marginTop={10}>
              <Stack
                direction="row"
                justifyContent="space-between"
                className="lable-h3"
                width={1}
              >
                <Stack
                  direction="row"
                  alignItems="end"
                  spacing={1.5}
                  sx={{ '& > *': { fontSize: 30 } }}
                >
                  <Title>{t('texts.attachedClients')}:</Title>
                  <Typography>{getNumberOfActiveClients()}</Typography>
                </Stack>
                <div>
                  <MuiButton onClick={() => navigate('/client')}>
                    {t('texts.manageClients')}
                  </MuiButton>
                </div>
              </Stack>

              {!!prices.length && (
                <MuiTable
                  headers={[t('texts.numberOfClients'), t('texts.price')]}
                  selectedRowIdx={prices.findIndex(
                    (price) => priceId === price.id,
                  )}
                  rows={prices.map((price) => [
                    `${t('texts.upTo')} ${price.clientCount} ${t(
                      'texts.clients',
                    )}`,
                    `$ ${parseFloat(price.amount).toFixed(2)}`,
                  ])}
                  page={0}
                  pageSize={10}
                />
              )}
            </Stack>

            <Typography
              sx={{
                fontSize: 18,
                color: 'primary.main',
                pt: 5,
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': { color: 'primary' },
              }}
              onClick={() => navigate('/plans')}
            >
              {t('texts.selectPlan')}
            </Typography>

            <Typography
              sx={{
                fontSize: 18,
                color: '#ff3333',
                pt: 5,
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': { color: 'red' },
              }}
              onClick={() => setCancelSubscriptionModal(true)}
            >
              {t('texts.cancelPlan')}
            </Typography>
          </>
        )}
      </Stack>

      <MuiModal
        title={t('modals.titles.cancelSubscription')}
        description={t('modals.phrases.cancelSubscription')}
        submitBtnText={t('buttons.next')}
        sx={{ width: 550 }}
        open={cancelSubscriptionModal}
        onSubmit={() => {
          setCancelSubscriptionModal(false);
          setCancelSubscriptionConfirmationModal(true);
        }}
        onClose={() => setCancelSubscriptionModal(false)}
      />

      <MuiModal
        title={t('modals.titles.confirmSubscriptionCancel')}
        description={t('modals.phrases.cancelSubscriptionConfirm')}
        submitBtnText={t('buttons.confirm')}
        closeBtnText={t('buttons.cancel')}
        sx={{ width: 550 }}
        open={cancelSubscriptionConfirmationModal}
        isLoading={isCancellingSubscription}
        onSubmit={cancelCurrentSubscription}
        onClose={() => setCancelSubscriptionConfirmationModal(false)}
      />
    </Stack>
  );
};

export default Subscription;
