import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  assignClientSessionApi,
  assignTeamSession,
  deleteAssignedSessionById,
  deleteTeamSession,
  getAssignedClientSession,
  team_assigned_session,
} from '../../Service/Api';
import LoaderContext from '../../context/Loader';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './SessionsCalendar.css';
import { getTeams } from '../../redux/actions/Teams';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../../redux/actions/Clients';
import { AuthContext } from '../../App';
import { toast } from 'react-toastify';

const useSessionsCalendar = () => {
  const dispatch = useDispatch();
  const loader = useContext(LoaderContext);
  const { auth } = useContext(AuthContext);
  const { clientId, teamId } = useParams();

  const navigate = useNavigate();
  const today = moment.utc().format('MM DD YYYY');

  const { data: teamsData, loading: loadingTeams } = useSelector(
    (s) => s.teams,
  );
  const { data: clientsData, loading: loadingClients } = useSelector(
    (s) => s.client,
  );

  const [sessions, setSessions] = useState();
  const [selectedDateSessions, setSelectedDateSessions] = useState();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);

  const formattedSessions = (sessions) => {
    return sessions.reduce((result, session) => {
      const key = moment.utc(session.date).format('MM DD YYYY').toString();
      return {
        ...result,
        [key]: result[key] ? [...result[key], session] : [session],
      };
    }, {});
  };

  const getTeamSessions = async () => {
    try {
      const response = await team_assigned_session(teamId, auth.token);
      if (response.statusCode === 200) {
        setSessions(formattedSessions(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getClientSessions = async () => {
    try {
      const response = await getAssignedClientSession(auth.token, clientId);
      if (response.statusCode === 200) {
        setSessions(formattedSessions(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadData = async () => {
    loader.setLoading(true);
    if (teamId) {
      dispatch(getTeams(auth._id));
      await getTeamSessions();
    } else if (clientId) {
      dispatch(getClients(auth._id));
      await getClientSessions();
    }
    loader.setLoading(false);
  };

  const handleSelectedEvent = (e) => {
    const date = moment(e.date).format('YYYY-MM-DD');
    const path = `/${teamId ? 'team' : 'client'}/${
      teamId || clientId
    }/session/${date}`;
    navigate(path);
  };

  useEffect(() => {
    if (loadingTeams || loadingClients) {
      loader.setLoading(true);
    } else {
      loader.setLoading(false);
    }
  }, [loader, loadingTeams, loadingClients]);

  useEffect(() => {
    loadData();
  }, []);

  return {
    selectedDateSessions,
    setSelectedDateSessions,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    isCopyModalVisible,
    setIsCopyModalVisible,
    isMoveModalVisible,
    setIsMoveModalVisible,
    handleSelectedEvent,
    updateSessions: (data) => {
      setSessions(undefined);
      setSessions(data);
    },
    today,
    teamsData,
    clientsData,
    sessions,
    teamId,
    clientId,
  };
};

export default useSessionsCalendar;
