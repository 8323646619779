import MuiButton from '../../../components/shared/MuiButton';
import { Stack } from '@mui/material';
import PasswordInput from '../../../components/shared/PasswordInput';
import useChangePassword from './useChangePassword';
import Logo from '../../../components/shared/Logo';
import { t } from 'i18next';

const ChangePassword = () => {
  const { register, onSubmit, getValues, isLoading, errors } =
    useChangePassword();

  return (
    <div className="login-page inner-page-header">
      <div className="containers">
        <div className="forms">
          <div className="form otpverify">
            <Logo />

            <form onSubmit={onSubmit}>
              <Stack spacing={3} mt={5}>
                <PasswordInput
                  register={register}
                  error={errors?.password}
                  label="NEW PASSWORD"
                  placeholder={t('placeholders.enterNewPassword')}
                />
                <PasswordInput
                  register={register}
                  error={errors?.passwordConfirmation}
                  name="passwordConfirmation"
                  label="CONFIRM PASSWORD"
                  placeholder="Enter your password again"
                  options={{
                    validate: (value) =>
                      value === getValues('password')
                        ? true
                        : "Passwords don't match",
                  }}
                />
              </Stack>

              <Stack spacing={2} alignItems="center" sx={{ mt: 3 }}>
                <MuiButton
                  sx={{ width: 1 }}
                  type="submit"
                  isLoading={isLoading}
                  fullWidth
                >
                  Submit
                </MuiButton>
              </Stack>
            </form>
          </div>
        </div>

        <div className="space-40px"></div>
        <div className="space-80px"></div>
      </div>
    </div>
  );
};

export default ChangePassword;
