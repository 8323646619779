import React from 'react';
import './LandingPage.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import Navbar from '../page/header/Navbar';
import Footer from '../page/header/Footer';
import LoginPage from '../page/loginpage/LoginPage';
import ForgotPassword from '../page/forgot/ForgotPassword';
import OTPVerification from '../page/otp/OTPVerification';
import ChangePassword from '../page/changepassword/ChangePassword';

function LandingPage() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/otp" element={<OTPVerification />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default LandingPage;
