import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Connectivity from './customComponents/Connectivity';

import './i18n';

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Connectivity>
      <App />
    </Connectivity>
  </BrowserRouter>,
  // </React.StrictMode>,.
  document.getElementById('root'),
);
reportWebVitals();
