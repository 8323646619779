import {
  HIT_NOTIFICATION_REQUEST,
  SET_NOTIFICATION_REQUEST,
  FAILED_NOTFICATION_REQUEST,
  NEW_NOTIFICATION_REQUEST,
  CHECKED_NOTIFICATION_REQUEST,
  CHECKED_CHAT_MESSAGE_REQUEST,
} from '../constants';
import { getNotifications, markNotificationsAsRead } from '../../Service/Api';
import i18n from '../../i18n';

export const setNotifications = (page = 0, clientId) => async (dispatch) => {
  // ? disptching initial

  dispatch({ type: HIT_NOTIFICATION_REQUEST });

  try {
    const response = await getNotifications(page, clientId);
    if (response.statusCode === 200) {
      dispatch({
        type: SET_NOTIFICATION_REQUEST,
        payload: { data: response.data, page },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_NOTFICATION_REQUEST,
      payload: {
        error: err?.error || err?.message || i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const readNotiAction = () => async (dispatch) => {
  await markNotificationsAsRead();
  dispatch({ type: CHECKED_NOTIFICATION_REQUEST });
};

export const newNotiAction = () => (dispatch) => {
  dispatch({ type: NEW_NOTIFICATION_REQUEST });
};
