import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AuthContext } from '../../App';
import { newNotiAction } from '../../redux/actions/Notification';
import { customFetch, getUnreadNotificationsCount } from '../../Service/Api';
import { useTranslation } from 'react-i18next';

const useNavBar = () => {
  const dispatch = useDispatch();
  const { auth, setAuth } = useContext(AuthContext);

  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getNotificationCount();
  }, []);

  const getNotificationCount = async () => {
    const response = await getUnreadNotificationsCount();
    if (response?.data > 0) {
      dispatch(newNotiAction());
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const ProgramProfileUpload = handleSubmit(async ({ profilePhoto }, e) => {
    e.preventDefault();
    setLoading(true);

    const items = JSON.parse(localStorage.getItem('auth'));
    let id = items._id;

    try {
      let formData = new FormData();
      formData.append('image', profilePhoto[0]);

      const response = await customFetch(
        `${process.env.REACT_APP_API_URL}/api/users/uploadProfilePic/${id}`,
        {
          method: 'PUT',
          body: formData,
          headers: {
            'x-access-token': localStorage.getItem('token'),
          },
        },
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        const newAuth = { ...auth, avatar: data.data.avatar };
        localStorage.setItem('auth', JSON.stringify(newAuth));
        setAuth(newAuth);
        toast.success(data.message);
        setProfilePhotoModal(false);
      }
    } catch (err) {
      toast.error(t('errors.somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  });

  return {
    ProgramProfileUpload,
    profilePhotoModal,
    setProfilePhotoModal,
    register,
    errors,
    isLoading,
    currentUser: auth,
  };
};

export default useNavBar;
