import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateTeamPhoto } from '../../../redux/actions/Teams';

const useTeamPhotoModal = (teamId, onClose) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const updatePhoto = handleSubmit(async (data, e) => {
    e.preventDefault();

    const teamPhotoForm = new FormData();
    teamPhotoForm.append('image', data.avatar[0]);
    dispatch(updateTeamPhoto(teamPhotoForm, teamId, onClose));
  });

  return { register, errors, onSubmit: updatePhoto };
};

export default useTeamPhotoModal;
