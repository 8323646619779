import React, { useEffect, useState } from 'react';
import './stripe.css';
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  Elements,
} from '@stripe/react-stripe-js';
import { stripePromise } from './utils';
import { toast } from 'react-toastify';
import {
  createPaymentIntent,
  getAllPrices,
  getStripeCoupon,
  getUserById,
  subscriptionCreate,
} from '../Service/Api';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack, Typography } from '@mui/material';
import FormInput from '../components/shared/FormInput';
import MuiButton from '../components/shared/MuiButton';

import './stripe.css';

const Content = () => {
  const { t } = useTranslation();

  const [isSubmiting, setSubmiting] = useState(false);
  const [isApplyingCoupon, setApplyingCoupon] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [prices, setPrices] = useState(null);
  const [couponInput, setCouponInput] = useState(null);
  const [couponValue, setCouponValue] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();

  useEffect(() => {
    getPrices();
  }, []);

  const getPrices = async () => {
    const token = localStorage.getItem('token');
    const response = await getAllPrices(token);
    if (response && response.statusCode === 200) {
      setPrices(response.data);
    }
  };

  useEffect(() => {
    if (prices?.length > 0) {
      const price = prices?.filter((price) => price.id === params.price)[0];
      setSelectedPrice(price);
    }
  }, [prices]);

  /**
   * * create stripe card token
   * * from card element
   */
  const createStripeSubscription = async (e) => {
    e.preventDefault();
    setSubmiting(true);

    try {
      const addressElement = elements.getElement('address');
      const { value } = await addressElement.getValue();
      const shipping = {
        ...value,
      };
      const result = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });
      if (result?.error) throw result.error;

      const { _id } = JSON.parse(localStorage.getItem('auth'));
      const subscriptionRes = await subscriptionCreate({
        userId: _id,
        priceId: params.price,
        coupon: couponValue?.id,
        paymentMethod: result.setupIntent.payment_method,
        shipping,
      });
      if (subscriptionRes.status === 400) throw subscriptionRes;

      const token = localStorage.getItem('token');
      const user = await getUserById(_id, token);

      if (user.data.subscriptionStatus.toUpperCase() !== 'ACTIVE') {
        user.data.subscriptionStatus = 'CONFIRMING';
      }

      localStorage.clear();
      localStorage.setItem('token', user.data.token);
      localStorage.setItem('auth', JSON.stringify(user.data));
      window.location.href = '/payment/confirmation';
    } catch (err) {
      console.log('ERRR', err);
      toast.error(err?.message || err?.error);
    } finally {
      setSubmiting(false);
    }
  };

  const getDiscountValue = () => {
    if (!couponValue) return undefined;
    let discount;
    if (couponValue.percent_off) {
      discount = (couponValue.percent_off / 100) * selectedPrice?.amount;
    } else {
      discount = couponValue.amount_off / 100;
    }
    return formatMoney(discount);
  };

  const onApplyCoupon = async () => {
    try {
      setApplyingCoupon(true);
      const res = await getStripeCoupon(couponInput);
      if (res.error || !res.data?.valid) throw new Error();
      setCouponValue(res.data);
    } catch (err) {
      toast.error('Invalid cupom');
    } finally {
      setApplyingCoupon(false);
    }
  };

  const formatMoney = (value) => `$ ${value}`;

  const getTotalPrice = () => {
    const packagePrice = selectedPrice?.amount;
    if (!packagePrice) return '-';
    const discount_perc = couponValue?.percent_off;
    const amount_off = couponValue?.amount_off;

    if (!discount_perc && !amount_off) return formatMoney(packagePrice);
    if (discount_perc) {
      const percentageAfterDiscount = 1 - discount_perc / 100;
      return formatMoney(packagePrice * percentageAfterDiscount);
    } else {
      console.log(packagePrice);
      const amountOffFormatted = amount_off / 100;
      return formatMoney(packagePrice - amountOffFormatted);
    }
  };

  return (
    <React.Fragment>
      <Box className="containers">
        <h2 style={{ textAlign: 'center' }}>{t('texts.paySubscription')}</h2>
        <h4 style={{ textAlign: 'center' }}>{t('texts.enjoyJourney')}</h4>
        <p style={{ textAlign: 'center', marginBottom: '50px' }}>
          {t('phrases.card.enterDetails')}
        </p>

        <form
          className="stripe-form-payment"
          onSubmit={createStripeSubscription}
          style={{ paddingBottom: 60 }}
        >
          <br />
          <Stack
            className="stripe-form"
            justifyContent={'center'}
            gap={5}
            key={selectedPrice?._id}
            direction="row"
          >
            <div>
              <Box
                className="stripe-form-card"
                sx={{ border: '1px solid white', flex: 2 }}
              >
                <Typography fontSize={25} mb={2}>
                  Billing Information:
                </Typography>
                <Box className="input-field">
                  <AddressElement
                    options={{
                      mode: 'billing',
                    }}
                  />
                </Box>
              </Box>
            </div>

            <div>
              <Box
                className="stripe-form-card"
                sx={{ border: '1px solid white', flex: 2 }}
              >
                <Typography fontSize={25} mb={2}>
                  {t('texts.creditCardInformation')}:
                </Typography>
                <Box className="input-field">
                  <Box>
                    <PaymentElement />
                  </Box>
                </Box>
              </Box>
            </div>
            <Stack
              className="stripe-form-card"
              sx={{
                border: '1px solid white',
                height: '100%',
              }}
            >
              <Stack direction="row" spacing={1.5}>
                <Box sx={{ width: 1 }}>
                  <label>{t('texts.coupon')}</label>
                  <Box>
                    <FormInput
                      value={couponInput}
                      containerSx={{ width: 1 }}
                      sx={{ height: 40 }}
                      onChange={({ target: { value } }) =>
                        setCouponInput(value)
                      }
                      lightTheme
                    />
                  </Box>
                </Box>

                <MuiButton
                  sx={{
                    mt: 3.8,
                    bgcolor: 'success.main',
                    borderColor: 'success.main',
                    '&:hover': {
                      bgcolor: 'success.hover',
                    },
                  }}
                  disabled={!couponInput}
                  onClick={onApplyCoupon}
                  isLoading={isApplyingCoupon}
                >
                  {t('buttons.apply')}
                </MuiButton>
              </Stack>

              <Divider sx={{ my: 2 }} />
              <Typography fontSize={25} mb={2}>
                {t('texts.orderSummary')}:
              </Typography>

              <Stack spacing={1.2}>
                {selectedPrice?.amount ? (
                  <Stack direction="row" justifyContent="space-between">
                    <div>
                      <Typography sx={{ color: 'rgba(255,255,255,0.75)' }}>
                        {selectedPrice?.name}
                      </Typography>
                      <span className="txt-month" style={{ fontSize: 12 }}>
                        {t('texts.upTo')} {selectedPrice?.clientCount}{' '}
                        {t('texts.users')}
                      </span>
                    </div>

                    <Typography sx={{ color: 'rgba(255,255,255,0.75)' }}>
                      {formatMoney(selectedPrice?.amount)} / {t('texts.month')}
                    </Typography>
                  </Stack>
                ) : null}

                {couponValue ? (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ color: 'success.main' }}>
                      {t('texts.coupon')}
                    </Typography>
                    <Typography sx={{ color: 'success.main' }}>
                      - {getDiscountValue()}
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>

              <Divider component="div" sx={{ my: 1 }} />

              <Stack direction="row" justifyContent="space-between" mb={2.5}>
                <Typography fontSize={25} fontWeight={600}>
                  {t('texts.total')}
                </Typography>
                <Typography fontSize={25} fontWeight={600}>
                  {getTotalPrice()}
                </Typography>
              </Stack>

              <MuiButton
                type="submit"
                sx={{ width: 1 }}
                isLoading={isSubmiting}
                disabled={isSubmiting ? true : false}
              >
                {isSubmiting ? t('buttons.submiting') : t('buttons.submit')}
              </MuiButton>
            </Stack>
          </Stack>
        </form>
      </Box>
    </React.Fragment>
  );
};

const PaymentForm = () => {
  const [options, setOptions] = useState(null);

  const handlePaymentIntent = async () => {
    const response = await createPaymentIntent();
    setOptions({
      clientSecret: response.data.clientSecret,
      appearance: {
        theme: 'night',
        variables: {
          marginTop: 5,
        },
      },
    });
  };

  useEffect(() => {
    handlePaymentIntent();
  }, []);

  return (
    <React.Fragment>
      {options && (
        <Elements stripe={stripePromise} options={options}>
          <Content />
        </Elements>
      )}
    </React.Fragment>
  );
};

export default PaymentForm;
