import { Download } from '@mui/icons-material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MuiButton from '../../../components/shared/MuiButton';
import {
  set_bank_card,
  set_default_card,
} from '../../../redux/actions/BankCard';
import { setPaymentInfo } from '../../../redux/actions/PaymentInfo';
import { createBankCard, deleteBankCard } from '../../../Service/Api';
import LoaderContext from '../../../context/Loader';
import { AuthContext } from '../../../App';
import { useTranslation } from 'react-i18next';

const usePaymentInfo = () => {
  const dispatch = useDispatch();

  const payment = useSelector((e) => e.payment);
  const bankCards = useSelector((s) => s.bankCard);

  const loader = useContext(LoaderContext);
  const [isLoading, setLoading] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [cardToBeRemoved, setCardToBeRemoved] = useState(false);
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setPaymentInfo());
    dispatch(set_bank_card());
  }, []);

  useEffect(() => {
    loader.setLoading(payment.loading || bankCards.loading);
  }, [payment.loading, bankCards.loading]);

  const getCardBrandImage = (brand = 'MasterCard') => {
    switch (brand) {
      case 'MasterCard':
        return <img alt={brand} src="assets/payment-info-img.png" />;
      case 'Visa':
        return <img alt={brand} src="assets/visa.jpeg" />;
      case 'American Express':
        return <img alt={brand} src="assets/american-express.png" />;
      case 'UnionPay':
        return <img alt={brand} src="assets/union-pay.png" />;
      default:
        return '';
    }
  };

  const onCreateCard = async (stripeToken) => {
    try {
      setLoading(true);

      const body = {
        userId: auth._id,
        stripeToken,
      };
      const response = await createBankCard(body);

      if (response && response.statusCode === 200) {
        toast.success(response?.message || t('success.cardRegistered'));
        dispatch(set_bank_card());
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(err?.error ?? err?.message ?? t('errors.somethingWentWrong'));
    } finally {
      setLoading(false);
      setShowAddCardModal(false);
    }
  };

  const setDefault = async (card, x) => {
    try {
      setLoading(true);
      const body = {
        cardId: card.id,
      };
      dispatch(set_default_card(body));
    } catch (err) {
      toast.error(err?.error ?? err?.message ?? t('errors.somethingWentWrong'));
    } finally {
      setLoading(false);
      setShowAddCardModal(false);
    }
  };

  const onRemoveCard = async () => {
    try {
      setLoading(true);
      const response = await deleteBankCard(cardToBeRemoved.id);

      if (response && response.statusCode === 200) {
        dispatch(set_bank_card());
        toast.success(response?.message ?? t('success.cardRemoved'));
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        err?.error || err?.messsage || t('errors.somethingWentWrong'),
      );
    } finally {
      setLoading(false);
      setCardToBeRemoved(false);
    }
  };

  const getPaymentsTableData = useCallback(() => {
    return payment.data.map((payment) => [
      `$ ${parseFloat(payment.amountPaid).toFixed(2)}`,
      <MuiButton
        href={payment.payreceipt}
        icon={<Download />}
        target="_blank"
        transparent
      >
        Download
      </MuiButton>,
    ]);
  }, [payment.data]);

  return {
    isLoading,
    showAddCardModal,
    setShowAddCardModal,
    onRemoveCard,
    onCreateCard,
    paymentsTableData: getPaymentsTableData(),
    bankCards,
    cardToBeRemoved,
    setCardToBeRemoved,
    setDefaultCard: setDefault,
    getCardBrandImage,
    subscriptionStatus: auth.subscriptionStatus,
  };
};

export default usePaymentInfo;
