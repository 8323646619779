import {
  HIT_SESSION_REQUEST,
  SET_SESSION_DATA,
  FAILED_SESSION_DATA,
  SET_EMPTY_STATE,
  SET_ORDER_OF_EXERCISES,
  RESET_DATA,
} from '../constants';
const initialValue = {};
export const session = (state = initialValue, { type, payload }) => {
  switch (type) {
    case 'CREATE_SESSION': {
      return {
        state: {
          data: payload.body,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const sessionInitialValue = {
  data: null,
  loading: false,
  error: null,
};

export const sessionData = (state = sessionInitialValue, { type, payload }) => {
  switch (type) {
    case HIT_SESSION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_SESSION_DATA: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }

    case FAILED_SESSION_DATA: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case SET_EMPTY_STATE: {
      return { ...state, data: null };
    }

    case SET_ORDER_OF_EXERCISES: {
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    }

    case RESET_DATA: {
      return {
        ...state,
        data: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const clientSessionInitialValue = {
  _id: '',
  userId: '',
  trainerId: '',
  sessionName: '',
  instructions: '',
  exercises: [],
  date: new Date(),
};

export const clientSessionData = (
  state = clientSessionInitialValue,
  { type, payload },
) => {
  switch (type) {
    case 'SET_CLIENT_SESSION': {
      return {
        state: {
          data: payload.data,
        },
      };
    }
    default: {
      return {
        state: {
          data: state,
        },
      };
    }
  }
};

// const sessionReducer = (state, { type, paylod }) => {
//   switch (type) {
//     case
//   }
// };
