import MuiModal from '../../../components/shared/MuiModal';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PaymentCardModal = ({ open, onSubmit, onClose, isLoading }) => {
  const [isSubmiting, setSubmiting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmitCard = (e) => {
    e.preventDefault();
    addCard();
  };

  const addCard = async () => {
    setSubmiting(true);

    const cardElement = elements.getElement(CardNumberElement);
    try {
      const result = await stripe.createToken(cardElement);
      if (result?.error) throw result.error;

      await onSubmit(result?.token.id);
      setSubmiting(false);
      onClose();
    } catch (err) {
      setSubmiting(false);
      toast.error(err?.message || err?.error);
    }
  };

  return (
    <MuiModal
      title={t('modals.titles.registerNewCard')}
      submitBtnText={isSubmiting ? t('buttons.submiting') : t('buttons.submit')}
      open={open}
      onSubmit={handleSubmitCard}
      onClose={onClose}
      hideCloseBtn={true}
      isLoading={isLoading}
      disableSubmitBtn={isSubmiting}
    >
      <div className="forms">
        <form className="stripe-form-payment" onSubmit={handleSubmitCard}>
          <div className="input-field">
            <label>{t('texts.cardNumber')}</label>
            <div>
              <CardNumberElement />
            </div>
          </div>
          <div className="input-field">
            <label>{t('texts.expiryDate')}</label>
            <div>
              <CardExpiryElement />
            </div>
          </div>
          <div className="input-field">
            <label>{t('texts.cvv')}</label>
            <div>
              <CardCvcElement />
            </div>
          </div>
        </form>
      </div>
    </MuiModal>
  );
};

export default PaymentCardModal;
