import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { customFetch } from '../../../Service/Api';
import { useTranslation } from 'react-i18next';

const useForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data, event) => {
    event.preventDefault();
    setIsLoading(true);

    customFetch(`${process.env.REACT_APP_API_URL}/api/users/forgotPassword`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((resdata) => {
        if (resdata.statusCode === 200) {
          localStorage.setItem('token', resdata.data.token);
          toast.success(t('texts.verificationCodeSentToEmail'));
          navigate('/otp');
        } else {
          toast.error(
            resdata.error || t('errors.couldNotSendVerificationCode'),
          );
        }
      })
      .catch((err) => {
        toast.error(err.error || t('errors.somethingWentWrong'));
      })
      .finally(() => setIsLoading(false));
  });

  return {
    register,
    onSubmit,
    isLoading,
    errors,
  };
};

export default useForgotPassword;
