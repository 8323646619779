import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { customFetch } from '../../Service/Api';
import { useTranslation } from 'react-i18next';

const useSaveExercise = ({ onClose, exercise, updateExercises, open }) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ExerciseName: exercise?.ExerciseName,
      Instructions: exercise?.Instructions,
      YoutubeUrl: exercise?.YoutubeUrl,
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (exercise) {
      setValue('ExerciseName', exercise.ExerciseName);
      setValue('Instructions', exercise.Instructions);
      setValue('YoutubeUrl', exercise.YoutubeUrl);
    } else {
      reset();
    }
  }, [exercise, open]);

  const createExercise = ({ ExerciseName, YoutubeUrl, Instructions }) => {
    const items = JSON.parse(localStorage.getItem('auth'));
    let trainerId = items._id;
    let body = {
      trainerId: trainerId,
      ExerciseName,
      YoutubeUrl,
      Instructions,
    };

    const token = localStorage.getItem('token');
    setLoading(true);
    customFetch(`${process.env.REACT_APP_API_URL}/api/exercises/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    })
      .then((res) => res.json())
      .then((resdata) => {
        if (resdata.statusCode === 200) {
          updateExercises(resdata.data, true);
          toast.success(resdata.message);
          onClose();
        } else {
          toast.error(resdata.error);
        }
      })
      .finally((_) => setLoading(false));
  };

  const updateExercise = ({ ExerciseName, YoutubeUrl, Instructions }) => {
    setLoading(true);
    let token = localStorage.getItem('token');
    customFetch(
      `${process.env.REACT_APP_API_URL}/api/exercises/${exercise?._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ ExerciseName, YoutubeUrl, Instructions }),
      },
    )
      .then((res) => res.json())
      .then((resdata) => {
        if (resdata.statusCode === 200) {
          updateExercises(resdata.data);
          toast.success(t('success.exerciseUpdated'));
          onClose();
        } else {
          toast.error(resdata.error);
        }
      })
      .catch((er) => {
        toast.error(t('errors.somethingWentWrong'));
      })
      .finally((_) => setLoading(false));
  };

  const submit = handleSubmit(async (data) => {
    exercise ? updateExercise(data) : createExercise(data);
  });

  return {
    register,
    submit,
    errors,
    loading,
  };
};

export default useSaveExercise;
