import { Stack } from '@mui/material';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import './Loader.css';
import { useTranslation } from 'react-i18next';

export default function Loader() {
  const { t } = useTranslation();
  return (
    <div className="loader-container">
      <Stack spacing={2} alignItems="center">
        <ScaleLoader color="#32B5FF" />
        <h4>{t('texts.loading')}</h4>
      </Stack>
    </div>
  );
}
