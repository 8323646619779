import {
  HIT_ASSIGNTEAMSESSIONS_REQUEST,
  SET_ASSIGNED_TEAM_SESSION,
  FAIL_ASSIGNED_TEAM_SESSION_REQUEST,
  SET_ALL_ASSIGNED_SESSION,
  RESET_TEAM_SESSION_ACTION,
  SET_TEAM_SIMPLE_SESSION,
  UPDATE_TEAM_SESSION_DATE,
} from '../constants';

const init = {
  data: null,
  error: null,
  loading: false,
  allSession: [],
  isSimpleSession: false,
};

export const AssignedTeamReducer = (state = init, { payload, type }) => {
  switch (type) {
    case HIT_ASSIGNTEAMSESSIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_ASSIGNED_TEAM_SESSION: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
      };
    }

    case FAIL_ASSIGNED_TEAM_SESSION_REQUEST: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case SET_ALL_ASSIGNED_SESSION: {
      return {
        ...state,
        allSession: payload.data,
        loading: false,
      };
    }
    case RESET_TEAM_SESSION_ACTION: {
      return init;
    }
    case SET_TEAM_SIMPLE_SESSION: {
      return {
        ...state,
        data: { sessionId: payload.data },
      };
    }
    case UPDATE_TEAM_SESSION_DATE: {
      return {
        ...state,
        data: state.data.map((session) =>
          session._id === payload?._id ? payload : session,
        ),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
