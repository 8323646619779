import moment from 'moment';
import { useEffect, useState } from 'react';
import { getWorkoutById } from '../../Service/Api';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../loader/Loader';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Workout() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const workoutId = params.id;
    const response = await getWorkoutById(workoutId);
    setData(response.data);
    setLoading(false);
  };

  const formatAddingZeros = (n) => {
    return n > 9 ? '' + n : '0' + n;
  };

  const getDuration = () => {
    const hours = data.workout?.totalTime?.hours;
    const minutes = data.workout?.totalTime?.minutes;
    const seconds = data.workout?.totalTime?.seconds;

    return `${formatAddingZeros(hours)}:${formatAddingZeros(
      minutes,
    )}:${formatAddingZeros(seconds)}`;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Stack
      backgroundColor="#252735"
      borderRadius={5}
      width="50%"
      marginLeft={'auto'}
      marginRight={'auto'}
      paddingY={5}
    >
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <Typography sx={{ color: 'primary.main', fontSize: 32 }}>
          {data.workout.assignedSessionId?.title}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Client: {data.workout.assignedSessionId?.userId?.Name}
        </Typography>
      </div>

      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
          marginTop: 50,
        }}
      >
        <Stack direction="row" justifyContent={'space-around'} gap={10}>
          <div>
            <Typography sx={{ fontSize: 16 }}>
              <Typography sx={{ color: 'primary.main' }}>
                {t('texts.dueDate')}:
              </Typography>
              {moment(data.dueDate).format('YYYY-MM-DD')}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: 16 }}>
              <Typography sx={{ color: 'primary.main' }}>
                {t('texts.duration')}:
              </Typography>
              {getDuration()}
            </Typography>
          </div>

          <div>
            <Typography sx={{ fontSize: 16 }}>
              <Typography sx={{ color: 'primary.main' }}>
                {t('texts.reps')}:
              </Typography>
              {data.totalReps || '-'}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: 16 }}>
              <Typography sx={{ color: 'primary.main' }}>
                {t('texts.volume')}:
              </Typography>
              {`${data.totalWeight?.value} ${data.totalWeight?.unit}` || '-'}
            </Typography>
          </div>
        </Stack>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 100,
        }}
      >
        <div>
          {data?.workout?.workoutData?.map((exercise, i) => {
            return (
              <div key={'workout' + i} style={{ marginBottom: 10 }}>
                <span style={{ color: '#32B5FF' }}>
                  {exercise?.exerciseId?.ExerciseName}
                </span>
                <br />
                <span>{`${exercise.workout.length || 0} sets`}</span>
                <span>
                  {exercise.workout.length && ' @'}{' '}
                  {exercise?.workout?.map((workoutData, k) => {
                    return (
                      <>
                        <span>{workoutData.firstMetric.value}x</span>
                        <span>
                          {workoutData.secondMetric.value}
                          {workoutData.secondMetric.unit}
                          {k < exercise.workout.length - 1 && ', '}
                        </span>
                      </>
                    );
                  })}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <button
        onClick={() =>
          navigate('/client?chat=' + data.workout.assignedSessionId?.userId._id)
        }
        style={{
          width: '50%',
          marginTop: 70,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Go to chat
      </button>
    </Stack>
  );
}
