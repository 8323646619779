import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useRef } from 'react';

const MuiTable = ({
  sx,
  containerSx,
  headers,
  rows,
  selectedRowIdx,
  noDataMessage,
  page,
  setPage,
  pageSize,
  setPageSize,
  scrollToBottomOnChange,
}) => {
  const ref = useRef(null);

  if (!rows?.length)
    return (
      <Stack sx={containerSx}>
        <Typography sx={{ color: 'error.main' }}>
          {noDataMessage || 'No data available'}
        </Typography>
      </Stack>
    );

  const scrollToBottom = (newPageSize) => {
    const lastPage = Math.floor(rows.length / newPageSize || pageSize);
    if (page === lastPage || page === lastPage - 1) {
      setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0);
    }
  };

  return (
    <TableContainer ref={ref} component={Paper} sx={containerSx}>
      <Table
        className="date-calendar-table-css"
        sx={{
          minWidth: 650,
          '.MuiTableCell-root': { color: 'background.lighter', fontSize: 20 },
          '.MuiTableCell-head': { color: 'white' },
          ...sx,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => (
              <TableCell key={idx + header} align={'center'}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * pageSize, page * pageSize + pageSize)
            .map((row, idx) => (
              <TableRow
                key={idx}
                sx={
                  selectedRowIdx === idx
                    ? {
                        '.MuiTableCell-root': {
                          borderColor: 'background.lighter',
                          color: 'white',
                          bgcolor: 'rgba(50, 181, 255, 0.3)',
                          padding: 2,
                        },
                      }
                    : {
                        '.MuiTableCell-root': {
                          borderColor: 'background.lighter',
                          padding: 1.5,
                        },
                        '&:last-child td, &:last-child th': { border: 0 },
                      }
                }
              >
                {row.map((value, idx) => (
                  <TableCell
                    key={idx}
                    align={'center'}
                    component="th"
                    scope="row"
                  >
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {setPage && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          sx={{
            '& .MuiTablePagination-selectLabel': { mb: 0 },
            '& .MuiTablePagination-displayedRows': { mb: 0 },
            bgcolor: 'background.light',
            borderTop: 1,
          }}
          count={rows.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            if (scrollToBottomOnChange) scrollToBottom();
          }}
          onRowsPerPageChange={({ target: { value } }) => {
            setPage(0);
            setPageSize(value);
            if (scrollToBottomOnChange) scrollToBottom(value);
          }}
        />
      )}
    </TableContainer>
  );
};

export default MuiTable;
