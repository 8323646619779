import React from "react";
import LoaderConetxt from "../context/Loader";
import RenderIf from "../customComponents/RenderIf";
import Loader from "../pages/loader/Loader";
export default function Loading({ children }) {
  const [loading, setLoading] = React.useState(false);
  return (
    <React.Fragment>
      <LoaderConetxt.Provider
        value={{
          loading,
          setLoading,
        }}
      >
        <RenderIf isTrue={loading}>
          <Loader />
        </RenderIf>
        {children}
      </LoaderConetxt.Provider>
    </React.Fragment>
  );
}
