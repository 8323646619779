import { loadStripe } from '@stripe/stripe-js';

const test_publicKey =
  'pk_test_51KngJVBiDeUCfD4ORTbtSjnKHz70xFjFSmViJDKXwXPu6P0dikWzf5ZbUQw8QOZLmn4wYZXFnDrJr7NcFuC0shXn00u0XzJ9x1';

const prod_publicKey =
  'pk_live_51KngJVBiDeUCfD4OHg6ASBwd5xbglv2gHj1c2sG0qAzh4DAyGMblfj5lR4zWUX6G6UZAXcgcEbBFhWkN6GOuQ2Dr00uyy3TbH4';

const publicKey = process.env.REACT_APP_ENV === 'production' ? prod_publicKey : test_publicKey;

export const stripePromise = loadStripe(publicKey);
