import React from "react";
import Renderif from "./RenderIf";

const Connectivity = ({ children }) => {
  const [isConnected, set_isConnected] = React.useState(true);

  const listener_handler = () => {
    set_isConnected(true);
  };

  const offline_listener_handler = () => {
    set_isConnected(false);
  };

  React.useEffect(() => {
    window.addEventListener("online", listener_handler);
    window.addEventListener("offline", offline_listener_handler);

    return () => {
      window.removeEventListener("online", listener_handler);
      window.removeEventListener("offline", offline_listener_handler);
    };
  }, []);
  const styling = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };
  return (
    <React.Fragment>
      <Renderif isTrue={isConnected}>{children}</Renderif>
      <Renderif isTrue={!isConnected}>
        <div style={styling}>
          <h1>you are in Offline mode now.</h1>
        </div>
      </Renderif>
    </React.Fragment>
  );
};

export default Connectivity;
