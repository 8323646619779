import { getToken } from 'firebase/messaging';
import { message } from './firebase';

export const genrateToken = async () => {
  try {
    const permited = await Notification.requestPermission();

    if (permited !== 'granted') {
      await Notification.requestPermission();
    }
    const token = await getToken(message, {
      vapidKey:
        'BFtI2sr_3k9UIsBwFE-Vcho003MED4wp7IVxV0mg6tU1uxbLFukblKcju3ETXsYMAd0vP83MdA-gKOBxHDNpIdU',
    });
    return token;
  } catch (err) {
    console.warn(err);
  }
};
