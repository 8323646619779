import { SET_SESSION_EXERCISES } from "../constants";

const init = {
  data: [],
  error: null,
};

export const sessionExercises = (state = init, { type, payload }) => {
  switch (type) {
    case SET_SESSION_EXERCISES: {
      return {
        ...state,
        data: payload.data,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
