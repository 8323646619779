import './App.css';
import React, { useState, useEffect } from 'react';
import LandingPage from './landing_page/landingpage/LandingPage';
import DashBoard from './components/dashboard/DashBoard';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './redux/reducers/store';
import Loading from './wrappers/Loading';
import { createTheme, ThemeProvider } from '@mui/material';

export const AuthContext = React.createContext();

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#32B5FF',
      hover: '#66c6fd',
    },
    secondary: {
      main: '#aaa',
    },
    disabled: {
      main: '#71797E',
    },
    background: {
      main: '#252735',
      dark: '#212529',
      darker: '#1E1E2B',
      light: '#2E313F',
      lighter: '#a7a7a7',
    },
    success: {
      main: '#32CD32',
      hover: '#4de34d',
    },
    error: {
      main: '#ff3333',
      hover: 'red',
    },
  },
});

const App = () => {
  const [auth, setAuth] = React.useState(null);
  const [loadingCompleted, setLoadingCompleted] = useState(false);

  useEffect(() => {
    const checkUser = localStorage.getItem('auth');
    if (checkUser && Object.keys(checkUser).length > 0) {
      setAuth(JSON.parse(checkUser));
    } else {
      setAuth(null);
    }
    setLoadingCompleted(true);
  }, []);

  return (
    <Provider store={store}>
      <Loading>
        {loadingCompleted && (
          <AuthContext.Provider value={{ auth, setAuth }}>
            <div className="App">
              <ToastContainer position="bottom-right" />
              <ThemeProvider theme={theme}>
                {auth ? <DashBoard /> : <LandingPage />}
              </ThemeProvider>
            </div>
          </AuthContext.Provider>
        )}
      </Loading>
    </Provider>
  );
};
export default App;
