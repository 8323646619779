import { InputLabel } from '@mui/material';
import { useMemo } from 'react';
import MuiModal from '../../components/shared/MuiModal';
import SelectInput from '../../components/shared/SelectInput';
import ReactCalendar from '../reactcalendar/ReactCalendar';
import useActionsModals from './useActionsModals';
import { useTranslation } from 'react-i18next';

const ActionsModals = ({
  selectedDateSessions,
  loadData,
  updateSessions,
  sessions,
  isDeleteModalVisible,
  setIsDeleteModalVisible,
  isCopyModalVisible,
  setIsCopyModalVisible,
  isMoveModalVisible,
  setIsMoveModalVisible,
}) => {
  const {
    handleDeleteSession,
    handleChangeSessionDate,
    handleCopySession,
    setNewDate,
    setSelectedSession,
    selectedSession,
    newDate,
    teamId,
    clientId,
    loading,
  } = useActionsModals({
    setIsDeleteModalVisible,
    setIsCopyModalVisible,
    setIsMoveModalVisible,
    isCopyModalVisible,
    isMoveModalVisible,
    selectedDateSessions,
    updateSessions,
    sessions,
  });

  const {t} = useTranslation();

  const SessionSelect = () => (
    <>
      {selectedDateSessions.length > 1 && (
        <SelectInput
          label={t('texts.selectSession')}
          containerSx={{ mt: 1.5 }}
          value={selectedSession}
          onChange={({ target: { value } }) => setSelectedSession(value)}
          selectOptions={selectedDateSessions.map((session) => ({
            value: session,
            label: session.title,
          }))}
        />
      )}
    </>
  );

  return (
    <>
      <MuiModal
        title={t('modals.titles.deleteSession')}
        description={t('modals.phrases.areYouSureDeleteSession')}
        submitBtnText={t('buttons.delete')}
        closeBtnText={t('buttons.cancel')}
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onSubmit={handleDeleteSession}
        isLoading={loading}
      >
        <SessionSelect />
      </MuiModal>

      <MuiModal
        title={t('texts.duplicateSession')}
        submitBtnText={t('texts.duplicate')}
        closeBtnText={t('buttons.cancel')}
        open={isCopyModalVisible}
        onClose={() => setIsCopyModalVisible(false)}
        onSubmit={handleCopySession}
        isLoading={loading}
      >
        <SessionSelect />

        <InputLabel sx={{ color: 'white', fontSize: 14, ml: 0.5 }}>
          {t('modals.phrases.selectDateToScheduleSession')}
        </InputLabel>
        <ReactCalendar
          style={{ paddingTop: 10, paddingBottom: 10, width: 380 }}
          onChange={setNewDate}
          selectedDate={newDate}
          clientId={clientId}
          teamId={teamId}
        />
      </MuiModal>

      <MuiModal
        title={t('modals.titles.updateSessionDate')}
        submitBtnText={t('buttons.update')}
        closeBtnText={t('buttons.cancel')}
        open={isMoveModalVisible}
        onClose={() => setIsMoveModalVisible(false)}
        onSubmit={handleChangeSessionDate}
        isLoading={loading}
      >
        <SessionSelect />

        <InputLabel sx={{ color: 'white', fontSize: 14, ml: 0.5 }}>
          {t('modals.phrases.selectDateToScheduleSession')}
        </InputLabel>
        <ReactCalendar
          style={{ paddingTop: 10, paddingBottom: 10, width: 380 }}
          onChange={setNewDate}
          selectedDate={newDate}
          clientId={clientId}
          teamId={teamId}
        />
      </MuiModal>
    </>
  );
};

export default ActionsModals;
