import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';

const FormFileInput = ({
  register,
  label,
  name,
  options,
  lightTheme,
  shadow = true,
  noLabel,
  disabled,
  error,
  sx,
  ...props
}) => (
  <FormControl
    sx={{
      width: 1,
      mb: 1,
      '& .MuiFormHelperText-root': { ml: '1px' },
      ...sx,
    }}
  >
    {!noLabel && label && (
      <InputLabel
        shrink
        sx={{ color: disabled ? 'disabled.main' : 'white', ml: -1.5, mt: -2 }}
      >
        {label}
      </InputLabel>
    )}

    <input
      className="form-control"
      type="file"
      {...(register ? register(name, options) : {})}
      {...props}
      disabled={disabled}
      style={{
        color: !lightTheme ? 'white' : 'black',
        backgroundColor: !lightTheme ? '#2E313F' : 'white',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        borderRadius: 5,
        border: 'none',
        width: '100%',
        pr: 1,
      }}
    />

    <FormHelperText sx={{ color: 'error.main', height: 15 }}>
      {error?.message.toString()}
    </FormHelperText>
  </FormControl>
);

export default FormFileInput;
