import {
  HIT_GRAPH_REQUEST,
  SET_GRAPH_DATA_REQUEST,
  FAILED_GRAPH_DATA_REQUEST,
} from '../constants';

const init = {
  data: [],
  error: null,
  loading: false,
};

export const graphReducer = (state = init, { type, payload }) => {
  switch (type) {
    case HIT_GRAPH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_GRAPH_DATA_REQUEST: {
      return {
        ...state,
        data: payload.data,
        error: null,
        loading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
