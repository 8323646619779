import { getUniqueExercisesAssginedByUser } from '../../Service/Api';
import {
  HIT_EXERCISES_REQUET,
  SET_EXERCISES_REQUET,
  FAILED_EXERCISES_REQUET,
} from '../constants';
import i18n from '../../i18n';

export const setUserExercises = (userId) => async (dispatch) => {
  // * intial dispatch
  dispatch({
    type: HIT_EXERCISES_REQUET,
  });

  try {
    const token = localStorage.getItem('token');
    const response = await getUniqueExercisesAssginedByUser(userId, token);

    if (response.statusCode === 200) {
      dispatch({
        type: SET_EXERCISES_REQUET,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_EXERCISES_REQUET,
      payload: {
        error: err?.message || err?.error || i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};
