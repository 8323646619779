import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { disconnect_webSocket } from './utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  initilChatDataAction,
  addMessageAction,
  emptyToChat,
  loadChatHistory,
  onChatRequestFailed,
} from '../../redux/actions/Chat';
import { CHAT_REQUEST_HIT } from '../../redux/constants';
import { markChatAsRead } from '../../Service/Api';

const useChat = ({
  closeTab,
  clientId,
  team,
  noti = undefined,
  focusMessage,
}) => {
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [input, setInput] = useState('');

  const chatBoxRef = useRef(null);
  const socketRef = useRef(null);
  const dispatch = useDispatch();

  const {
    messages,
    page,
    pageSize,
    total: totalMessages,
    loading,
  } = useSelector((state) => state.chat);
  const user = JSON.parse(localStorage.getItem('auth'));

  const allMessagesLoaded =
    totalMessages === 0 || totalMessages <= messages.length;

  const scrollToBottom = () => {
    if (focusMessage) {
      setTimeout(() => {
        const el = document.getElementById(focusMessage);
        el?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else {
      setTimeout(() => {
        const chatEl = document?.getElementsByClassName('messages-info')?.[0];
        chatEl?.scrollTo(0, chatEl.scrollHeight);
      }, 500);
    }
  };

  const markChatMessagesAsRead = async (from, to, isTeam = false) => {
    markChatAsRead({ from, to, isTeam });
  };

  const handle_socketio_Connection = () => {
    socketRef.current = io.connect(process.env.REACT_APP_API_URL, {
      secure: true,
      transports: ['websocket'],
      rejectUnauthorized: true,
    });

    socketRef.current.on('connect', () => {
      socketRef.current.emit('set-user-data', user._id);

      setTimeout(() => {
        socketRef.current.emit('set-room', {
          name1: user._id,
          name2: clientId || team?._id,
        });
        if (team?._id) {
          markChatMessagesAsRead(team?._id, user._id, true);
        } else {
          markChatMessagesAsRead(clientId, user._id);
        }
      }, 20);
    });

    socketRef.current.on('set-room', (roomId) => {
      socketRef.current.roomId = roomId;
      fetchInitialChat();
      scrollToBottom();
    });

    socketRef.current.on('old-chats', (chat) => {
      dispatch(initilChatDataAction(chat.result.reverse(), chat.total));
      scrollToBottom();
    });

    socketRef.current.on('chat-msg', (data) => {
      setIsSendingMessage(false);
      dispatch(addMessageAction(data));
      scrollToBottom();
    });

    socketRef.current.on('chat-msg-failed', (data) => {
      setIsSendingMessage(false);
      dispatch(addMessageAction(data));
      scrollToBottom();
    });

    socketRef.current.on('old-chats-failed', () => {
      dispatch(onChatRequestFailed());
    });

    socketRef.current.on('disconnect', () => {
      dispatch(emptyToChat());
      socketRef.current.roomId = '';
    });
  };

  const disconnect = () => {
    if (!socketRef?.current) return;
    disconnect_webSocket(socketRef.current);
    dispatch(emptyToChat());
  };

  const handleClose = () => {
    closeTab(false);
  };

  const inputHandler = (event) => {
    const { value } = event.target;
    setInput(value);
  };

  const sendMessage = () => {
    if (input.length > 0) {
      const body = {
        msgFrom: user._id,
        msgTo: clientId || team?._id,
        msg: input,
        room: socketRef.current.roomId,
        createdOn: new Date(),
      };

      socketRef.current.emit(clientId ? 'chat-msg' : 'team-msg', body);
      setIsSendingMessage(true);
      setInput('');
    }
  };

  const fetchInitialChat = () => {
    socketRef.current.emit('old-chats-init', {
      room: socketRef.current.roomId,
    });
  };

  const onKeyPress = (e) => {
    if (e.code === 'Enter') {
      sendMessage();
      e.preventDefault();
    }
  };

  const fetchChatHistory = () => {
    const roomId = socketRef.current.roomId;

    if (messages.length >= totalMessages) return;

    dispatch(
      loadChatHistory(roomId, page, pageSize, () =>
        setTimeout(
          () =>
            document
              ?.getElementsByClassName('messages-info')?.[0]
              ?.scrollTo(0, 2),
          800,
        ),
      ),
    );
  };

  const onScroll = (event) => {
    const { scrollTop } = event.target;
    if (scrollTop === 0 && !allMessagesLoaded) fetchChatHistory();
  };

  const onModalClose = () => {
    handleClose();
    disconnect();
  };

  const getFirstAndLastName = (fullName) => {
    const names = fullName.split(' ');
    const lastName = names.length > 1 ? names[names.length - 1] : '';
    return `${names[0]} ${lastName}`;
  };

  useEffect(() => {
    dispatch({ type: CHAT_REQUEST_HIT });
    if (team || clientId) handle_socketio_Connection();

    return () => {
      disconnect();
    };
  }, [team, clientId]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => document.removeEventListener('keydown', onKeyPress);
  }, [onKeyPress]);

  return {
    messages,
    loading,
    allMessagesLoaded,
    user,
    input,
    chatBoxRef,
    isSendingMessage,
    getFirstAndLastName,
    onModalClose,
    onScroll,
    inputHandler,
    sendMessage,
  };
};

export default useChat;
