import {
  CHAT_REQUEST_HIT,
  LOAD_CHAT_HISTORY,
  FAILED_CHAT_REQUEST,
  INSERT_CHAT_MESSAGE,
  EMPTY_CHAT,
} from '../constants';

const initialVal = {
  messages: [],
  error: null,
  loading: false,
  page: 1,
  pageSize: 10,
};
export const chatReducer = (state = initialVal, { type, payload }) => {
  switch (type) {
    case CHAT_REQUEST_HIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case EMPTY_CHAT: {
      return {
        ...state,
        messages: [],
        loading: false,
        total: undefined,
        page: 1,
      };
    }
    case FAILED_CHAT_REQUEST: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case LOAD_CHAT_HISTORY: {
      return {
        ...state,
        messages: [...payload.messages, ...state.messages],
        page: state.page + 1,
        total: state.total || payload.total,
        loading: false,
      };
    }
    case INSERT_CHAT_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, payload.message],
        total: state.total + 1,
      };
    }
    default: {
      return { ...state };
    }
  }
};
