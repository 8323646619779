import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormSelectInput from '../../components/shared/FormSelectInput';
import MuiButton from '../../components/shared/MuiButton';
import { useTranslation } from 'react-i18next';

const CalendarToolbar = ({
  label,
  onNavigate,
  teamId,
  clientId,
  selectOptions,
}) => {
  const { control } = useForm({ defaultValues: { teamId, clientId } });
  const { t } = useTranslation();

  const goToBack = () => {
    onNavigate('PREV');
  };
  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  const hadleSelectClient = (e) => {
    window.location.assign(
      `/${teamId ? 'team' : 'client'}/calendars/${e.target.value}`,
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <FormSelectInput
        name={teamId ? 'teamId' : 'clientId'}
        control={control}
        label={teamId ? t('texts.teams') : t('texts.clients')}
        selectOptions={selectOptions?.map((data) => ({
          label: teamId ? data.TeamName : data.Name,
          value: data._id,
        }))}
        onChange={hadleSelectClient}
        sx={{ minWidth: 350 }}
      />

      <div className="calendar-top-bar-info">
        <div className="calendar-top-grid">
          <div className="left-arrow-btn">
            <span onClick={goToBack}>
              <i className="fa fa-angle-left"></i>
            </span>
          </div>
          <div className="heading-h2">
            <h2>
              <img src="assets/calendar-time-icon.png" alt="" /> {label}
            </h2>
          </div>
          <div className="right-arrow-btn">
            <span onClick={goToNext}>
              <i className="fa fa-angle-right"></i>
            </span>
          </div>
        </div>

        <MuiButton onClick={goToToday}>Go to today</MuiButton>
      </div>
    </Stack>
  );
};

export default CalendarToolbar;
